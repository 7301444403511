import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
import { FormControl, FormHelperText, IconButton } from "@mui/material";
import AddGiftVoucherApi from "../../../api/gift-vouchers/AddGiftVoucherApi";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import AddIcon from "@mui/icons-material/Add";
import { UploadFileApi } from "../../../api/UploadFileApi";
import NotificationHelper from "../../helpers/NotificationHelper";
import ImageCropper from "../ImageCropper";
import { LoadingButton } from "@mui/lab";
import EditGiftVoucherApi from "../../../api/gift-vouchers/EditGiftVoucherApi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function EditGiftCardModal(props) {
  const [name, setName] = useState();
  const [value, setValue] = useState("");
  const [validityPeriod, setValidityPeriod] = useState();
  const [validityAbb, setValidityAbb] = useState("month");
  const [submit, setSubmit] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });

  const [nameError, setNameError] = useState("");
  const [valueError, setValueError] = useState("");
  const [validityError, setValidityError] = useState("");

  useEffect(() => {
    setName(props.selectedRow?.name);
    setValidityPeriod(props.selectedRow?.validityPeriod?.split(" ")[0]);
    setValidityAbb(props.selectedRow?.validityPeriod?.split(" ")[1]);
    setValue(props.selectedRow?.value);
  }, [
    props.selectedRow?.name,
    props.selectedRow?.validityPeriod,
    props.selectedRow?.value,
  ]);

  function onCancel() {
    setName(name);
    setValue(value);
    setValidityPeriod(validityPeriod);
    setValidityAbb(validityAbb);
    setNameError("");
    setValueError("");
    setValidityError("");
    props.onClose();
  }

  async function handleSubmit(event) {
    setSubmit(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!data.get("name")) {
      setNameError("Name can't be empty!");
      setSubmit(false);
    }
    if (!data.get("value")) {
      setValueError("Price can't be empty!");
      setSubmit(false);
    }

    if (!data.get("validityPeriod")) {
      setValidityError("Validity period can't be empty!");
      setSubmit(false);
    }

    if (data.get("name") && data.get("value") && data.get("validityPeriod")) {
      setSubmit(true);
      const apiResponse = await EditGiftVoucherApi(
        props.selectedRow._id,
        data.get("name"),
        data.get("value"),
        `${data.get("validityPeriod")} ${data.get("validityAbb")}`
      );
      if (apiResponse.statusCode) {
        setNotification({
          open: true,
          type: "error",
          message: apiResponse.message,
        });
      } else if (apiResponse.success) {
        setNotification({
          open: true,
          type: "success",
          message: apiResponse.message,
        });
      }
      props.handleOk();
      onCancel();
    }
    setSubmit(false);
  }

  return (
    <>
      <Modal
        keepMounted
        open={props.open}
        onClose={() => onCancel()}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Add Gift Card
            </Typography>
            <CloseIcon onClick={() => onCancel()} />
          </Stack>

          <TextField
            sx={{ mt: 2 }}
            fullWidth
            name="name"
            size="small"
            label="Gift Card name"
            variant="outlined"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setNameError("");
            }}
            error={nameError ? true : false}
            helperText={nameError ? nameError : " "}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Stack sx={{ mt: 1 }} direction="row" spacing={2} alignItems="center">
            <TextField
              sx={{ width: "70%" }}
              size="small"
              label="Price"
              name="value"
              variant="outlined"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                setValueError("");
              }}
              error={valueError ? true : false}
              helperText={valueError ? valueError : " "}
              inputProps={{ inputMode: "numeric" }}
              onKeyDown={(event) => {
                const allowedKeys = ["Backspace", "Delete", "Enter"];
                if (
                  !/^[0-9]+$/.test(event.key) &&
                  !/^Arrow(Left|Right|Up|Down)$/.test(event.key) &&
                  !allowedKeys.includes(event.key)
                ) {
                  event.preventDefault();
                }
              }}
            />
            <TextField
              id="outlined-basic"
              sx={{ width: "100%" }}
              size="small"
              label="Validity period"
              name="validityPeriod"
              inputProps={{ inputMode: "numeric" }}
              onKeyDown={(event) => {
                const allowedKeys = ["Backspace", "Delete", "Enter"];
                if (
                  !/^[0-9]+$/.test(event.key) &&
                  !/^Arrow(Left|Right|Up|Down)$/.test(event.key) &&
                  !allowedKeys.includes(event.key)
                ) {
                  event.preventDefault();
                }
              }}
              value={validityPeriod}
              onChange={(event) => {
                setValidityPeriod(event.target.value);
                setValidityError("");
              }}
              error={validityError ? true : false}
              helperText={validityError ? validityError : " "}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <FormControl>
                      <Select
                        id="outlined-basic"
                        size="small"
                        sx={{
                          marginRight: -2.8,
                          width: "100px",
                        }}
                        name="validityAbb"
                        value={validityAbb}
                        onChange={(event) => {
                          setValidityAbb(event.target.value);
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="year">Year</MenuItem>
                        <MenuItem value="month">Month</MenuItem>
                        <MenuItem value="day">Day</MenuItem>
                      </Select>
                    </FormControl>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack
            sx={{ mt: 2 }}
            justifyContent="end"
            direction="row"
            spacing={1}
          >
            <Button
              sx={{ textTransform: "none" }}
              variant="outlined"
              size="small"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
            <LoadingButton
              size="large"
              loading={submit}
              loadingPosition="start"
              variant="contained"
              type="submit"
              fullWidth
              sx={{
                mt: 2,
                textTransform: "none",
                width: 80,
              }}
            >
              <Typography variant="body1">Edit</Typography>
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
      <NotificationHelper notification={notification} />
    </>
  );
}

export default EditGiftCardModal;
