import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import NotificationHelper from "../../helpers/NotificationHelper";
import { LoadingButton } from "@mui/lab";
import EditBoostPlanApi from "../../../api/boost-plans/EditBoostPlanApi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function EditBoostPlanModal(props) {
  const [name, setName] = useState();
  const [submit, setSubmit] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [price, setPrice] = useState("");
  const [boostNo, setBoostNo] = useState("");
  const [nameError, setNameError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [noOfBoostError, setNoOfBoostError] = useState("");

  useEffect(() => {
    setName(props.selectedRow?.name);
    setPrice(props.selectedRow?.price);
    setBoostNo(props.selectedRow?.noOfBoost);
  }, [
    props.selectedRow?.name,
    props.selectedRow?.price,
    props.selectedRow?.noOfBoost,
  ]);

  function onCancel() {
    setName(name);
    setPrice(price);
    setBoostNo(boostNo);
    setNameError("");
    setPriceError("");
    setNoOfBoostError("");
    props.onClose();
  }

  async function handleSubmit(event) {
    setSubmit(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!data.get("name")) {
      setNameError("Name can't be empty!");
      setSubmit(false);
    }
    if (!data.get("price")) {
      setPriceError("Price can't be empty!");
      setSubmit(false);
    }

    if (!data.get("noOfBoost")) {
      setNoOfBoostError("No of boost can't be empty!");
      setSubmit(false);
    }

    if (data.get("name") && data.get("price") && data.get("noOfBoost")) {
      setSubmit(true);
      const apiResponse = await EditBoostPlanApi(
        props.selectedRow.id,
        data.get("name"),
        data.get("price"),
        data.get("noOfBoost")
      );
      if (apiResponse.statusCode) {
        setNotification({
          open: true,
          type: "error",
          message: apiResponse.message,
        });
      } else if (apiResponse.success) {
        setNotification({
          open: true,
          type: "success",
          message: apiResponse.message,
        });
      }
      props.handleOk();
      onCancel();
    }
    setSubmit(false);
  }

  return (
    <>
      <Modal
        keepMounted
        open={props.open}
        onClose={() => onCancel()}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Edit boost plan
            </Typography>
            <CloseIcon onClick={() => onCancel()} />
          </Stack>

          <TextField
            sx={{ mt: 2 }}
            fullWidth
            name="name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setNameError("");
            }}
            error={nameError ? true : false}
            helperText={nameError ? nameError : " "}
            size="small"
            label="Boost plan name"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Stack sx={{ mt: 1 }} direction="row" spacing={2} alignItems="center">
            <TextField
              fullWidth
              name="price"
              value={price}
              onChange={(e) => {
                setPrice(e.target.value);
                setPriceError("");
              }}
              error={priceError ? true : false}
              helperText={priceError ? priceError : " "}
              size="small"
              label="Price"
              variant="outlined"
            />
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label">No of boost</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="noOfBoost"
                value={boostNo}
                label="No of boost"
                onChange={(event) => setBoostNo(event.target.value)}
              >

                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={25}>25</MenuItem>

              </Select>
              <FormHelperText
                error={noOfBoostError ? true : false}
                id="outlined-weight-helper-text"
              >
                {noOfBoostError ? noOfBoostError : " "}
              </FormHelperText>
            </FormControl>
          </Stack>

          <Stack
            sx={{ mt: 2 }}
            justifyContent="end"
            direction="row"
            spacing={1}
          >
            <Button
              sx={{ textTransform: "none" }}
              variant="outlined"
              size="small"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
            <LoadingButton
              size="large"
              loading={submit}
              loadingPosition="start"
              variant="contained"
              type="submit"
              fullWidth
              sx={{
                mt: 2,
                textTransform: "none",
                width: 80,
              }}
            >
              <Typography variant="body1">Edit</Typography>
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
      <NotificationHelper notification={notification} />
    </>
  );
}

export default EditBoostPlanModal;
