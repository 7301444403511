import React, { useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Paper from "@mui/material/Paper";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { STATUS } from "../helpers/Constant";
import DisableEnableProductsApi from "../../api/products/DisableEnableProductsApi";
import NotificationHelper from "../helpers/NotificationHelper";
import ConfirmationModal from "../modals/ConfirmationModal";
import DeleteProductsApi from "../../api/products/DeleteProductsApi";
import HardDeleteModal from "../modals/HardDeleteModal";
import { CustomNoRowsOverlay } from "../helpers/NoData";
import { Link, useNavigate } from "react-router-dom";

function ProductsTable(props) {
  const [page, setPage] = useState(1);
  const [openDisableProduct, setOpenDisableProduct] = useState(false);
  const [productId, setProductId] = useState();
  const [selectProductStatus, setSelectProductStatus] = useState();
  const [openDeleteProduct, setOpenDeleteProduct] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [disableLoading, setDisableLoading] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    setPage(props.page);
  }, [props.page]);

  async function disableProduct() {
    setDisableLoading(true);
    const apiResponse = await DisableEnableProductsApi(
      productId,
      selectProductStatus
    );

    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse.success) {
      setNotification({
        open: true,
        type: "success",
        message: apiResponse.message,
      });
      props.getProducts();
    }
    setDisableLoading(false);
    setProductId("");
    setOpenDisableProduct(false);
  }

  async function deleteProduct() {
    setLoading(true);
    const apiResponse = await DeleteProductsApi(productId);

    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse.success) {
      setNotification({
        open: true,
        type: "success",
        message: apiResponse.message,
      });
      if (props.products?.length === 1) {
        props.getProducts(page - 1);
      } else {
        props.getProducts(page);
      }
    }
    setLoading(false);
    setProductId("");
    setOpenDeleteProduct(false);
  }

  return (
    <>
      <Typography component="h2" variant="h6" gutterBottom>
        Products
      </Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Product Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Price</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Category</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Stock</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Sold</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          {props.products?.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  <CustomNoRowsOverlay message={"No Product"} />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.products?.map((product) => (
                <TableRow key={product._id}>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>${product.price}</TableCell>
                  <TableCell>
                    {product.categoryName ? product.categoryName : "-"}
                  </TableCell>
                  <TableCell>{product.stock ? product.stock : "-"}</TableCell>
                  <TableCell>{product.sold ? product.sold : "-"}</TableCell>
                  <TableCell>
                    {product.status === STATUS.Active
                      ? "Active"
                      : product.status === STATUS.Disabled
                      ? "Disabled"
                      : "-"}
                  </TableCell>
                  <TableCell>
                    <div className="flex justify-items-center gap-1">
                      <Link
                        to={`https://peppynest.com/products/product?productId=${product._id}`}
                        target="_blank"
                      >
                        <OpenInNewIcon
                          className="text-blue-500"
                          sx={{ fontSize: "20px" }}
                        />
                      </Link>
                      {product.status === STATUS.Disabled ? (
                        <div
                          onClick={() => {
                            setProductId(product._id);
                            setSelectProductStatus(STATUS.Active);
                            setOpenDisableProduct(true);
                          }}
                        >
                          <CheckCircleOutlinedIcon
                            style={{ fontSize: "20px" }}
                            className="text-green-500"
                          />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setProductId(product._id);
                            setSelectProductStatus(STATUS.Disabled);
                            setOpenDisableProduct(true);
                          }}
                        >
                          <BlockOutlinedIcon
                            style={{ fontSize: "20px" }}
                            className="text-orange-500"
                          />
                        </div>
                      )}
                      <div
                        onClick={() => {
                          setProductId(product._id);
                          setOpenDeleteProduct(true);
                        }}
                      >
                        <DeleteOutlinedIcon
                          sx={{ fontSize: "20px", color: "#f44336" }}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <NotificationHelper notification={notification} />
      <ConfirmationModal
        keepMounted
        title={
          selectProductStatus === STATUS.Disabled
            ? "Disable Product"
            : "Enable Product"
        }
        description={
          selectProductStatus === STATUS.Disabled
            ? "Are you sure want to disable this product?"
            : "Are you sure want to Enable this product?"
        }
        loading={disableLoading}
        open={openDisableProduct}
        handleOk={() => disableProduct()}
        onClose={() => {
          setOpenDisableProduct(false);
          setProductId("");
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      />
      <HardDeleteModal
        keepMounted
        title="Delete Product"
        description="Are you sure want to delete this product?"
        open={openDeleteProduct}
        loading={loading}
        handleOk={() => deleteProduct()}
        onClose={() => {
          setOpenDeleteProduct(false);
          setProductId("");
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      />
    </>
  );
}
export default ProductsTable;
