import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ButtonBase from "@mui/material/ButtonBase";
import image from "../../assets/images/shirt.png";
import Rating from "@mui/material/Rating";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

function ProductCard() {
  return (
    <Card>
      <Grid item xs={12} sm container>
        <Grid item>
          <ButtonBase sx={{ width: 100, height: 100 }}>
            <img src={image} width="80px" />
          </ButtonBase>
        </Grid>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item xs>
            <Stack direction="row" justifyContent="space-between">
              <Typography gutterBottom variant="subtitle1" component="div">
                Product name
              </Typography>
              <DeleteOutlinedIcon sx={{ color: "red" }} />
            </Stack>
            <Typography variant="body2" gutterBottom>
              $ 19.99
            </Typography>
            <Grid display="flex">
              <Rating value={2} readOnly />
              <Typography>(15)</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default ProductCard;
