import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Stack from "@mui/material/Stack";
import SetupProfileApi from "../../api/auth/SetupProfileApi";
import { userDetails } from "../../features/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { UploadFileApi } from "../../api/UploadFileApi";
import NotificationHelper from "../../components/helpers/NotificationHelper";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import AddIcon from "@mui/icons-material/Add";
import ImageCropper from "../../components/modals/ImageCropper";
import { firstLogin } from "../../features/FirstLoginSlice";

function PersonalDetails(props) {
  const [submit, setSubmit] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });
  const userState = useSelector((state) => state.userState.value);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [imageCropper, setImageCropper] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [croppedImageFile, setCroppedImageFile] = useState("");
  const [nameError, setNameError] = useState("");

  useEffect(() => {
    if (croppedImageFile) {
      async function blobToFile(blobUrl, fileName) {
        const response = await fetch(blobUrl);
        const blob = await response.blob();
        return new File([blob], fileName, { type: blob.type });
      }

      blobToFile(croppedImageFile, "filename.jpg").then((fileImage) => {
        upload(fileImage, userState._id);
      });
    }
  }, [croppedImageFile]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    event.target.value = null;
    setImageFile(selectedFile);
  };

  async function upload(file, name) {
    setSubmit(true);

    const apiResponse = await UploadFileApi(file, name);

    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse) {
      setImageUrl(apiResponse.Location);
    }
    setSubmit(false);
  }

  async function handleSubmit(event) {
    setSubmit(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (imageUrl) {
      setSubmit(false);
    }

    if (!data.get("fullName")) {
      setNameError("Fullname can't be empty!");
      setSubmit(false);
    }

    if (data.get("fullName")) {
      setSubmit(true);
      const apiResponse = await SetupProfileApi(data.get("fullName"), imageUrl);
      if (apiResponse.statusCode) {
        setNotification({
          open: true,
          type: "error",
          message: apiResponse.message,
        });
      } else if (apiResponse.success) {
        setNotification({
          open: true,
          type: "success",
          message: apiResponse.message,
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));
        dispatch(firstLogin(false));
        dispatch(userDetails(apiResponse));
        if (apiResponse.firstLogin) {
          navigate("/create-newpassword");
        } else {
          navigate("/website-layout");
        }
      }
    }
    setSubmit(false);
  }
  return (
    <>
      <div className="h-screen flex w-full  justify-center ">
        <Box
          sx={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <PersonOutlineIcon />
          </Avatar>
          <Typography textAlign="center" variant="h6">
            Personal Details
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            flexDirection="column"
            sx={{
              width: "400px",
              display: "flex",
              mt: 2,
              alignItems: "center",
            }}
          >
            <div
              className={
                "h-36 w-36 flex justify-center items-center mb-2 relative " +
                (!croppedImageFile && "bg-gray-200")
              }
            >
              {(imageUrl || croppedImageFile) && (
                <img
                  src={croppedImageFile ? croppedImageFile : imageUrl}
                  height={144}
                  width={144}
                  alt="sample"
                  className="absolute top-0 left-0 w-full h-full"
                />
              )}
              <Stack direction="row" spacing={1}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={() => setImageCropper(true)}
                >
                  <input
                    accept="image/*"
                    hidden
                    type="file"
                    onChange={handleFileChange}
                  />
                  {croppedImageFile || imageUrl ? (
                    <SwapHorizIcon />
                  ) : (
                    <AddIcon />
                  )}
                </IconButton>
              </Stack>
            </div>

            <TextField
              margin="normal"
              size="small"
              onChange={() => {
                setNameError("");
              }}
              fullWidth
              id="fullName"
              label="Full Name"
              name="fullName"
              autoComplete="fullName"
              error={nameError ? true : false}
              helperText={nameError ? nameError : " "}
            />

            <LoadingButton
              loading={submit}
              loadingPosition="start"
              variant="contained"
              type="submit"
              fullWidth
              sx={{ mb: 2, textTransform: "initial" }}
            >
              Complete
            </LoadingButton>
          </Box>
        </Box>
      </div>
      <NotificationHelper notification={notification} />
      <ImageCropper
        visible={imageCropper}
        handleCancel={() => setImageCropper(false)}
        imageFile={imageFile}
        handleClearImage={() => setImageFile(null)}
        setCroppedImageFile={(file) => setCroppedImageFile(file)}
      />
    </>
  );
}

export default PersonalDetails;
