import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dashboard from "../views/dashboard/Dashboard";
import DashboardLayout from "../layouts/DashboardLayout";
import SubscriptionsPlans from "../views/dashboard/SubscriptionsPlans";
import CreateSubscriptions from "../views/dashboard/CreateSubscriptions";
import WebsiteLayout from "../views/dashboard/WebsiteLayout";
import Categories from "../views/dashboard/Categories";
import Sellers from "../views/dashboard/Sellers";
import Products from "../views/dashboard/Products";
import Admin from "../views/dashboard/Admin";
import SubscriptionPlansDetails from "../views/dashboard/SubscriptionPlansDetails";
import SellersDetails from "../views/dashboard/SellersDetails";
import BoostPlans from "../views/dashboard/BoostPlans";
import GiftVouchers from "../views/dashboard/GiftVouchers";
import Contests from "../views/dashboard/Contests";
import Shops from "../views/dashboard/Shops";
import Users from "../views/dashboard/Users";
import CreateContests from "../views/dashboard/CreateContests";
import ContestsDetails from "../views/dashboard/ContestsDetails";
import Points from "../views/dashboard/Points";
import SettingsLayout from "../layouts/SettingsLayout";
import AdminProfile from "../views/settings/AdminProfile";
import ChangePassword from "../views/settings/ChangePassword";
import CategoryRequests from "../views/dashboard/CategoryRequests";
import WebLayout from "../layouts/WebLayout";
import Login from "../views/web/Login";
import SetupLayout from "../layouts/SetupLayout";
import PersonalDetails from "../views/setup/PersonalDetails";
import CreateNewPassword from "../views/setup/CreateNewPassword";
import ForgotPassword from "../views/web/ForgotPassword";
import ResetPassword from "../views/web/ResetPassword";
import { userDetails } from "../features/UserSlice";
import { login, logout } from "../features/AuthSlice";
import { GetMeApi } from "../api/user/GetMeApi";
import { firstLogin } from "../features/FirstLoginSlice";
import ForgotPasswordOtpVerification from "../views/web/ForgotPasswordOtpVerification";
import OpenRoute from "./OpenRoute";
import ProtectedRoute from "./ProtectedRoute";
import SetupProfileRoute from "./SetupProfileRoute";
import AppRoute from "./AppRoute";
import Accounts from "../views/dashboard/Accounts";
import AccountsDetails from "../views/dashboard/AccountsDetails";
import AccountsHistory from "../views/dashboard/AccountsHistory";
import AccountsHistoryDetails from "../views/dashboard/AccountsHistoryDetails";
import Memberships from "../views/dashboard/Memberships";

function Main() {
  const authState = useSelector((state) => state.authState.value);
  const userState = useSelector((state) => state.userState.value);
  const dispatch = useDispatch();

  useEffect(() => {
    getUserDetails(userState._id);
  }, [authState, userState._id]);

  async function getUserDetails() {
    const apiResponse = await GetMeApi();
    if (apiResponse.statusCode) {
      localStorage.setItem("authToken", "");
      localStorage.setItem("authUser", "");
      dispatch(logout());
    } else if (apiResponse.fullName) {
      dispatch(login());
      dispatch(userDetails(apiResponse));
      if (apiResponse.firstLogin) {
        dispatch(firstLogin(true));
      }
    } else {
      localStorage.setItem("authToken", "");
      localStorage.setItem("authUser", "");
      dispatch(logout());
    }
  }

  return (
    <Routes>
      <Route element={<OpenRoute />}>
        <Route element={<WebLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/forgotpassword-otpverification"
            element={<ForgotPasswordOtpVerification />}
          />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<Navigate replace to="/login" />} />
        </Route>
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route element={<SetupProfileRoute />}>
          <Route element={<SetupLayout />}>
            <Route path="/create-newpassword" element={<CreateNewPassword />} />
            <Route path="/personal-details" element={<PersonalDetails />} />
          </Route>
        </Route>
        <Route element={<AppRoute />}>
          <Route element={<DashboardLayout />}>
            {/* <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/accounts/:shopName" element={<AccountsDetails />} />
            <Route path="/accounts-history" element={<AccountsHistory />} />
            <Route
              path="/accounts-history/:shopName"
              element={<AccountsHistoryDetails />}
            /> */}
            <Route path="/website-layout" element={<WebsiteLayout />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/categories/requests" element={<CategoryRequests />} />
            <Route
              path="/subscriptions-plans"
              element={<SubscriptionsPlans />}
            />
            <Route
              path="/subscriptions-plans/create-subscriptions"
              element={<CreateSubscriptions />}
            />
            <Route
              path="/subscriptions-plans/:subName"
              element={<SubscriptionPlansDetails />}
            />
            <Route path="/sellers" element={<Sellers />} />
            <Route path="/sellers/:id" element={<SellersDetails />} />
            <Route path="/shops" element={<Shops />} />
            <Route path="/products" element={<Products />} />
            <Route path="/users" element={<Users />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/memberships" element={<Memberships />} />
            <Route path="/boost-plans" element={<BoostPlans />} />
            <Route path="/gift-vouchers" element={<GiftVouchers />} />
            <Route path="/contests" element={<Contests />} />
            <Route
              path="/contests/create-contests"
              element={<CreateContests />}
            />
            <Route path="/contests/:name" element={<ContestsDetails />} />
            <Route path="/points" element={<Points />} />
          </Route>
          <Route element={<SettingsLayout />}>
            <Route path="settings/admin-profile" element={<AdminProfile />} />
            <Route
              path="settings/change-password"
              element={<ChangePassword />}
            />
            <Route
              path="settings/*"
              element={<Navigate replace to="/settings/admin-profile" />}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default Main;
