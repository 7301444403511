import * as React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";

const action = (
  <div className="flex justify-items-center gap-1">
    <EditOutlinedIcon style={{ fontSize: "20px" }} className="text-blue-500" />
    <BlockOutlinedIcon
      style={{ fontSize: "20px" }}
      className="text-orange-500"
    />
    <DeleteOutlinedIcon style={{ fontSize: "20px" }} className="text-red-500" />
  </div>
);

// Generate subscription plan Data
function createData(
  id,
  subName,
  subFreq,
  noOfSub,
  availablePeriod,
  status,
  action
) {
  return { id, subName, subFreq, noOfSub, availablePeriod, status, action };
}

const rows = [
  createData(
    0,
    "$100 pack monthly",
    "Monthly",
    20,
    "January 1, 2023 - December 31, 2023",
    "Active",
    action
  ),
];

function SubscriptionPlanTable() {
  let navigate = useNavigate();

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" gutterBottom>
        Subscriptions
      </Typography>
      <TableContainer component={Paper} variant="outlined">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>Subscription Name</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>Subscription frequency</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>No of Subscribers</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>Available period</TableCell>

            <TableCell sx={{fontWeight: 'bold'}}>status</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              onClick={() => {
                navigate(`/subscriptions-plans/${row?.subName}`);
              }}
              key={row.id}
            >
              <TableCell>{row.subName}</TableCell>
              <TableCell>{row.subFreq}</TableCell>
              <TableCell>{row.noOfSub}</TableCell>
              <TableCell>{row.availablePeriod}</TableCell>

              <TableCell>{row.status}</TableCell>
              <TableCell>{row.action}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
    </React.Fragment>
  );
}
export default SubscriptionPlanTable;
