import { Box, CircularProgress } from "@mui/material";
import React from "react";

function MainLoader() {
  return (
    <div className="flex mt-60 justify-center items-center w-full h-full">
      <CircularProgress />
    </div>
  );
}

export default MainLoader;
