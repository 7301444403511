import React, { useEffect, useState } from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import styled from "@emotion/styled";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Button,
  Pagination,
  Stack,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import AccountsDetailsTable from "../../components/tables/AccountsDetailsTable";
import { GetPayableApi } from "../../api/accounts/GetPayableApi";
import { useLocation, useSearchParams } from "react-router-dom";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function AccountsDetails() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shopId = queryParams.get("shop");
  const [payable, setPayable] = useState();
  const [filterStatus, setFilterStatus] = useState(false);
  const [filterBy, setFilterBy] = useState();

  useEffect(() => {
    getPayable(1);
  }, []);

  async function getPayable(page) {
    const apiResponse = await GetPayableApi(page, shopId, new Date());
    if (apiResponse.success) {
      setPayable(apiResponse.data);
    }
  }

  function handlePageChange(event, num) {
    changeTablePage(num);
  }

  function changeTablePage(num) {
    if (filterStatus) {
      // getFilteredProduct(filterBy, num);
    } else {
      getPayable(num);
    }
  }

  function resetFilter() {
    setFilterStatus(false);
    setFilterBy();
  }

  const right = (
    <>
      <div className="flex items-center gap-2">
        <div>
          <BootstrapTooltip title="Refresh" placement="top">
            <Button
              sx={{
                width: "50px",
                minWidth: "unset",
              }}
              variant="contained"
            >
              <RefreshIcon
                sx={{
                  fontSize: "25px",
                  color: "primary",
                }}
              />
            </Button>
          </BootstrapTooltip>
        </div>
        <div>
          <BootstrapTooltip title="Filter" placement="top">
            <Button
              sx={{
                width: "50px",
                minWidth: "unset",
              }}
              variant="contained"
            >
              <FilterAltOutlinedIcon sx={{ fontSize: "25px" }} />
            </Button>
          </BootstrapTooltip>
        </div>
      </div>
    </>
  );
  const left = <div>Accounts / Details</div>;
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        mt: 10,
        ml: 2,
        mr: 2,
        mb: 4,
      }}
    >
      <DashboardBreadcrumb left={left} right={right} />
      <AccountsDetailsTable payable={payable?.accounts} />
      {payable?.totalCount > 10 && (
        <Stack alignItems="center" mt={2}>
          <Pagination
            count={Math.ceil(payable?.totalCount / 10)}
            siblingCount={0}
            onChange={handlePageChange}
            variant="outlined"
            color="primary"
            shape="rounded"
          />
        </Stack>
      )}
    </Box>
  );
}

export default AccountsDetails;
