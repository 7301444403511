import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormHelperText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import NotificationHelper from "../../components/helpers/NotificationHelper";
import CreateNewPasswordApi from "../../api/auth/CreateNewPasswordApi";

function ChangePassword() {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [form, setForm] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword((show) => !show);
  };
  const handleClickShowNewPassword = () => {
    setShowNewPassword((show) => !show);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function isStrongPassword(password) {
    let strength = false;
    const passwordArray = password.split("");
    let lowercase = false;
    let uppercase = false;
    let number = false;
    let length = false;
    // Validate lowercase letters
    const lowerCaseLetters = /[a-z]/g;
    passwordArray.forEach((pass) => {
      if (pass.match(lowerCaseLetters)) {
        lowercase = true;
      }
    });
    // Validate capital letters
    const upperCaseLetters = /[A-Z]/g;
    passwordArray.forEach((pass) => {
      if (pass.match(upperCaseLetters)) {
        uppercase = true;
      }
    });
    // Validate numbers
    const numbers = /[0-9]/g;
    passwordArray.forEach((pass) => {
      if (pass.match(numbers)) {
        number = true;
      }
    });
    // Validate length
    if (passwordArray.length >= 8) {
      length = true;
    }
    if (lowercase && uppercase && number && length) {
      strength = true;
    }

    return strength;
  }

  async function handleSubmit(event) {
    setSubmit(true);

    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const currentPassword = data.get("currentPassword");
    const newPassword = data.get("newPassword");
    const confirmPassword = data.get("confirmPassword");

    if (newPassword && !isStrongPassword(newPassword)) {
      setNewPasswordError("Enter a strong password!");
      setNotification({
        open: true,
        type: "error",
        message:
          "Your password must be at least 8 characters long, contain at least one number, one uppercase letter and one lowercase letter.",
      });
      setSubmit(false);
    } else if (!currentPassword || !newPassword || !confirmPassword) {
      if (!currentPassword) {
        setCurrentPasswordError("Current password cannot be empty!");
      }
      if (!newPassword) {
        setNewPasswordError("New password cannot be empty!");
      }
      if (!confirmPassword) {
        setConfirmPasswordError("Confirm password cannot be empty!");
      }
      setSubmit(false);
    }

    if (
      isStrongPassword(newPassword) &&
      confirmPassword &&
      newPassword !== confirmPassword
    ) {
      setConfirmPasswordError("Password did not match the new password!");
      setSubmit(false);
    }

    if (
      currentPassword &&
      newPassword &&
      confirmPassword &&
      isStrongPassword(newPassword) &&
      isStrongPassword(confirmPassword) &&
      newPassword === confirmPassword
    ) {
      const apiResponse = await CreateNewPasswordApi(
        currentPassword,
        newPassword,
        confirmPassword
      );

      if (apiResponse.message === "Current password is incorrect") {
        setCurrentPasswordError("Current password is incorrect");
      } else if (apiResponse.error) {
        setNotification({
          open: true,
          type: "error",
          message: apiResponse.message,
        });
      } else if (apiResponse.success) {
        setForm({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        setShowCurrentPassword(false);
        setShowNewPassword(false);
        setShowConfirmPassword(false);
        setNotification({
          open: true,
          type: "success",
          message: apiResponse.message,
        });
      }
      setSubmit(false);
    }
  }

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          mt: 10,
          ml: 2,
          mr: 2,
          mb: 4,
        }}
      >
        <Typography component="h2" variant="h6">
          Change Password
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate>
          <div>
            <FormControl
              variant="outlined"
              sx={{ mb: 2, mt: 2, width: "40ch" }}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                size="small"
                error={currentPasswordError ? true : false}
              >
                Current Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                value={form.currentPassword}
                onChange={(event) => {
                  setCurrentPasswordError("");
                  setForm({ ...form, currentPassword: event.target.value });
                }}
                fullWidth
                name="currentPassword"
                label="Current password"
                type={showCurrentPassword ? "text" : "password"}
                size="small"
                helperText={currentPasswordError ? currentPasswordError : " "}
                endAdornment={
                  <Stack direction="row">
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowCurrentPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showCurrentPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  </Stack>
                }
              />
              <FormHelperText
                sx={{ mb: -2 }}
                error={currentPasswordError ? true : false}
                id="outlined-weight-helper-text"
              >
                {currentPasswordError ? currentPasswordError : " "}
              </FormHelperText>
            </FormControl>
          </div>

          <div>
            <FormControl
              variant="outlined"
              sx={{ mb: 2, mt: 1, width: "40ch" }}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                size="small"
                error={newPasswordError ? true : false}
              >
                New password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                value={form.newPassword}
                onChange={(event) => {
                  setNewPasswordError("");
                  setForm({ ...form, newPassword: event.target.value });
                }}
                fullWidth
                name="newPassword"
                label="New password"
                type={showNewPassword ? "text" : "password"}
                size="small"
                helperText={newPasswordError ? newPasswordError : " "}
                endAdornment={
                  <Stack direction="row">
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  </Stack>
                }
              />
              <FormHelperText
                sx={{ mb: -2 }}
                error={newPasswordError ? true : false}
                id="outlined-weight-helper-text"
              >
                {newPasswordError ? newPasswordError : " "}
              </FormHelperText>
            </FormControl>
          </div>
          <div>
            <FormControl
              variant="outlined"
              sx={{ mb: 3, mt: 1, width: "40ch" }}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                size="small"
                error={confirmPasswordError ? true : false}
              >
                Confirm password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                value={form.confirmPassword}
                onChange={(event) => {
                  setConfirmPasswordError("");
                  setForm({ ...form, confirmPassword: event.target.value });
                }}
                fullWidth
                name="confirmPassword"
                label="Confirm password"
                type={showConfirmPassword ? "text" : "password"}
                size="small"
                helperText={confirmPasswordError ? confirmPasswordError : " "}
                endAdornment={
                  <Stack direction="row">
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  </Stack>
                }
              />
              <FormHelperText
                sx={{ mb: -2 }}
                error={confirmPasswordError ? true : false}
                id="outlined-weight-helper-text"
              >
                {confirmPasswordError ? confirmPasswordError : " "}
              </FormHelperText>
            </FormControl>
          </div>

          <Stack direction="row" spacing={2}>
            <LoadingButton
              loading={submit}
              loadingPosition="start"
              variant="contained"
              type="submit"
              size="small"
              fullWidth
              sx={{ width: "160px", textTransform: "none" }}
            >
              Update Password
            </LoadingButton>
            <Button
              sx={{ width: "160px", textTransform: "none" }}
              variant="outlined"
              size="small"
              onClick={() => {
                setForm({
                  currentPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                });
                setCurrentPasswordError("");
                setNewPasswordError("");
                setConfirmPasswordError("");
                setShowCurrentPassword(false);
                setShowNewPassword(false);
                setShowConfirmPassword(false);
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
      <NotificationHelper notification={notification} />
    </>
  );
}

export default ChangePassword;
