import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

function SetupProfileRoute() {
  const firstLoginState = useSelector((state) => state.firstLoginState.value);

  if (!firstLoginState) {
    return <Navigate to={"/website-layout"} replace />;
  } else {
    return <Outlet />;
  }
}

export default SetupProfileRoute;
