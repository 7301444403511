import React from "react";
import Typography from "@mui/material/Typography";

function Transfers() {
  return (
    <>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Recent Deposits
      </Typography>
      <Typography component="p" variant="h4">
        $1000.00
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        on 14 April, 2023
      </Typography>
    </>
  );
}

export default Transfers;
