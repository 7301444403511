import React from "react";

function DashboardBreadcrumb(props) {
  return (
    <div className="mb-1">
      <div className="flex justify-between items-center h-8">
        <div className="flex items-center font-lg text-gray-500">
          {props.left}
        </div>
        <div className="flex items-center mt-2">{props.right}</div>
      </div>
    </div>
  );
}

export default DashboardBreadcrumb;
