import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import EditMembershipModal from "../modals/edit/EditMembershipModal";
import SubsChangeInfoModal from "../modals/SubsChangeInfoModal";

function MembershipsTable(props) {
  const [editMembershipModal, setEditMembershipModal] = useState(false);
  const [changeInfoModal, setChangeInfoModal] = useState(false);
  const [selectedMembership, setSelectedMembership] = useState({});

  return (
    <>
      <Typography component="h2" variant="h6" gutterBottom>
        Memberships
      </Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Listings</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Boosts</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Subscription</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.memberships?.map((membership) => (
              <TableRow key={membership.id}>
                <TableCell>{membership.name || "-"}</TableCell>
                <TableCell>{membership.numberOfListing || "-"}</TableCell>
                <TableCell>{membership.noOfBoost || "-"}</TableCell>
                <TableCell>
                  {membership.price ? `$ ${membership.price}` : "-"}
                </TableCell>
                <TableCell>
                  <div className="flex items-center gap-2">
                    <EditOutlinedIcon
                      onClick={() => {
                        setSelectedMembership(membership);
                        setEditMembershipModal(true);
                      }}
                      style={{ fontSize: "20px" }}
                      className="text-blue-500 cursor-pointer"
                    />
                    {membership.changeDate !== null && (
                      <PublishedWithChangesIcon
                        onClick={() => {
                          setSelectedMembership(membership);
                          setChangeInfoModal(true);
                        }}
                        style={{ fontSize: "20px" }}
                        className="text-green-500 cursor-pointer"
                      />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditMembershipModal
        open={editMembershipModal}
        onClose={() => {
          setSelectedMembership({});
          setEditMembershipModal(false);
        }}
        handleOk={() => props.refetch()}
        selectedMembership={selectedMembership}
      />
      <SubsChangeInfoModal
        open={changeInfoModal}
        selectedMembership={selectedMembership}
        onClose={() => {
          setSelectedMembership({});
          setChangeInfoModal(false);
        }}
      />
    </>
  );
}

export default MembershipsTable;
