import React, { useEffect, useState } from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ProductsTable from "../../components/tables/ProductsTable";
import GetProductsTableApi from "../../api/products/GetProductsTableApi";
import { Badge, Button, Pagination, Stack } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ProductFilterModal from "../../components/modals/filters/ProductFilterModal";
import GetFilteredProductTableApi from "../../api/products/GetFilteredProductTableApi";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function Products() {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [filterModal, setFilterModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filterBy, setFilterBy] = useState();
  const [priceRange, setPriceRange] = useState([]);

  useEffect(() => {
    {
      filterStatus ? getFilteredProduct(page) : getProducts(page);
    }
  }, [page]);

  const handleChange = (event, num) => {
    changeTablePage(num);
  };

  async function getProducts(num) {
    const apiResponse = await GetProductsTableApi(num);
    if (apiResponse.success) {
      setProducts(apiResponse.data);
      setPage(num);
    }
  }

  const handlePriceRange = (newData) => {
    setPriceRange(newData);
  };

  async function getFilteredProduct(event, num) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const apiResponse = await GetFilteredProductTableApi(
      data.get("category") || "",
      priceRange[0] || "",
      priceRange[1] || "",
      num
    );

    if (apiResponse.success) {
      setProducts(apiResponse.data);
      setFilterStatus(true);
      setFilterBy({
        category: data.get("category"),
        startPrice: priceRange[0],
        endPrice: priceRange[1],
      });
      setPage(num);
    }
  }

  function changeTablePage(num) {
    if (filterStatus) {
      getFilteredProduct(filterBy, num);
    } else {
      getProducts(num);
    }
  }

  function resetFilter() {
    getProducts(1);
    setPriceRange([]);
    setFilterStatus(false);
    setFilterBy();
  }

  const right = (
    <div className="flex items-center gap-2">
      <div>
        <BootstrapTooltip title="Refresh" placement="top">
          <Button
            onClick={() => resetFilter()}
            sx={{
              width: "50px",
              minWidth: "unset",
            }}
            variant="contained"
            disabled={!filterStatus}
          >
            <RefreshIcon
              sx={{
                fontSize: "25px",
                color: "primary",
              }}
            />
          </Button>
        </BootstrapTooltip>
      </div>
      <div onClick={() => setFilterModal(true)}>
        <BootstrapTooltip title="Filter" placement="top">
          <Button
            sx={{
              width: "50px",
              minWidth: "unset",
            }}
            variant="contained"
          >
            {filterStatus ? (
              <Badge
                color="secondary"
                overlap="circular"
                badgeContent=" "
                variant="dot"
              >
                <FilterAltOutlinedIcon sx={{ fontSize: "25px" }} />
              </Badge>
            ) : (
              <FilterAltOutlinedIcon sx={{ fontSize: "25px" }} />
            )}
          </Button>
        </BootstrapTooltip>
      </div>
    </div>
  );
  const left = <div>Products</div>;

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          mt: 10,
          ml: 2,
          mr: 2,
          mb: 4,
        }}
      >
        <DashboardBreadcrumb left={left} right={right} />
        <ProductsTable
          products={products?.products}
          page={page}
          getProducts={(num) => changeTablePage(num)}
        />
        {products.totalCount > 10 && (
          <Stack alignItems="center" mt={2}>
            <Pagination
              count={Math.ceil(products.totalCount / 10)}
              siblingCount={0}
              page={page}
              onChange={handleChange}
              variant="outlined"
              color="primary"
              shape="rounded"
            />
          </Stack>
        )}
      </Box>
      <ProductFilterModal
        keepMounted
        open={filterModal}
        filterBy={filterBy}
        filterStatus={filterStatus}
        priceRange={(value) => handlePriceRange(value)}
        handleOk={(num) => {
          getFilteredProduct(num);
        }}
        onClose={() => setFilterModal(false)}
      />
    </>
  );
}

export default Products;
