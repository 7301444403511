import React, { useEffect, useState } from "react";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import List from "@mui/material/List";
import { useLocation, useNavigate } from "react-router-dom";
import StarBorder from "@mui/icons-material/StarBorder";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import CategoryIcon from "@mui/icons-material/Category";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import InventoryIcon from "@mui/icons-material/Inventory";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import GradeIcon from "@mui/icons-material/Grade";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import HistoryIcon from "@mui/icons-material/History";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
function DashboardSideMenu() {
  const [selectedIndex, setSelectedIndex] = useState("");
  const [open, setOpen] = useState(false);
  let navigate = useNavigate();

  let curLocation = useLocation();

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const paths = [
    // { path: "/dashboard", index: 0 },
    { path: "/website-layout", index: 1 },
    { path: "/categories", index: 2 },
    { path: "/subscriptions-plans", index: 3 },
    { path: "/sellers", index: 4 },
    { path: "/shops", index: 9 },
    { path: "/products", index: 5 },
    { path: "/users", index: 6 },
    { path: "/admin", index: 7 },
    { path: "/memberships", index: 16 },
    { path: "/boost-plans", index: 10 },
    { path: "/gift-vouchers", index: 11 },
    { path: "/points", index: 13 },
    // { path: "/accounts", index: 14 },
    // { path: "/accounts-history", index: 15 },
  ];

  useEffect(() => {
    paths.forEach((path) => {
      if (path.path === curLocation.pathname) {
        setSelectedIndex(path.index);
        if (path.path === "/shops") {
          setOpen(true);
        }
        if (path.path === "/sellers") {
          setOpen(true);
        }
      }
      if (curLocation.pathname.split("/")[1] === "accounts") {
        setSelectedIndex(14);
      }
    });
  }, [curLocation]);

  return (
    <>
      <List>
        {/* <ListItemButton
          selected={selectedIndex === 0}
          onClick={(event) => {
            handleListItemClick(event, 0);
            navigate(`/dashboard`);
          }}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton> */}

        {/* <ListItemButton
          selected={selectedIndex === 14}
          onClick={(event) => {
            handleListItemClick(event, 14);
            navigate(`/accounts`);
          }}
        >
          <ListItemIcon>
            <AccountBalanceWalletIcon />
          </ListItemIcon>
          <ListItemText primary="Accounts" />
        </ListItemButton>

        <ListItemButton
          selected={selectedIndex === 15}
          onClick={(event) => {
            handleListItemClick(event, 15);
            navigate(`/accounts-history`);
          }}
        >
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText primary="Accounts History" />
        </ListItemButton> */}

        <ListItemButton
          selected={selectedIndex === 1}
          onClick={(event) => {
            handleListItemClick(event, 1);
            navigate(`/website-layout`);
          }}
        >
          <ListItemIcon>
            <WebAssetIcon />
          </ListItemIcon>
          <ListItemText primary="Website Layout" />
        </ListItemButton>

        <ListItemButton
          selected={selectedIndex === 2}
          onClick={(event) => {
            handleListItemClick(event, 2);
            navigate(`/categories`);
          }}
        >
          <ListItemIcon>
            <CategoryIcon />
          </ListItemIcon>
          <ListItemText primary="Categories" />
        </ListItemButton>

        {/* <ListItemButton
          selected={selectedIndex === 3}
          onClick={(event) => {
            handleListItemClick(event, 3);
            navigate(`/subscriptions-plans`);
          }}
        >
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary="Subcription plans" />
        </ListItemButton> */}

        {/* <ListItemButton
          onClick={(event) => {
            handleListItemClick(event, 4);
            handleClick();
            navigate(`/sellers`);
          }}
        >
          <ListItemIcon>
            <SellIcon />
          </ListItemIcon>
          <ListItemText primary="Sellers" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding> */}
        {/* <ListItemButton
              sx={{ pl: 4 }}
              selected={selectedIndex === 4}
              onClick={(event) => {
                handleListItemClick(event, 4);
                navigate(`/sellers`);
              }}
            >
              <ListItemIcon>
                <StorefrontOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Registered Shops" />
            </ListItemButton> */}
        <ListItemButton
          //sx={{ pl: 4 }}
          selected={selectedIndex === 9}
          onClick={(event) => {
            handleListItemClick(event, 9);
            navigate(`/shops`);
          }}
        >
          <ListItemIcon>
            <StarBorder />
          </ListItemIcon>
          <ListItemText primary="Shops" />
        </ListItemButton>
        {/* </List>
        </Collapse> */}
        <ListItemButton
          selected={selectedIndex === 5}
          onClick={(event) => {
            handleListItemClick(event, 5);
            navigate(`/products`);
          }}
        >
          <ListItemIcon>
            <InventoryIcon />
          </ListItemIcon>
          <ListItemText primary="Products" />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 6}
          onClick={(event) => {
            handleListItemClick(event, 6);
            navigate(`/users`);
          }}
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 7}
          onClick={(event) => {
            handleListItemClick(event, 7);
            navigate(`/admin`);
          }}
        >
          <ListItemIcon>
            <AdminPanelSettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Admin" />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 16}
          onClick={(event) => {
            handleListItemClick(event, 16);
            navigate(`/memberships`);
          }}
        >
          <ListItemIcon>
            <CardMembershipIcon />
          </ListItemIcon>
          <ListItemText primary="Memberships" />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 10}
          onClick={(event) => {
            handleListItemClick(event, 10);
            navigate(`/boost-plans`);
          }}
        >
          <ListItemIcon>
            <RocketLaunchIcon />
          </ListItemIcon>
          <ListItemText primary="Boost Plans" />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 11}
          onClick={(event) => {
            handleListItemClick(event, 11);
            navigate(`/gift-vouchers`);
          }}
        >
          <ListItemIcon>
            <CardGiftcardIcon />
          </ListItemIcon>
          <ListItemText primary="Gift Vouchers" />
        </ListItemButton>

        {/* <ListItemButton
          selected={selectedIndex === 12}
          onClick={(event) => {
            handleListItemClick(event, 12);
            navigate(`/contests`);
          }}
        >
          <ListItemIcon>
            <EmojiEventsIcon />
          </ListItemIcon>
          <ListItemText primary="Contests" />
        </ListItemButton> */}

        <ListItemButton
          selected={selectedIndex === 13}
          onClick={(event) => {
            handleListItemClick(event, 13);
            navigate(`/points`);
          }}
        >
          <ListItemIcon>
            <GradeIcon />
          </ListItemIcon>
          <ListItemText primary="Points" />
        </ListItemButton>
      </List>
    </>
  );
}

export default DashboardSideMenu;
