import React, { useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Paper from "@mui/material/Paper";
import { STATUS } from "../helpers/Constant";
import EditGiftCardModal from "../modals/edit/EditGiftCardModal";
import ConfirmationModal from "../modals/ConfirmationModal";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import DisableEnableGiftApi from "../../api/gift-vouchers/DisableEnableGiftApi";
import NotificationHelper from "../helpers/NotificationHelper";
import HardDeleteModal from "../modals/HardDeleteModal";
import DeleteGiftApi from "../../api/gift-vouchers/DeleteGiftApi";
import { CustomNoRowsOverlay } from "../helpers/NoData";

const action = (
  <div className="flex justify-items-center gap-1">
    <EditOutlinedIcon style={{ fontSize: "20px" }} className="text-blue-500" />
    <BlockOutlinedIcon
      style={{ fontSize: "20px" }}
      className="text-orange-500"
    />
    <DeleteOutlinedIcon style={{ fontSize: "20px" }} className="text-red-500" />
  </div>
);

function GiftVouchersTable(props) {
  const [page, setPage] = useState(1);
  const [editGiftModal, setEditGiftModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [enableDisableGiftModal, setEnableDisableGiftModal] = useState(false);
  const [enableDisableLoading, setEnableDisableLoading] = useState(false);
  const [selectGiftStatus, setSelectGiftStatus] = useState();
  const [giftId, setGiftId] = useState("");
  const [deleteGiftModal, setDeleteGiftModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    setPage(props.page);
  }, [props.page]);

  async function disableEnableGift() {
    setEnableDisableLoading(true);
    const apiResponse = await DisableEnableGiftApi(giftId, selectGiftStatus);

    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse.success) {
      setNotification({
        open: true,
        type: "success",
        message: apiResponse.message,
      });
      props.changeTablePage();
    }
    setEnableDisableLoading(false);
    setGiftId("");
    setEnableDisableGiftModal(false);
  }

  async function deleteGift() {
    setDeleteLoading(true);
    const apiResponse = await DeleteGiftApi(giftId);

    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse.success) {
      setNotification({
        open: true,
        type: "success",
        message: apiResponse.message,
      });
      if (
        props.gifts?.length === 1
      ) {
        props.changeTablePage(page - 1);
      } else {
        props.changeTablePage(page);
      }
    }
    setPage(page);
    setDeleteLoading(false);
    setGiftId("");
    setDeleteGiftModal(false);
  }

  return (
    <>
      <Typography component="h2" variant="h6" gutterBottom>
        Gift Cards
      </Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Gift Card name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Value</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Gift Card Image</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Sold</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Validity period</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          {props.gifts?.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  <CustomNoRowsOverlay message={"No Gift Voucher"} />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.gifts?.map((gift) => (
                <TableRow
                  key={gift._id}
                  sx={{
                    "& > *": {
                      padding: "6px",
                    },
                  }}
                >
                  <TableCell>{gift.name}</TableCell>
                  <TableCell>$ {gift.value}</TableCell>
                  <TableCell>
                    <img className="w-20" src={gift.imgUrl} />
                  </TableCell>
                  <TableCell>{gift.sold}</TableCell>
                  <TableCell>{gift.validityPeriod}</TableCell>
                  <TableCell>
                    {gift.status === STATUS.Active ? "Active" : "Disabled"}
                  </TableCell>
                  <TableCell>
                    <div className="flex justify-items-center gap-1">
                      <div
                        onClick={() => {
                          setSelectedRow(gift);
                          setEditGiftModal(true);
                        }}
                      >
                        <EditOutlinedIcon
                          style={{ fontSize: "20px" }}
                          className="text-blue-500"
                        />
                      </div>
                      {gift.status === STATUS.Disabled ? (
                        <div
                          onClick={() => {
                            setGiftId(gift._id);
                            setSelectGiftStatus(STATUS.Active);
                            setEnableDisableGiftModal(true);
                          }}
                        >
                          <CheckCircleOutlinedIcon
                            style={{ fontSize: "20px" }}
                            className="text-green-500"
                          />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setGiftId(gift._id);
                            setSelectGiftStatus(STATUS.Disabled);
                            setEnableDisableGiftModal(true);
                          }}
                        >
                          <BlockOutlinedIcon
                            style={{ fontSize: "20px" }}
                            className="text-orange-500"
                          />
                        </div>
                      )}
                      <div
                        onClick={() => {
                          setGiftId(gift._id);
                          setDeleteGiftModal(true);
                        }}
                      >
                        <DeleteOutlinedIcon
                          style={{ fontSize: "20px" }}
                          className="text-red-500"
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <NotificationHelper notification={notification} />
      <EditGiftCardModal
        keepMounted
        open={editGiftModal}
        handleOk={(num) => {
          props.changeTablePage(num);
        }}
        onClose={() => setEditGiftModal(false)}
        selectedRow={selectedRow}
      />
      <ConfirmationModal
        keepMounted
        title={
          selectGiftStatus === STATUS.Disabled
            ? "Disable Gift Card"
            : "Enable Gift Card"
        }
        description={
          selectGiftStatus === STATUS.Disabled
            ? "Are you sure want to disable this gift card?"
            : "Are you sure want to Enable this gift card?"
        }
        loading={enableDisableLoading}
        open={enableDisableGiftModal}
        handleOk={() => disableEnableGift()}
        onClose={() => {
          setEnableDisableGiftModal(false);
          setGiftId("");
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      />
      <HardDeleteModal
        keepMounted
        title="Delete Gift Card"
        description="Are you sure want to delete this gift?"
        open={deleteGiftModal}
        loading={deleteLoading}
        handleOk={() => deleteGift()}
        onClose={() => {
          setDeleteGiftModal(false);
          setGiftId("");
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      />
    </>
  );
}
export default GiftVouchersTable;
