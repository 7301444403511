import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { GetCategoryApi } from "../../../api/category/GetCategoryApi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ProductFilterModal(props) {
  const [category, setCategory] = useState("");
  const [submit, setSubmit] = useState(false);
  const [priceValues, setPriceValues] = useState([1000, 3000]);
  const [categories, setCategories] = useState();

  useEffect(() => {
    setCategory(props.filterBy?.category || "");
    setPriceValues([
      props.filterBy?.startPrice || 1000,
      props.filterBy?.endPrice || 3000,
    ]);
  }, [
    props.filterBy?.category,
    props.filterBy?.startPrice,
    props.filterBy?.endPrice,
  ]);
  useEffect(() => {
    getCategories();
  }, []);

  async function getCategories() {
    const apiResponse = await GetCategoryApi();
    if (apiResponse.success) {
      setCategories(apiResponse.data);
    }
  }

  function onCancel() {
    setSubmit(false);
    if (props.filterStatus) {
      setCategory(category);
      setPriceValues(priceValues);
    } else {
      setCategory("");
      setPriceValues([1000, 3000]);
    }
    props.onClose();
  }

  const handleSubmit = (num) => {
    setSubmit(true);
    props.handleOk(num);
    onCancel();
  };

  const handleChange = (event, newValue) => {
    props.priceRange(newValue);
    setPriceValues(newValue);
  };

  const marks = [
    {
      value: 0,
      label: "$0",
    },
    {
      value: 1000,
      label: "$1000",
    },
    {
      value: 3000,
      label: "$3000",
    },
    {
      value: 6000,
      label: "$6000",
    },
  ];

  return (
    <Modal
      keepMounted
      open={props.open}
      onClose={() => onCancel()}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style} component="form" onSubmit={handleSubmit}>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Product Filter
          </Typography>
          <CloseIcon onClick={() => onCancel()} />
        </Stack>

        <FormControl size="small" sx={{ mt: 2, width: "100%" }}>
          <InputLabel sx={{ width: "100%" }} id="demo-single-name-label">
            Category
          </InputLabel>
          <Select
            labelId="demo-single-name-label"
            id="demo-single-name"
            name="category"
            value={category}
            onChange={(event) => setCategory(event.target.value)}
            input={<OutlinedInput label="Category" />}
            MenuProps={MenuProps}
          >
            {categories?.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography sx={{ mt: 2 }}>Price Range</Typography>
        <Stack sx={{ mt: 2, ml: 2, mr: 2 }}>
          <Slider
            aria-label="Range Slider"
            name="priceRange"
            value={priceValues}
            onChange={handleChange}
            valueLabelDisplay="auto"
            min={10}
            max={6000}
            marks={marks}
          />
        </Stack>

        <Stack sx={{ mt: 2 }} alignItems="flex-start" direction="row" gap={2}>
          <Typography variant="body2">Selected Price Range:</Typography>
          <Typography variant="body2">
            {`$ ${priceValues[0]} - $ ${priceValues[1]}`}
          </Typography>
        </Stack>

        <Stack sx={{ mt: 2 }} justifyContent="end" direction="row" spacing={1}>
          <Button
            variant="outlined"
            sx={{ width: 100, textTransform: "none" }}
            size="small"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
          <LoadingButton
            size="large"
            loadingPosition="start"
            loading={submit}
            variant="contained"
            type="submit"
            fullWidth
            sx={{
              mt: 2,
              textTransform: "none",
              width: 100,
            }}
          >
            <Typography variant="body1">Filter</Typography>
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
}

export default ProductFilterModal;
