import React from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ProductCard from "../../components/cards/ProductCard";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

function getStyles(name, frequency, theme) {
  return {
    fontWeight:
      frequency.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function CreateSubscriptions() {
  const [abb, setAbb] = React.useState(0);
  const [frequency, setFrequency] = React.useState([]);
  const theme = useTheme();

  const handleChangeCurrencyAbbreviation = (event) => {
    setAbb(event.target.value);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFrequency(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const left = <div>Subscriptions plans / Create subscriptions</div>;

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        mt: 10,
        ml: 2,
        mr: 2,
        mb: 4,
      }}
    >
      <DashboardBreadcrumb left={left} />
      <Typography component="h2" variant="h6">
        Create subscriptions
      </Typography>

      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ mt: 1 }}
      >
        <Grid item xs={3}>
          <TextField
            size="small"
            label="Subscription name"
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            className="addon--parent"
            sx={{ width: 250 }}
            size="small"
            label="Sub Price"
            variant="outlined"
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Select
                    size="small"
                    sx={{ width: 100 }}
                    value={abb}
                    onChange={handleChangeCurrencyAbbreviation}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={0}>USD</MenuItem>
                    <MenuItem value={1}>EUR</MenuItem>
                  </Select>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl size="small" sx={{ width: 250 }}>
            <InputLabel id="demo-multiple-name-label" required>
              Sub frequency
            </InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={frequency}
              onChange={handleChange}
              input={<OutlinedInput label="No of boost" />}
              MenuProps={MenuProps}
            >
              {numbers.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, frequency, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <TextField
            size="small"
            label="Available period"
            variant="outlined"
            required
          />
        </Grid>
      </Grid>

      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ mt: 1 }}
      >
        <Grid item xs={6}>
          <div>Products</div>
          <TextField
            size="small"
            type="search"
            fullWidth
            required
            placeholder="Search"
          />
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={1}
        sx={{ mt: 1 }}
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {Array.from(Array(6)).map((_, index) => (
          <Grid item xs={2} sm={4} md={3} key={index}>
            <ProductCard />
          </Grid>
        ))}
      </Grid>

      <Stack sx={{ mt: 2 }} spacing={2} direction="row">
        <Button
          sx={{ width: "80px", textTransform: "none" }}
          variant="contained"
        >
          Create
        </Button>
        <Button
          sx={{ width: "80px", textTransform: "none" }}
          variant="outlined"
        >
          Cancel
        </Button>
      </Stack>
    </Box>
  );
}

export default CreateSubscriptions;
