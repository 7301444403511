import React, { useEffect, useState } from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import BoostPlansTable from "../../components/tables/BoostPlansTable";
import AddBoostPlanModal from "../../components/modals/add/AddBoostPlanModal";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import GetBoostPlanTableApi from "../../api/boost-plans/GetBoostPlanTableApi";
import { Badge, Button, Pagination, Stack } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import BoostPlanFilterModal from "../../components/modals/filters/BoostPlanFilterModal";
import GetFilteredBoostPlansTableApi from "../../api/boost-plans/GetFilteredBoostPlansTableApi";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BoostPlans() {
  const [openAddBoostPlan, setOpenAddBoostPlan] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [filterModal, setFilterModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filterBy, setFilterBy] = useState();
  const [priceRange, setPriceRange] = useState([]);

  useEffect(() => {
    {
      filterStatus ? getFilteredBoostPlans(page) : getBoostPlans(page);
    }
  }, [page]);

  const handleChange = (event, num) => {
    changeTablePage(num);
  };

  async function getBoostPlans(num) {
    const apiResponse = await GetBoostPlanTableApi(num);

    if (apiResponse.success) {
      setData(apiResponse.data);
      setPage(num);
    }
  }

  const handlePriceRange = (newData) => {
    setPriceRange(newData);
  };

  async function getFilteredBoostPlans(event, num) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const apiResponse = await GetFilteredBoostPlansTableApi(
      data.get("name") || "",
      priceRange[0] || "",
      priceRange[1] || "",
      data.get("noOfBoost") || "",
      data.get("status") || "",
      num
    );
    if (apiResponse.success) {
      setData(apiResponse.data);
      setFilterStatus(true);
      setFilterBy({
        name: data.get("name"),
        startPrice: priceRange[0],
        endPrice: priceRange[1],
        noOfBoost: data.get("noOfBoost"),
        status: data.get("status"),
      });
      setPage(num);
    }
  }

  function changeTablePage(num) {
    if (filterStatus) {
      getFilteredBoostPlans(filterBy, num);
    } else {
      getBoostPlans(num);
    }
  }

  function resetFilter() {
    getBoostPlans(1);
    setPriceRange([]);
    setFilterStatus(false);
    setFilterBy();
  }

  const right = (
    <div className="flex items-center gap-2">
      <div>
        <BootstrapTooltip title="Refresh" placement="top">
          <Button
            onClick={() => resetFilter()}
            sx={{
              width: "50px",
              minWidth: "unset",
            }}
            variant="contained"
            disabled={!filterStatus}
          >
            <RefreshIcon
              sx={{
                fontSize: "25px",
                color: "primary",
              }}
            />
          </Button>
        </BootstrapTooltip>
      </div>
      <div onClick={() => setFilterModal(true)}>
        <BootstrapTooltip title="Filter" placement="top">
          <Button
            sx={{
              width: "50px",
              minWidth: "unset",
            }}
            variant="contained"
          >
            {filterStatus ? (
              <Badge
                color="secondary"
                overlap="circular"
                badgeContent=" "
                variant="dot"
              >
                <FilterAltOutlinedIcon sx={{ fontSize: "25px" }} />
              </Badge>
            ) : (
              <FilterAltOutlinedIcon sx={{ fontSize: "25px" }} />
            )}
          </Button>
        </BootstrapTooltip>
      </div>
      <div onClick={() => setOpenAddBoostPlan(true)}>
        <BootstrapTooltip title="Add" placement="top">
          <Button
            sx={{
              width: "50px",
              minWidth: "unset",
            }}
            variant="contained"
          >
            <AddOutlinedIcon style={{ fontSize: "25px" }} />
          </Button>
        </BootstrapTooltip>
      </div>
    </div>
  );

  const left = <div>Boost Plans</div>;
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        mt: 10,
        ml: 2,
        mr: 2,
        mb: 4,
      }}
    >
      <DashboardBreadcrumb left={left} right={right} />
      <BoostPlansTable
        boosts={data?.boosts}
        page={page}
        changeTablePage={(num) => changeTablePage(num)}
      />
      {data.totalCount > 10 && (
        <Stack alignItems="center" mt={2}>
          <Pagination
            count={Math.ceil(data?.totalCount / 10)}
            siblingCount={0}
            page={page}
            onChange={handleChange}
            variant="outlined"
            color="primary"
            shape="rounded"
          />
        </Stack>
      )}
      <AddBoostPlanModal
        keepMounted
        open={openAddBoostPlan}
        handleOk={(num) => getBoostPlans(num)}
        onClose={() => setOpenAddBoostPlan(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      />
      <BoostPlanFilterModal
        keepMounted
        open={filterModal}
        filterStatus={filterStatus}
        filterBy={filterBy}
        priceRange={(value) => handlePriceRange(value)}
        handleOk={(num) => {
          getFilteredBoostPlans(num);
        }}
        onClose={() => setFilterModal(false)}
      />
    </Box>
  );
}

export default BoostPlans;
