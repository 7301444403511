import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Box, Stack, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ForgotPasswordOtpVerificationApi from "../../api/user/ForgotPasswordOtpVerificationApi";
import ForgotPasswordResendOtpApi from "../../api/user/ForgotPasswordResendOtpApi";
import NotificationHelper from "../../components/helpers/NotificationHelper";
import { LoadingButton } from "@mui/lab";

function ForgotPasswordOtpVerification() {
  const [submit, setSubmit] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });
  const formRef = useRef(null);
  const location = useLocation();
  let navigate = useNavigate();

  async function handleSubmit(event) {
    setSubmit(true);

    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const apiResponse = await ForgotPasswordOtpVerificationApi(
      data.get("otp"),
      location.state
    );
    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse.success) {
      navigate(`/reset-password`, {
        state: apiResponse.data?.customerAccessToken,
      });

      formRef.current.reset();
    }

    setSubmit(false);
  }

  async function handleSubmitResendOtp(event) {
    const apiResponse = await ForgotPasswordResendOtpApi(location.state);

    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse.success) {
      setNotification({
        open: true,
        type: "success",
        message: apiResponse.message,
      });
    }
  }

  return (
    <>
      <div className="h-screen flex w-full  justify-center ">
        <Box
          sx={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "380px",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <MailOutlineIcon />
          </Avatar>
          <Typography textAlign="center" variant="h5">
            Verify your email address
          </Typography>
          <Typography sx={{ mt: 1 }}>
            A verification code has been sent to
          </Typography>
          <Typography variant="h6">{location.state}</Typography>
          <Typography sx={{ mt: 1 }}>
            Check your email for a verification code. Enter the verification
            code below to reset your password.
          </Typography>
          <Box component="form" ref={formRef} onSubmit={handleSubmit}>
            <TextField
              sx={{ width: "400px" }}
              margin="dense"
              required
              fullWidth
              id="otp"
              label="Verification code"
              name="otp"
              placeholder="Verification code"
              variant="outlined"
              size="small"
            />

            <LoadingButton
              loading={submit}
              loadingPosition="start"
              variant="contained"
              type="submit"
              fullWidth
              sx={{ mt: 1, mb: 2, textTransform: "initial" }}
            >
              Verify
            </LoadingButton>
          </Box>
          <Stack
            sx={{ mt: -1 }}
            direction="row"
            spacing={20}
            justifyContent="space-between"
          >
            <Button
              onClick={() => handleSubmitResendOtp()}
              sx={{ textTransform: "none" }}
              style={{ color: "blue", textDecoration: "none" }}
            >
              Resend code
            </Button>
            <Button
              onClick={() => navigate(`/forgot-password`)}
              sx={{ textTransform: "none" }}
              style={{ color: "blue", textDecoration: "none" }}
            >
              Change email
            </Button>
          </Stack>
        </Box>
      </div>
      <NotificationHelper notification={notification} />
    </>
  );
}

export default ForgotPasswordOtpVerification;
