import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function SubsChangeInfoModal(props) {
  return (
    <Modal
      open={props.open}
      onClose={() => props.onClose()}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Membership change info
          </Typography>
          <CloseIcon
            className="cursor-pointer"
            onClick={() => props.onClose()}
          />
        </Stack>
        <Typography variant="body2" mb={1} mt={1}>
          New listings: {props.selectedMembership?.changeListings}
        </Typography>
        <Typography variant="body2" mb={1}>
          New boosts: {props.selectedMembership?.changeBoost}
        </Typography>
        <Typography variant="body2" mb={1}>
          New subscription: {props.selectedMembership?.changePrice || 0}
        </Typography>
        <Typography variant="body2" mb={1}>
          Effective date:{" "}
          {dayjs(props.selectedMembership?.changeDate).format(
            "YYYY-MM-DD HH:mm:ss"
          )}
        </Typography>
      </Box>
    </Modal>
  );
}

export default SubsChangeInfoModal;
