import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function ConfirmationModal(props) {
  return (
    <Modal
      keepMounted
      open={props.open}
      onClose={() => props.onClose()}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            {props.title}
          </Typography>
          <CloseIcon onClick={() => props.onClose()} />
        </Stack>
        <Typography>{props.description}</Typography>

        <Stack sx={{ mt: 2 }} justifyContent="end" direction="row" spacing={1}>
          <Button
            variant="outlined"
            size="small"
            sx={{ textTransform: "none", width: 100 }}
            onClick={() => props.onClose()}
          >
            Cancel
          </Button>
          <LoadingButton
            loadingPosition="start"
            loading={props.loading}
            size="large"
            onClick={() => props.handleOk()}
            variant="contained"
            fullWidth
            sx={{
              textTransform: "none",
              width: 100,
            }}
          >
            Confirm
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
}

export default ConfirmationModal;
