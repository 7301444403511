import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import CloseIcon from "@mui/icons-material/Close";
import getCroppedImg from "../sub/CropImage";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  px: 4,
  py: 3,
};

export default function ImageCropper(props) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  function onCropChange(crop) {
    setCrop(crop);
  }

  function onZoomChange(zoom) {
    setZoom(zoom);
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        URL.createObjectURL(props.imageFile),
        croppedAreaPixels
      );

      props.setCroppedImageFile(croppedImage);
    } catch (e) {
      console.error(e);
    }

    props.handleClearImage();
    props.handleCancel();
  }, [croppedAreaPixels]);

  return (
    <Modal
      open={props.visible && props.imageFile}
      onClose={() => {
        props.handleClearImage();
        props.handleCancel();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack
          mb={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Crop image</Typography>

          <CloseIcon
            onClick={() => {
              props.handleClearImage();
              props.handleCancel();
            }}
          />
        </Stack>

        <div className="relative h-60 w-full mb-5">
          <div className="p-20 absolute top-0 left-0 w-full h-full">
            {props.imageFile && props.addGiftAspect ? (
              <Cropper
                image={URL.createObjectURL(props.imageFile)}
                crop={crop}
                zoom={zoom}
                aspect={270 / 120}
                onCropChange={onCropChange}
                onZoomChange={onZoomChange}
                onCropComplete={onCropComplete}
              />
            ) : (
              props.imageFile && (
                <Cropper
                  image={URL.createObjectURL(props.imageFile)}
                  crop={crop}
                  zoom={zoom}
                  aspect={1 / 1}
                  onCropChange={onCropChange}
                  onZoomChange={onZoomChange}
                  onCropComplete={onCropComplete}
                />
              )
            )}
          </div>
        </div>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          spacing={2}
        >
          <Button
            variant="outlined"
            sx={{ textTransform: "initial" }}
            onClick={() => {
              props.handleClearImage();
              props.handleCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "initial" }}
            onClick={showCroppedImage}
          >
            Crop
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
