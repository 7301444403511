import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
import { FormControl, FormHelperText, IconButton } from "@mui/material";
import AddGiftVoucherApi from "../../../api/gift-vouchers/AddGiftVoucherApi";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import AddIcon from "@mui/icons-material/Add";
import { UploadFileApi } from "../../../api/UploadFileApi";
import NotificationHelper from "../../helpers/NotificationHelper";
import ImageCropper from "../ImageCropper";
import { LoadingButton } from "@mui/lab";
import { randomId } from "../../helpers/RandomId";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function AddGiftCardModal(props) {
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [validityPeriod, setValidityPeriod] = useState();
  const [validityAbb, setValidityAbb] = useState("month");
  const [submit, setSubmit] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [imageCropper, setImageCropper] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [croppedImageFile, setCroppedImageFile] = useState("");
  const [nameError, setNameError] = useState("");
  const [valueError, setValueError] = useState("");
  const [validityError, setValidityError] = useState("");
  const [imageError, setImageError] = useState("");
  const [addGiftAspect, setAddGiftAspect] = useState(false);

  useEffect(() => {
    if (croppedImageFile) {
      async function blobToFile(blobUrl, fileName) {
        const response = await fetch(blobUrl);
        const blob = await response.blob();
        return new File([blob], fileName, { type: blob.type });
      }

      blobToFile(croppedImageFile, "filename.jpg").then((fileImage) => {
        upload(fileImage, randomId);
      });
    }
  }, [croppedImageFile]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    event.target.value = null;
    setImageFile(selectedFile);
    setImageError("");
  };

  async function upload(file, name) {
    setSubmit(true);

    const apiResponse = await UploadFileApi(file, name);

    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse) {
      setImageUrl(apiResponse.Location);
    }
    setSubmit(false);
  }

  function onCancel() {
    setImageUrl("");
    setCroppedImageFile("");
    setName("");
    setValue("");
    setValidityPeriod("");
    setValidityAbb("month");
    setNameError("");
    setValueError("");
    setValidityError("");
    setImageError("");
    props.onClose();
  }

  async function handleSubmit(event) {
    setSubmit(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!data.get("name")) {
      setNameError("Name can't be empty!");
      setSubmit(false);
    }
    if (!data.get("value")) {
      setValueError("Price can't be empty!");
      setSubmit(false);
    }
    if (!imageUrl) {
      setImageError(true);
      setSubmit(false);
    }
    if (!data.get("validityPeriod")) {
      setValidityError("Validity period can't be empty!");
      setSubmit(false);
    }

    if (
      data.get("name") &&
      data.get("value") &&
      imageUrl &&
      data.get("validityPeriod")
    ) {
      setSubmit(true);
      const apiResponse = await AddGiftVoucherApi(
        data.get("name"),
        data.get("value"),
        imageUrl,
        `${data.get("validityPeriod")} ${data.get("validityAbb")}`
      );
      if (apiResponse.statusCode) {
        setNotification({
          open: true,
          type: "error",
          message: apiResponse.message,
        });
      } else if (apiResponse.success) {
        setNotification({
          open: true,
          type: "success",
          message: apiResponse.message,
        });
      }
      props.handleOk();
      onCancel();
    }
    setSubmit(false);
  }

  return (
    <>
      <Modal
        keepMounted
        open={props.open}
        onClose={() => onCancel()}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Add Gift Card
            </Typography>
            <CloseIcon onClick={() => onCancel()} />
          </Stack>

          <TextField
            sx={{ mt: 2 }}
            fullWidth
            name="name"
            size="small"
            label="Gift Card name"
            variant="outlined"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setNameError("");
            }}
            error={nameError ? true : false}
            helperText={nameError ? nameError : " "}
          />
          <Stack sx={{ mt: 1 }} direction="row" spacing={2} alignItems="center">
            <TextField
              sx={{ width: "70%" }}
              size="small"
              label="Price"
              name="value"
              variant="outlined"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                setValueError("");
              }}
              error={valueError ? true : false}
              helperText={valueError ? valueError : " "}
              inputProps={{ inputMode: "numeric" }}
              onKeyDown={(event) => {
                const allowedKeys = ["Backspace", "Delete", "Enter"];
                if (
                  !/^[0-9]+$/.test(event.key) &&
                  !/^Arrow(Left|Right|Up|Down)$/.test(event.key) &&
                  !allowedKeys.includes(event.key)
                ) {
                  event.preventDefault();
                }
              }}
            />
            <TextField
              id="outlined-basic"
              sx={{ width: "100%" }}
              size="small"
              label="Validity period"
              name="validityPeriod"
              inputProps={{ inputMode: "numeric" }}
              onKeyDown={(event) => {
                const allowedKeys = ["Backspace", "Delete", "Enter"];
                if (
                  !/^[0-9]+$/.test(event.key) &&
                  !/^Arrow(Left|Right|Up|Down)$/.test(event.key) &&
                  !allowedKeys.includes(event.key)
                ) {
                  event.preventDefault();
                }
              }}
              value={validityPeriod}
              onChange={(event) => {
                setValidityPeriod(event.target.value);
                setValidityError("");
              }}
              error={validityError ? true : false}
              helperText={validityError ? validityError : " "}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <FormControl>
                      <Select
                        id="outlined-basic"
                        size="small"
                        sx={{
                          marginRight: -2.8,
                          width: "100px",
                        }}
                        name="validityAbb"
                        value={validityAbb}
                        onChange={(event) => {
                          setValidityAbb(event.target.value);
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="year">Year</MenuItem>
                        <MenuItem value="month">Month</MenuItem>
                        <MenuItem value="day">Day</MenuItem>
                      </Select>
                    </FormControl>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Gift Card Image:
          </Typography>

          <div
            className={
              "mt-1 h-36 full flex justify-center items-center mb-2 relative " +
              (!croppedImageFile && "bg-gray-200")
            }
          >
            {(imageUrl || croppedImageFile) && (
              <img
                src={croppedImageFile ? croppedImageFile : imageUrl}
                alt="sample"
                className="absolute top-0 left-0 w-full h-full"
              />
            )}
            <Stack direction="row" spacing={1}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={() => {
                  setImageCropper(true);
                  setAddGiftAspect(true);
                }}
              >
                <input
                  accept="image/*"
                  hidden
                  type="file"
                  onChange={handleFileChange}
                />
                {croppedImageFile || imageUrl ? <SwapHorizIcon /> : <AddIcon />}
              </IconButton>
            </Stack>
          </div>
          {imageError ? (
            <FormHelperText sx={{ ml: 1, color: "#d32f2f" }}>
              Gift image can't be empty!
            </FormHelperText>
          ) : (
            <FormHelperText sx={{ ml: 1, color: "#d32f2f" }}> </FormHelperText>
          )}

          <Stack
            sx={{ mt: 2 }}
            justifyContent="end"
            direction="row"
            spacing={1}
          >
            <Button
              sx={{ textTransform: "none" }}
              variant="outlined"
              size="small"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
            <LoadingButton
              size="large"
              loading={submit}
              loadingPosition="start"
              variant="contained"
              type="submit"
              fullWidth
              sx={{
                mt: 2,
                textTransform: "none",
                width: 80,
              }}
            >
              <Typography variant="body1">Add</Typography>
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
      <NotificationHelper notification={notification} />
      <ImageCropper
        visible={imageCropper}
        handleCancel={() => setImageCropper(false)}
        imageFile={imageFile}
        handleClearImage={() => setImageFile(null)}
        setCroppedImageFile={(file) => setCroppedImageFile(file)}
        addGiftAspect={addGiftAspect}
      />
    </>
  );
}

export default AddGiftCardModal;
