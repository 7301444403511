import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";
import InviteAdminApi from "../../api/admin/InviteAdminApi";
import NotificationHelper from "../helpers/NotificationHelper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function InviteAdminModal(props) {
  const [submit, setSubmit] = useState(false);
  const [email, setEmail] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [emailError, setEmailError] = useState("");

  function onCancel() {
    setEmail("");
    props.onClose();
  }

  function isValidEmail(email) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  }

  async function handleSubmit(event) {
    setSubmit(true);

    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (!data.get("email")) {
      setEmailError("Email cannot be empty!");
      setSubmit(false);
    } else if (!isValidEmail(data.get("email"))) {
      setSubmit(false);
      setEmailError("Email is not valid!");
    }
    if (data.get("email") && isValidEmail(data.get("email"))) {
      const apiResponse = await InviteAdminApi(data.get("email"));
      if (apiResponse.statusCode) {
        setNotification({
          open: true,
          type: "error",
          message: apiResponse.message,
        });
      } else if (apiResponse.success) {
        setNotification({
          open: true,
          type: "success",
          message: apiResponse.message,
        });
      }
      props.handleOk();
      onCancel();
    }
    setSubmit(false);
  }
  return (
    <>
      <Modal
        keepMounted
        open={props.open}
        onClose={() => onCancel()}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Invite Admin
            </Typography>
            <CloseIcon onClick={() => props.onClose()} />
          </Stack>

          <TextField
            fullWidth
            sx={{ mt: 2 }}
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
              setEmailError("");
            }}
            size="small"
            name="email"
            label="Email"
            variant="outlined"
            error={emailError ? true : false}
            helperText={emailError ? emailError : " "}
          />

          <Stack justifyContent="end" direction="row" spacing={1}>
            <Button
              sx={{ textTransform: "none", width: 100 }}
              variant="outlined"
              size="small"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
            <LoadingButton
              size="large"
              loading={submit}
              loadingPosition="start"
              variant="contained"
              type="submit"
              fullWidth
              sx={{
                mt: 2,
                textTransform: "initial",
                width: 100,
              }}
            >
              <Typography variant="body1">Invite</Typography>
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
      <NotificationHelper notification={notification} />
    </>
  );
}

export default InviteAdminModal;
