import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";

function AccountsTable({ payables }) {
  let navigate = useNavigate();

  return (
    <>
      <Typography component="h2" variant="h6" gutterBottom>
        Accounts
      </Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Shop Name</TableCell>

              <TableCell sx={{ fontWeight: "bold" }} align="right">
                Product count
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }} align="right">
                Amount
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }} align="right">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payables?.map((row, index) => (
              <TableRow
                hover
                key={index}
                onClick={() => {
                  navigate(`/accounts/${row?.shopName}?shop=${row.shopId}`);
                }}
              >
                <TableCell>{row.shopName}</TableCell>

                <TableCell align="right">{row.productsCount}</TableCell>

                <TableCell align="right">{row.total}</TableCell>
                <TableCell align="right">{row.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
export default AccountsTable;
