import React from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import Typography from "@mui/material/Typography";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import Paper from "@mui/material/Paper";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

const action = (
  <div className="flex justify-items-center gap-1">
    <CheckCircleOutlinedIcon
      style={{ fontSize: "20px" }}
      className="text-green-500"
    />
    <BlockOutlinedIcon
      style={{ fontSize: "20px" }}
      className="text-orange-500"
    />
  </div>
);

function createData(id, name, description, status, action) {
  return { id, name, description, status, action };
}

const rows = [
  createData(
    0,
    "Vintage",
    "Vintage items are atleast 20 years old and have historical or collectible value",
    "Pending",
    action
  ),
];

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function CategoryRequests() {
  const right = (
    <div>
      <BootstrapTooltip title="Filter" placement="top">
        <FilterAltOutlinedIcon sx={{ fontSize: "30px" }} color="primary" />
      </BootstrapTooltip>
    </div>
  );
  const left = <div>Category / Requests</div>;
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          mt: 10,
          ml: 2,
          mr: 2,
          mb: 4,
        }}
      >
        <DashboardBreadcrumb left={left} right={right} />

        <Typography component="h2" variant="h6" gutterBottom>
          Requests
        </Typography>
        <TableContainer component={Paper} variant="outlined">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Category Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Category Description
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>status</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.action}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default CategoryRequests;
