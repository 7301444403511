export async function UploadFileApi(file, name) {
  let res = "";
  if (!file || !name) {
    return [];
  }
  const formdata = new FormData();
  formdata.append("file", file, `${name}.png`);
  const token = "Bearer " + localStorage.getItem("authToken");
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: token,
    },
    body: formdata,
    redirect: "follow",
  };

  await fetch(`${process.env.REACT_APP_API_URL}file-upload`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      res = result;
    })
    .catch((error) => {
      res = error;
    });

  return res;
}
