import React from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import SubscriptionPlanTable from "../../components/tables/SubscriptionPlanTable";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function SubscriptionsPlans() {
  let navigate = useNavigate();
  const right = (
    <div className="flex items-center gap-2">
      <div>
        <BootstrapTooltip title="Filter" placement="top">
          <FilterAltOutlinedIcon style={{ fontSize: "30px" }} color="primary" />
        </BootstrapTooltip>
      </div>
      <div
        onClick={() => navigate(`/subscriptions-plans/create-subscriptions`)}
      >
        <BootstrapTooltip title="Add" placement="top">
          <AddBoxOutlinedIcon style={{ fontSize: "30px" }} color="primary" />
        </BootstrapTooltip>
      </div>
    </div>
  );

  const left = <div>Subscriptions-plans</div>;
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        mt: 10,
        ml: 2,
        mr: 2,
        mb: 4,
      }}
    >
      <DashboardBreadcrumb left={left} right={right} />
      <SubscriptionPlanTable />
    </Box>
  );
}

export default SubscriptionsPlans;
