import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import { GetPointsApi } from "../../api/points/GetPointsApi";
import UpdatePointsApi from "../../api/points/UpdatePointsApi";
import NotificationHelper from "../../components/helpers/NotificationHelper";
import UpdateTreesApi from "../../api/points/UpdateTreesApi";
import { GetTreesApi } from "../../api/points/GetTreesApi";

function Points() {
  const [points, setPoints] = useState();
  const [trees, setTrees] = useState();
  const [submitPoints, setSubmitPoints] = useState(false);
  const [submitTrees, setSubmitTrees] = useState(false);
  const [pointsPerDollar, setPointsPerDollar] = useState();
  const [dollarPerPoints, setDollarPerPoints] = useState();
  const [treesPerAmount, setTreesPerAmount] = useState();
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });

  const [pointsPerDollarError, setPointsPerDollarError] = useState("");
  const [dollarPerPointsError, setDollarPerPointsError] = useState("");
  const [treesPerAmountError, setTreesPerAmountError] = useState("");

  useEffect(() => {
    getPoints();
    getTrees();
  }, []);

  async function getPoints() {
    const apiResponse = await GetPointsApi();
    if (apiResponse.statusCode) {
    } else if (apiResponse.success) {
      setPoints(apiResponse.data);
      setPointsPerDollar(apiResponse.data.pointsPerDollar);
      setDollarPerPoints(apiResponse.data.dollarPerPoints);
    }
  }

  async function getTrees() {
    const apiResponse = await GetTreesApi();
    if (apiResponse.statusCode) {
    } else if (apiResponse.success) {
      setTrees(apiResponse.data);
      setTreesPerAmount(apiResponse.data.treesPerAmount);
    }
  }

  async function handleSubmit1(event) {
    setSubmitPoints(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!data.get("pointsPerDollar")) {
      setPointsPerDollarError("Points can't be empty!");
      setSubmitPoints(false);
    }
    if (!data.get("dollarPerPoints")) {
      setDollarPerPointsError("Dollar can't be empty!");
      setSubmitPoints(false);
    }
    if (data.get("pointsPerDollar") && data.get("dollarPerPoints")) {
      setSubmitPoints(true);
      const apiResponse = await UpdatePointsApi(
        points.id,
        data.get("pointsPerDollar"),
        data.get("dollarPerPoints")
      );
      if (apiResponse.statusCode) {
        setNotification({
          open: true,
          type: "error",
          message: apiResponse.message,
        });
      } else if (apiResponse.success) {
        setNotification({
          open: true,
          type: "success",
          message: apiResponse.message,
        });
      }
    }
    setSubmitPoints(false);
  }

  async function handleSubmit2(event) {
    setSubmitTrees(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!data.get("treesPerAmount")) {
      setTreesPerAmountError("Trees can't be empty!");
      setSubmitTrees(false);
    }
    if (data.get("treesPerAmount")) {
      setSubmitTrees(true);
      const apiResponse = await UpdateTreesApi(
        trees.id,
        data.get("treesPerAmount")
      );
      if (apiResponse.statusCode) {
        setNotification({
          open: true,
          type: "error",
          message: apiResponse.message,
        });
      } else if (apiResponse.success) {
        setNotification({
          open: true,
          type: "success",
          message: apiResponse.message,
        });
      }
    }
    setSubmitTrees(false);
  }

  function handleCancelPoints() {
    setPointsPerDollarError("");
    setDollarPerPointsError("");
    getPoints();
  }

  function handleCancelTrees() {
    setTreesPerAmountError("");
    getTrees();
  }

  const left = <div>Points</div>;
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          mt: 10,
          ml: 2,
          mr: 2,
          mb: 4,
        }}
      >
        <DashboardBreadcrumb left={left} />
        <Typography variant="h6" component="h2">
          Points
        </Typography>

        <Box sx={{ mt: 2 }} component="form" onSubmit={handleSubmit1}>
          <Typography sx={{ mt: 1 }} variant="body2">
            Points per dollar spent:
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={2}>
              <TextField
                size="small"
                sx={{ mt: 1 }}
                value={1}
                id="outlined-start-adornment"
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={0.4} sx={{ mt: 2 }}>
              =
            </Grid>
            <Grid item xs={2}>
              <TextField
                sx={{ mt: 1 }}
                size="small"
                name="pointsPerDollar"
                value={pointsPerDollar}
                onChange={(event) => {
                  setPointsPerDollar(event.target.value);
                  setPointsPerDollarError("");
                }}
                id="outlined-start-adornment"
                onKeyDown={(event) => {
                  const allowedKeys = ["Backspace", "Delete", "Enter"];
                  if (
                    !/^[0-9]+$/.test(event.key) &&
                    !/^Arrow(Left|Right|Up|Down)$/.test(event.key) &&
                    !allowedKeys.includes(event.key)
                  ) {
                    event.preventDefault();
                  }
                }}
                error={pointsPerDollarError ? true : false}
                helperText={pointsPerDollarError ? pointsPerDollarError : " "}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Points</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Typography variant="body2">Dollar per point spent:</Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={2}>
              <TextField
                size="small"
                sx={{ mt: 1 }}
                value={100}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">Points</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={0.4} sx={{ mt: 2 }}>
              =
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                sx={{ mt: 1 }}
                name="dollarPerPoints"
                value={dollarPerPoints}
                onChange={(event) => {
                  setDollarPerPoints(event.target.value);
                  setDollarPerPointsError("");
                }}
                onKeyDown={(event) => {
                  const allowedKeys = ["Backspace", "Delete", "Enter"];
                  if (
                    !/^[0-9]+$/.test(event.key) &&
                    !/^Arrow(Left|Right|Up|Down)$/.test(event.key) &&
                    !allowedKeys.includes(event.key)
                  ) {
                    event.preventDefault();
                  }
                }}
                error={dollarPerPointsError ? true : false}
                helperText={dollarPerPointsError ? dollarPerPointsError : " "}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Stack direction="row" sx={{ mt: 2 }} spacing={2}>
            <LoadingButton
              loading={submitPoints}
              loadingPosition="start"
              variant="contained"
              type="submit"
              size="small"
              fullWidth
              sx={{ width: "120px", textTransform: "none" }}
            >
              Save
            </LoadingButton>
            <Button
              sx={{ width: "120px", textTransform: "none" }}
              onClick={() => handleCancelPoints()}
              variant="outlined"
              size="small"
            >
              Cancel
            </Button>
          </Stack>
        </Box>

        <Box sx={{ mt: 3 }} component="form" onSubmit={handleSubmit2}>
          <Typography sx={{ mt: 2 }} variant="body2">
            Trees planted per amount spent:
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={2}>
              <TextField
                size="small"
                sx={{ mt: 1 }}
                value={1}
                id="outlined-start-adornment"
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">Tree</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={0.4} sx={{ mt: 2 }}>
              =
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                sx={{ mt: 1 }}
                name="treesPerAmount"
                value={treesPerAmount}
                onChange={(event) => {
                  setTreesPerAmount(event.target.value);
                  setTreesPerAmountError("");
                }}
                onKeyDown={(event) => {
                  const allowedKeys = ["Backspace", "Delete", "Enter"];
                  if (
                    !/^[0-9]+$/.test(event.key) &&
                    !/^Arrow(Left|Right|Up|Down)$/.test(event.key) &&
                    !allowedKeys.includes(event.key)
                  ) {
                    event.preventDefault();
                  }
                }}
                error={treesPerAmountError ? true : false}
                helperText={treesPerAmountError ? treesPerAmountError : " "}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Stack direction="row" sx={{ mt: 2 }} spacing={2}>
            <LoadingButton
              loading={submitTrees}
              loadingPosition="start"
              variant="contained"
              type="submit"
              size="small"
              fullWidth
              sx={{ width: "120px", textTransform: "none" }}
            >
              Save
            </LoadingButton>
            <Button
              sx={{ width: "120px", textTransform: "none" }}
              onClick={() => handleCancelTrees()}
              variant="outlined"
              size="small"
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
      <NotificationHelper notification={notification} />
    </>
  );
}

export default Points;
