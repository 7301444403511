import React from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import Typography from "@mui/material/Typography";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";

const action = (
  <div className="flex justify-items-center gap-1">
    <CheckCircleOutlinedIcon
      style={{ fontSize: "20px" }}
      className="text-green-500"
    />
    <BlockOutlinedIcon
      style={{ fontSize: "20px" }}
      className="text-orange-500"
    />
  </div>
);

function createData(
  id,
  categoryId,
  shopName,
  sellerName,
  count,
  status,
  action
) {
  return { id, categoryId, shopName, sellerName, count, status, action };
}

const rows = [
  createData(
    0,
    "RQ-001",
    "ABC Electronics",
    "Smith",
    "smith@gmail.com",
    "Pending",
    action
  ),
  createData(
    1,
    "RQ-002",
    "Clothing",
    "John",
    "john@gmail.com",
    "Active",
    action
  ),
  createData(
    2,
    "RQ-003",
    "Home & Kitchen",
    "Kennady",
    "kennady@gmail.com",
    "Active",
    action
  ),
];

function Sellers() {
  let navigate = useNavigate();

  const left = <div>Sellers / Registered Shops</div>;
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          mt: 10,
          ml: 2,
          mr: 2,
          mb: 4,
        }}
      >
        <DashboardBreadcrumb left={left} />

        <Typography component="h2" variant="h6" gutterBottom>
          Registered Shops
        </Typography>
        <TableContainer component={Paper} variant="outlined">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Request ID</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Shop Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Seller Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Contact Info</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>status</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  onClick={() => {
                    navigate(`/sellers/${row?.categoryId}`);
                  }}
                  key={row.id}
                >
                  <TableCell>{row.categoryId}</TableCell>
                  <TableCell>{row.shopName}</TableCell>
                  <TableCell>{row.sellerName}</TableCell>
                  <TableCell>{row.count}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.action}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default Sellers;
