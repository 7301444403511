import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import Avatar from "@mui/material/Avatar";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Box, TextField } from "@mui/material";
import ForgotPasswordApi from "../../api/user/ForgotPasswordApi";
import { useNavigate } from "react-router-dom";
import NotificationHelper from "../../components/helpers/NotificationHelper";
import { LoadingButton } from "@mui/lab";

function ForgotPassword() {
  const [submit, setSubmit] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [form, setForm] = useState({
    email: "",
  });
  let navigate = useNavigate();

  async function handleSubmit(event) {
    setSubmit(true);

    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get("email");

    if (!email) {
      setForm((prevForm) => ({
        ...prevForm,
        email: {
          value: prevForm.email.value,
          error: "Email cannot be empty!",
        },
      }));
    } else {
      const apiResponse = await ForgotPasswordApi(data.get("email"));
      if (apiResponse.error) {
        setNotification({
          open: true,
          type: "error",
          message: apiResponse.message,
        });
      } else if (
        (apiResponse.message = "OTP Send to mail Suceesfully please check it")
      ) {
        setNotification({
          open: true,
          type: "success",
          message: apiResponse.message,
        });

        navigate(`/forgotpassword-otpverification`, {
          state: apiResponse.data?.email,
        });
      }
    }
    setSubmit(false);
  }

  return (
    <>
      <div className="h-screen flex w-full  justify-center ">
        <Box
          sx={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "400px",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <MailOutlineIcon />
          </Avatar>
          <Typography textAlign="center" variant="h5">
            Forgot Password
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Enter your email and we will send a link to your email to reset your
            password.
          </Typography>
          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              sx={{ width: "400px", mt: 2 }}
              margin="dense"
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={form.email.value}
              onChange={(e) =>
                setForm((prevForm) => ({
                  ...prevForm,
                  email: {
                    value: e.target.value,
                    error: "",
                  },
                }))
              }
              error={Boolean(form.email.error)}
              helperText={form.email.error ? form.email.error : " "}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <EmailIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
            />

            <LoadingButton
              loading={submit}
              loadingPosition="start"
              variant="contained"
              type="submit"
              fullWidth
              sx={{ mb: 2, textTransform: "initial" }}
            >
              Submit
            </LoadingButton>

            <Typography sx={{ mt: 1 }} textAlign="center">
              Remember password?{" "}
              <Link
                href="/login"
                style={{ color: "blue", textDecoration: "none" }}
              >
                Login
              </Link>
            </Typography>
          </Box>
        </Box>
      </div>
    </>
  );
}

export default ForgotPassword;
