import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";

function AccountsHistoryTable({ historyData }) {
  let navigate = useNavigate();

  return (
    <>
      <Typography component="h2" variant="h6" gutterBottom>
        Accounts History
      </Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Shop name</TableCell>

              <TableCell sx={{ fontWeight: "bold" }} align="right">
                Product count
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }} align="right">
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historyData?.map((row, index) => (
              <TableRow
                hover
                key={index}
                onClick={() => {
                  navigate(
                    `/accounts-history/${row?.shopName}?shop=${row.transactionHistoryId}`
                  );
                }}
              >
                <TableCell>
                  {dayjs(row.transferedDate).format("YYYY-MM-DD")}
                </TableCell>

                <TableCell>{row.shopName}</TableCell>

                <TableCell align="right">{row.productCount}</TableCell>
                <TableCell align="right">{row.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
export default AccountsHistoryTable;
