import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import AddFeaturedCategoryOrProductModal from "../../components/modals/add/AddFeaturedCategoryOrProductModal";
import { GetSliderApi } from "../../api/slider/GetSliderApi";
import { UploadFileApi } from "../../api/UploadFileApi";
import CreateSliderApi from "../../api/slider/CreateSliderApi";
import DeleteSliderApi from "../../api/slider/DeleteSliderApi";
import { GetFeatureApi } from "../../api/feature/GetFeatureApi";
import { IconButton, Tooltip } from "@mui/material";
import UpdateFeatureImageApi from "../../api/feature/UpdateFeatureImageApi";
import NotificationHelper from "../../components/helpers/NotificationHelper";
import { Link } from "react-router-dom";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { randomId } from "../../components/helpers/RandomId";

function WebsiteLayout() {
  const [openCategory, setOpenCategory] = useState(false);
  const [openDeleteSlider, setOpenDeleteSlider] = useState(false);
  const [sliderImage, setSliderImage] = useState([]);
  const [featureImage, setFeatureImage] = useState([]);
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [deleteSliderId, setDeleteSliderId] = useState();
  const [featureId, setFeatureId] = useState();
  const [loading, setLoading] = useState(false);
  const left = <div>Website Layout</div>;

  useEffect(() => {
    getSlider();
    getFeature();
  }, []);

  function handleFileChange(event) {
    const file = event.target.files[0];
    upload(file, randomId);
  }

  function handleFileChangeFeature(event) {
    const file = event.target.files[0];
    uploadFeature(file, randomId);
  }

  async function getSlider() {
    const apiResponse = await GetSliderApi();
    if (apiResponse.success) {
      setSliderImage(apiResponse.data);
    }
  }

  async function getFeature() {
    const apiResponse = await GetFeatureApi();
    if (apiResponse.success) {
      setFeatureImage(apiResponse.data);
    }
  }

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function upload(file, name) {
    const checkLocation = async () => {
      let apiResponse = await UploadFileApi(file, name);

      if (apiResponse.Location) {
        return apiResponse;
      } else {
        await delay(1000);
        return checkLocation();
      }
    };

    const apiResponse = await checkLocation();

    if (apiResponse.statusCode) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else {
      const apiResponse1 = await CreateSliderApi(apiResponse.Location);

      if (apiResponse1.statusCode) {
        setNotification({
          open: true,
          type: "error",
          message: apiResponse1.message,
        });
      } else if (apiResponse1.success) {
        setNotification({
          open: true,
          type: "success",
          message: apiResponse1.message,
        });

        getSlider();
      }
    }
  }

  async function uploadFeature(file, name) {
    const checkLocation = async () => {
      let apiResponse = await UploadFileApi(file, name);

      if (apiResponse.Location) {
        return apiResponse;
      } else {
        await delay(1000);
        return checkLocation();
      }
    };

    const apiResponse = await checkLocation();

    if (apiResponse.statusCode) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else {
      const apiResponse1 = await UpdateFeatureImageApi(
        featureId,
        apiResponse.Location
      );

      if (apiResponse1.statusCode) {
        setNotification({
          open: true,
          type: "error",
          message: apiResponse1.message,
        });
      } else if (apiResponse1.success) {
        setNotification({
          open: true,
          type: "success",
          message: apiResponse1.message,
        });

        getFeature();
      }
    }
  }

  async function deleteSlider() {
    setLoading(true);
    const apiResponse = await DeleteSliderApi(deleteSliderId);

    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse.success) {
      setNotification({
        open: true,
        type: "success",
        message: apiResponse.message,
      });
      getSlider();
    }
    setLoading(false);
    setDeleteSliderId("");
    setOpenDeleteSlider(false);
  }

  function renderSliderImages() {
    let output = [];
    sliderImage?.forEach((image) => {
      output.push(
        <>
          <Card style={{ position: "relative" }} variant="outlined">
            <IconButton
              onClick={() => {
                setOpenDeleteSlider(true);
                setDeleteSliderId(image._id);
              }}
              size="large"
              variant="contained"
              sx={{
                color: "red",
                top: 3,
                right: 3,
                position: "absolute",
                zIndex: "2",
              }}
            >
              <DeleteOutlinedIcon />
            </IconButton>
            <img style={{ width: "244px" }} src={image.imgUrl} />
          </Card>
        </>
      );
    });
    return output;
  }

  function renderFeatureImages() {
    let output = [];

    featureImage?.forEach((image) => {
      output.push(
        <div>
          <Stack container direction="row" gap={2} alignItems="center">
            <Button
              sx={{ minWidth: "100px", textTransform: "none" }}
              variant="outlined"
              size="small"
              onClick={() => {
                setOpenCategory(true);
                setFeatureId(image._id);
              }}
            >
              Upload link
            </Button>

            <Link
              to={image.link}
              className="truncate w-[170px]"
              style={{ color: "blue", textDecoration: "none" }}
            >
              {image.link}
            </Link>
          </Stack>
          <Card sx={{ position: "relative", mt: 2 }} variant="outlined">
            <IconButton
              component="label"
              size="large"
              variant="contained"
              sx={{
                color: "blue",
                top: 3,
                right: 3,
                position: "absolute",
                zIndex: "2",
              }}
              onClick={() => setFeatureId(image._id)}
            >
              <SwapHorizIcon />
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                onChange={handleFileChangeFeature}
              />
            </IconButton>
            <img style={{ width: "300px" }} src={image.imgUrl} alt="" />
          </Card>
        </div>
      );
    });
    return output;
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          mt: 10,
          ml: 2,
          mr: 2,
        }}
      >
        <DashboardBreadcrumb left={left} />
        <Typography variant="h6">
          Slider Content{" "}
          <Tooltip
            placement="top"
            title="Image size must be width:828px, height:488px"
          >
            <InfoOutlinedIcon style={{ fontSize: 20, cursor: "pointer" }} />
          </Tooltip>
        </Typography>
        <Stack direction="row" sx={{ mt: 1, gap: "16px" }} flexWrap="wrap">
          <Stack direction="row" item xs={3}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              fullwidth
              sx={{
                width: "244px",
                backgroundColor: "#eeeeee",
                border: "white",
                borderRadius: "4px",
              }}
            >
              <Button component="label" sx={{ textTransform: "none" }}>
                + Add
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={handleFileChange}
                />
              </Button>
            </Stack>
          </Stack>

          {renderSliderImages()}
        </Stack>
        <Typography variant="h6" sx={{ mt: 5 }}>
          Featured Category/ Product{" "}
          <Tooltip
            placement="top"
            title="Image size must be width:384px, height:216px"
          >
            <InfoOutlinedIcon style={{ fontSize: 20, cursor: "pointer" }} />
          </Tooltip>
        </Typography>
        <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
          {renderFeatureImages()}
        </Stack>
      </Box>
      <NotificationHelper notification={notification} />
      <AddFeaturedCategoryOrProductModal
        keepMounted
        featureId={featureId}
        openCategory={openCategory}
        handleOk={() => {
          getFeature();
          setOpenCategory(false);
        }}
        onClose={() => setOpenCategory(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      />

      <ConfirmationModal
        keepMounted
        title="Delete Slider"
        description="Are you sure want to delete this slide?"
        loading={loading}
        open={openDeleteSlider}
        handleOk={() => {
          deleteSlider();
        }}
        onClose={() => {
          setOpenDeleteSlider(false);
          setDeleteSliderId("");
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      />
    </>
  );
}

export default WebsiteLayout;
