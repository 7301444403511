import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

function AccountsDetailsTable({ payable }) {
  return (
    <>
      <Typography component="h2" variant="h6" gutterBottom>
        Shop Name
      </Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Product name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} align="right">
                Total received
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }} align="right">
                Delivery type
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }} align="right">
                Delivery fee
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }} align="right">
                Amount payable
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payable?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.productName}</TableCell>
                <TableCell>{row.totalReceived}</TableCell>
                <TableCell>{row.deliveryType}</TableCell>
                <TableCell>{row.deliveryFee}</TableCell>
                <TableCell>{row.amountTransfered}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
export default AccountsDetailsTable;
