import React from "react";
import ReactDOM from "react-dom/client";
import Main from "./routes/Main";
import { BrowserRouter } from "react-router-dom";
import "./assets/css/index.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { Store } from "./app/Store";

const store = Store;

const theme = createTheme({
  palette: {
    primary: {
      main: "#9f4600",
    },
    secondary: {
      main: "#ffab91",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <Main theme={theme} />
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
);
