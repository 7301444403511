import React, { useState } from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import identy from "../../assets/images/identy.jpg";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function SellersDetails() {
  const [value, setValue] = useState(0);
  const curLocation = useLocation();
  const path = curLocation.pathname;
  const categoryId = path.split("/");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  const right = (
    <div className="flex items-center gap-2">
      <div>
        <BootstrapTooltip title="Accept" placement="top">
          <CheckCircleOutlinedIcon
            sx={{ fontSize: "30px", color: "#4caf50" }}
            color="primary"
          />
        </BootstrapTooltip>
      </div>
      <div>
        <BootstrapTooltip title="Reject" placement="top">
          <BlockOutlinedIcon style={{ fontSize: "30px", color: "#ff9800" }} />
        </BootstrapTooltip>
      </div>
    </div>
  );

  const left = <div>Sellers / {decodeURIComponent(categoryId[2])}</div>;

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        mt: 10,
        ml: 2,
        mr: 2,
        mb: 4,
      }}
    >
      <DashboardBreadcrumb left={left} right={right} />

      <Typography component="h2" variant="h6">
        {decodeURIComponent(categoryId[2])}
      </Typography>

      <AppBar sx={{ mt: 2 }} position="static" color="transparent">
        <Tabs value={value} onChange={handleChange}>
          <Tab
            sx={{
              textTransform: "none",
              "&:hover": {
                color: "#3f51b5",
                opacity: 1,
              },
            }}
            label="Shop Details"
          />
          <Tab
            sx={{
              textTransform: "none",
              "&:hover": {
                color: "#3f51b5",
                opacity: 1,
              },
            }}
            label="Seller Details"
          />
          <Tab
            sx={{
              textTransform: "none",
              "&:hover": {
                color: "#3f51b5",
                opacity: 1,
              },
            }}
            label="Proof of identification"
          />
          <Tab
            sx={{
              textTransform: "none",
              "&:hover": {
                color: "#3f51b5",
                opacity: 1,
              },
            }}
            label="Bank Details"
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Typography variant="h6">Shop Details</Typography>

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ mt: 1 }}
        >
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>
              Business registration number:
            </Typography>
            <Typography>Mike's Electronic</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>
              Registered business name:
            </Typography>
            <Typography>Mike's Electronic</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>Trade name:</Typography>
            <Typography>Mike's Electronic</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>Country</Typography>
            <Typography>UK</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>Phone</Typography>
            <Typography>123439283</Typography>
          </Grid>
        </Grid>

        <Typography sx={{ mt: 3 }} variant="h6">
          Business Address
        </Typography>

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ mt: 1 }}
        >
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>Address Line 1:</Typography>
            <Typography>123 main St</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>Address Line 2:</Typography>
            <Typography>Suite 100</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>City:</Typography>
            <Typography>LA</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>
              State/Province/Regio:
            </Typography>
            <Typography>CA</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>ZIP/Postal code:</Typography>
            <Typography>123439283</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>Country</Typography>
            <Typography>United States</Typography>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Typography variant="h6">Owner's Details</Typography>

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ mt: 1 }}
        >
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>First name:</Typography>
            <Typography>John</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>Middle name:</Typography>
            <Typography>Smith</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>Last name:</Typography>
            <Typography>DOE</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>Phone</Typography>
            <Typography>123439283</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>DOB</Typography>
            <Typography>01.01.1990</Typography>
          </Grid>
        </Grid>

        <Typography sx={{ mt: 3 }} variant="h6">
          Residential Address
        </Typography>

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ mt: 1 }}
        >
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>Address Line 1:</Typography>
            <Typography>123 main St</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>Address Line 2:</Typography>
            <Typography>Suite 100</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>City:</Typography>
            <Typography>LA</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>
              State/Province/Regio:
            </Typography>
            <Typography>CA</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>ZIP/Postal code:</Typography>
            <Typography>123439283</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>Country</Typography>
            <Typography>United States</Typography>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Typography variant="h6">Proof of identification</Typography>
        <Typography sx={{ mt: 2, color: "gray" }}>Type of ID:</Typography>
        <Typography>Driver license</Typography>
        <Typography sx={{ mt: 2, color: "gray" }}>Front of ID:</Typography>
        <img className="w-44" src={identy} />
        <Typography sx={{ mt: 2, color: "gray" }}>Back of ID:</Typography>
        <img className="w-44" src={identy} />{" "}
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Typography variant="h6">Bank Details</Typography>
      </TabPanel>
    </Box>
  );
}

export default SellersDetails;
