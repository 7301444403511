import React, { useEffect, useState } from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import UsersTable from "../../components/tables/UsersTable";
import GetUsersTableApi from "../../api/users/GetUsersTableApi";
import { Badge, Button, IconButton, Pagination } from "@mui/material";
import UsersFilterModal from "../../components/modals/filters/UsersFilterModal";
import GetFilteredUsersTableApi from "../../api/users/GetFilteredUsersTableApi";
import RefreshIcon from "@mui/icons-material/Refresh";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function Users() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [data, setData] = useState();
  const [filterModal, setFilterModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filterBy, setFilterBy] = useState();

  useEffect(() => {
    {
      filterStatus ? getFilteredUsers(page) : getUsers(page);
    }
  }, [page]);

  const handleChange = (event, num) => {
    changeTablePage(num);
  };

  async function getUsers(num) {
    const apiResponse = await GetUsersTableApi(num);

    if (apiResponse.success) {
      setUsers(apiResponse.data?.users);
      setData(apiResponse.data);
      setPage(num);
    }
  }

  async function getFilteredUsers(event, num) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const apiResponse = await GetFilteredUsersTableApi(
      data.get("location") || "",
      data.get("sellerStatus") || "",
      num
    );

    if (apiResponse.success) {
      setUsers(apiResponse.data?.users);
      setFilterStatus(true);
      setFilterBy({
        location: data.get("location"),
        sellerStatus: data.get("sellerStatus"),
      });
      setData(apiResponse.data);
      setPage(num);
    }
  }

  function resetFilter() {
    getUsers(1);
    setFilterStatus(false);
    setFilterBy();
  }

  function changeTablePage(num) {
    if (filterStatus) {
      getFilteredUsers(filterBy, num);
    } else {
      getUsers(num);
    }
  }

  const right = (
    <div className="flex items-center gap-2">
      <div onClick={() => resetFilter()}>
        <BootstrapTooltip title="Refresh" placement="top">
          <Button
            sx={{
              width: "50px",
              minWidth: "unset",
            }}
            variant="contained"
            disabled={!filterStatus}
          >
            <RefreshIcon
              sx={{
                fontSize: "25px",
                color: "primary",
              }}
            />
          </Button>
        </BootstrapTooltip>
      </div>
      <div onClick={() => setFilterModal(true)}>
        <BootstrapTooltip title="Filter" placement="top">
          <Button
            sx={{
              width: "50px",
              minWidth: "unset",
            }}
            variant="contained"
          >
            {filterStatus ? (
              <Badge
                color="secondary"
                overlap="circular"
                badgeContent=" "
                variant="dot"
              >
                <FilterAltOutlinedIcon
                  sx={{ fontSize: "25px" }}
                />
              </Badge>
            ) : (
              <FilterAltOutlinedIcon
                sx={{ fontSize: "25px" }}
              />
            )}
          </Button>
        </BootstrapTooltip>
      </div>
    </div>
  );
  const left = <div>Users</div>;

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          mt: 10,
          ml: 2,
          mr: 2,
          mb: 4,
        }}
      >
        <DashboardBreadcrumb left={left} right={right} />

        <UsersTable
          users={users}
          page={page}
          data={data}
          getUsers={(num) => changeTablePage(num)}
        />
        {data?.totalCount > 10 && (
          <Stack alignItems="center" mt={2}>
            <Pagination
              count={Math.ceil(data?.totalCount / 10)}
              siblingCount={0}
              page={page}
              onChange={(event, num) => handleChange(event, num)}
              variant="outlined"
              color="primary"
              shape="rounded"
            />
          </Stack>
        )}
      </Box>
      <UsersFilterModal
        keepMounted
        open={filterModal}
        filterStatus={filterStatus}
        filterBy={filterBy}
        handleOk={(num) => {
          getFilteredUsers(num);
        }}
        onClose={() => setFilterModal(false)}
      />
    </>
  );
}

export default Users;
