import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { STATUS } from "../../helpers/Constant";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function GiftVouchersFilterModal(props) {
  const [status, setStatus] = useState("");
  const [submit, setSubmit] = useState(false);
  const [validityPeriod, setValidityPeriod] = useState();
  const [validityAbb, setValidityAbb] = useState("month");

  useEffect(() => {
    setValidityPeriod(props.filterBy?.validityPeriod?.split(" ")[0] || "");
    setValidityAbb(props.filterBy?.validityPeriod?.split(" ")[1] || "month");
    setStatus(props.filterBy?.status || "");
  }, [props.filterBy?.status, props.filterBy?.validityPeriod]);
  function onCancel() {
    setSubmit(false);
    if (props.filterStatus) {
      setValidityPeriod(validityPeriod);
      setValidityAbb(validityAbb);
      setStatus(status);
    } else {
      setValidityPeriod("");
      setValidityAbb("month");
      setStatus("");
    }
    props.onClose();
  }

  const handleSubmit = (num) => {
    setSubmit(true);
    props.handleOk(num);
    onCancel();
  };

  return (
    <Modal
      keepMounted
      open={props.open}
      onClose={() => onCancel()}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style} component="form" onSubmit={handleSubmit}>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Gift Vouchers Filter
          </Typography>
          <CloseIcon onClick={() => onCancel()} />
        </Stack>

        <Stack sx={{ mt: 2 }} direction="raw" columnGap={2}>
          <TextField
            id="outlined-basic"
            sx={{ width: "100%" }}
            size="small"
            label="Validity period"
            name="validityPeriod"
            type="tel"
            inputProps={{ inputMode: "numeric" }}
            onKeyDown={(event) => {
              const allowedKeys = ["Backspace", "Delete", "Enter"];
              if (
                !/^[0-9]+$/.test(event.key) &&
                !/^Arrow(Left|Right|Up|Down)$/.test(event.key) &&
                !allowedKeys.includes(event.key)
              ) {
                event.preventDefault();
              }
            }}
            value={validityPeriod}
            onChange={(event) => {
              setValidityPeriod(event.target.value);
            }}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <FormControl>
                    <Select
                      id="outlined-basic"
                      size="small"
                      sx={{
                        marginRight: -2.8,
                        width: "110px",
                      }}
                      name="validityAbb"
                      value={validityAbb}
                      onChange={(event) => {
                        setValidityAbb(event.target.value);
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="year">Year</MenuItem>
                      <MenuItem value="month">Month</MenuItem>
                      <MenuItem value="day">Day</MenuItem>
                    </Select>
                  </FormControl>
                </InputAdornment>
              ),
            }}
          />

          <FormControl size="small" fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="status"
              value={status}
              label="Status"
              onChange={(event) => setStatus(event.target.value)}
            >
              <MenuItem value={STATUS.Active}>Active</MenuItem>
              <MenuItem value={STATUS.Disabled}>Disabled</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack sx={{ mt: 2 }} justifyContent="end" direction="row" spacing={1}>
          <Button
            variant="outlined"
            sx={{ width: 100, textTransform: "none" }}
            size="small"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
          <LoadingButton
            size="large"
            loadingPosition="start"
            loading={submit}
            variant="contained"
            type="submit"
            fullWidth
            sx={{
              mt: 2,
              textTransform: "none",
              width: 100,
            }}
          >
            <Typography variant="body1">Filter</Typography>
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
}

export default GiftVouchersFilterModal;
