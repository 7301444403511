import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import MembershipsTable from "../../components/tables/MembershipsTable";
import GetMembershipsTableApi from "../../api/memberships/GetMembershipsTableApi";

function Memberships() {
  const [memberships, setMemberships] = useState([]);

  useEffect(() => {
    getAllMemberships();
  }, []);

  async function getAllMemberships() {
    const apiResponse = await GetMembershipsTableApi();
    if (apiResponse.success) {
      setMemberships(apiResponse.data);
    }
  }
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        mt: 10,
        ml: 2,
        mr: 2,
        mb: 4,
      }}
    >
      <DashboardBreadcrumb left={<div>Memberships</div>} />
      <MembershipsTable
        memberships={memberships}
        refetch={() => getAllMemberships()}
      />
    </Box>
  );
}

export default Memberships;
