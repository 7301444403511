import React, { useEffect, useState } from "react";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import List from "@mui/material/List";
import { useLocation, useNavigate } from "react-router-dom";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LockIcon from "@mui/icons-material/Lock";

function SettingsSideMenu() {
  const [selectedIndex, setSelectedIndex] = useState("");
  let navigate = useNavigate();
  let curLocation = useLocation();

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const paths = [
    { path: "/settings/admin-profile", index: 0 },
    { path: "/settings/change-password", index: 1 },
  ];

  useEffect(() => {
    paths.forEach((path) => {
      if (path.path === curLocation.pathname) {
        setSelectedIndex(path.index);
      }
    });
  }, [curLocation]);

  return (
    <>
      <List>
        <ListItemButton
          selected={selectedIndex === 0}
          onClick={(event) => {
            handleListItemClick(event, 0);
            navigate(`settings/admin-profile`);
          }}
        >
          <ListItemIcon>
            <AdminPanelSettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Admin Profile" />
        </ListItemButton>

        <ListItemButton
          selected={selectedIndex === 1}
          onClick={(event) => {
            handleListItemClick(event, 1);
            navigate(`settings/change-password`);
          }}
        >
          <ListItemIcon>
            <LockIcon />
          </ListItemIcon>
          <ListItemText primary="Change Password" />
        </ListItemButton>
      </List>
    </>
  );
}

export default SettingsSideMenu;
