import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControl, InputLabel } from "@mui/material";
import { STATUS } from "../../helpers/Constant";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function AdminFilterModal(props) {
  const [status, setStatus] = useState("");
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    setStatus(props.filterBy?.status || "");
  }, [props.filterBy?.status]);

  function onCancel() {
    setSubmit(false);
    if (props.filterStatus) {
      setStatus(status);
    } else {
      setStatus("");
    }
    props.onClose();
  }

  const handleSubmit = (num) => {
    setSubmit(true);
    props.handleOk(num);
    onCancel();
  };

  return (
    <Modal
      keepMounted
      open={props.open}
      onClose={() => onCancel()}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style} component="form" onSubmit={handleSubmit}>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Admin Filter
          </Typography>
          <CloseIcon onClick={() => onCancel()} />
        </Stack>

        <FormControl sx={{ mt: 2 }} size="small" fullWidth>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="status"
            value={status}
            label="Status"
            onChange={(event) => setStatus(event.target.value)}
          >
            <MenuItem value={STATUS.Active}>Active</MenuItem>
            <MenuItem value={STATUS.Disabled}>Disabled</MenuItem>
          </Select>
        </FormControl>

        <Stack sx={{ mt: 2 }} justifyContent="end" direction="row" spacing={1}>
          <Button
            variant="outlined"
            sx={{ width: 100, textTransform: "none" }}
            size="small"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
          <LoadingButton
            size="large"
            loadingPosition="start"
            loading={submit}
            variant="contained"
            type="submit"
            fullWidth
            sx={{
              mt: 2,
              textTransform: "none",
              width: 100,
            }}
          >
            <Typography variant="body1">Filter</Typography>
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
}

export default AdminFilterModal;
