import React, { useEffect, useState } from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import GiftVouchersTable from "../../components/tables/GiftVouchersTable";
import AddGiftCardModal from "../../components/modals/add/AddGiftCardModal";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import GetGiftVouchersTableApi from "../../api/gift-vouchers/GetGiftVouchersTableApi";
import { Badge, Button, Pagination, Stack } from "@mui/material";
import GiftVouchersFilterModal from "../../components/modals/filters/GiftVouchersFilterModal";
import GetFilteredGiftVouchersTableApi from "../../api/gift-vouchers/GetFilteredGiftVouchersTableApi";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function GiftVouchers() {
  const [openAddGiftCard, setOpenAddGiftCard] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [filterModal, setFilterModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filterBy, setFilterBy] = useState();

  useEffect(() => {
    {
      filterStatus ? getFilteredGiftVouchers(page) : getGiftVouchers(page);
    }
  }, [page]);

  const handleChange = (event, num) => {
    changeTablePage(num);
  };

  async function getGiftVouchers(num) {
    const apiResponse = await GetGiftVouchersTableApi(num);

    if (apiResponse.success) {
      setData(apiResponse.data);
      setPage(num);
    }
  }

  async function getFilteredGiftVouchers(event, num) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const apiResponse = await GetFilteredGiftVouchersTableApi(
      data.get("validityPeriod") && `${data.get("validityPeriod")} ${data.get("validityAbb")}` || "",
      data.get("status") || "",
      num
    );
    if (apiResponse.success) {
      setData(apiResponse.data);
      setFilterStatus(true);
      setFilterBy({
        validityPeriod: `${data.get("validityPeriod")} ${data.get(
          "validityAbb"
        )}`,
        status: data.get("status"),
      });
      setPage(num);
    }
  }

  function changeTablePage(num) {
    if (filterStatus) {
      getFilteredGiftVouchers(filterBy, num);
    } else {
      getGiftVouchers(num);
    }
  }

  function resetFilter() {
    getGiftVouchers(1);
    setFilterStatus(false);
    setFilterBy();
  }

  const right = (
    <div className="flex items-center gap-2">
      <div onClick={() => resetFilter()}>
        <BootstrapTooltip title="Refresh" placement="top">
          <Button
            sx={{
              width: "50px",
              minWidth: "unset",
            }}
            variant="contained"
            disabled={!filterStatus}
          >
            <RefreshIcon
              sx={{
                fontSize: "25px",
                color: "primary",
              }}
            />
          </Button>
        </BootstrapTooltip>
      </div>
      <div onClick={() => setFilterModal(true)}>
        <BootstrapTooltip title="Filter" placement="top">
          <Button
            sx={{
              width: "50px",
              minWidth: "unset",
            }}
            variant="contained"
          >
            {filterStatus ? (
              <Badge
                color="secondary"
                overlap="circular"
                badgeContent=" "
                variant="dot"
              >
                <FilterAltOutlinedIcon sx={{ fontSize: "25px" }} />
              </Badge>
            ) : (
              <FilterAltOutlinedIcon sx={{ fontSize: "25px" }} />
            )}
          </Button>
        </BootstrapTooltip>
      </div>
      <div onClick={() => setOpenAddGiftCard(true)}>
        <BootstrapTooltip title="Add" placement="top">
          <Button
            sx={{
              width: "50px",
              minWidth: "unset",
            }}
            variant="contained"
          >
            <AddOutlinedIcon style={{ fontSize: "25px" }} />
          </Button>
        </BootstrapTooltip>
      </div>
    </div>
  );

  const left = <div>Gift Cards</div>;
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        mt: 10,
        ml: 2,
        mr: 2,
        mb: 4,
      }}
    >
      <DashboardBreadcrumb left={left} right={right} />
      <GiftVouchersTable
        gifts={data?.giftCards}
        page={page}
        changeTablePage={(num) => changeTablePage(num)}
      />
      {data.totalCount > 10 && (
        <Stack alignItems="center" mt={2}>
          <Pagination
            count={Math.ceil(data?.totalCount / 10)}
            siblingCount={0}
            page={page}
            onChange={handleChange}
            variant="outlined"
            color="primary"
            shape="rounded"
          />
        </Stack>
      )}
      <AddGiftCardModal
        keepMounted
        open={openAddGiftCard}
        handleOk={(num) => getGiftVouchers(num)}
        onClose={() => setOpenAddGiftCard(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      />
      <GiftVouchersFilterModal
        keepMounted
        open={filterModal}
        filterStatus={filterStatus}
        filterBy={filterBy}
        handleOk={(num) => {
          getFilteredGiftVouchers(num);
        }}
        onClose={() => setFilterModal(false)}
      />
    </Box>
  );
}

export default GiftVouchers;
