import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function HistoryFilterModal(props) {
  const [shopName, setShopName] = useState("");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);

  useEffect(() => {
    setShopName(props.filterBy?.shopName);
    setMinPrice(props?.filterBy?.minPrice);
    setMaxPrice(props.filterBy?.maxPrice);
  }, [props]);

  function onCancel() {
    props.onClose();
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const shopName = formData.get("shopName");
    const minPrice = formData.get("minPrice");
    const maxPrice = formData.get("maxPrice");

    await props.handleOk({
      shopName: shopName ? shopName : "",
      minPrice: minPrice ? minPrice : "",
      maxPrice: maxPrice ? maxPrice : "",
    });

    props.onClose();
  }
  return (
    <Modal
      open={props.open}
      onClose={() => onCancel()}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style} component="form" onSubmit={handleSubmit}>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Accounts History Filter
          </Typography>
          <CloseIcon onClick={() => onCancel()} />
        </Stack>

        <TextField
          defaultValue={shopName}
          sx={{ mt: 2 }}
          fullWidth
          size="small"
          name="shopName"
          label="Shop name"
          variant="outlined"
        />

        <Grid container spacing={2} mt={2}>
          <Grid item xs={6}>
            <TextField
              defaultValue={minPrice}
              type="number"
              fullWidth
              size="small"
              name="minPrice"
              label="Minimum price"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              defaultValue={maxPrice}
              fullWidth
              type="number"
              size="small"
              name="maxPrice"
              label="Maximum price"
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Stack sx={{ mt: 4 }} justifyContent="end" direction="row" spacing={1}>
          <Button
            variant="outlined"
            sx={{ width: 100, textTransform: "none" }}
            size="small"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
          <LoadingButton
            size="large"
            loadingPosition="start"
            variant="contained"
            type="submit"
            fullWidth
            sx={{
              mt: 2,
              textTransform: "none",
              width: 100,
            }}
          >
            <Typography variant="body1">Filter</Typography>
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
}

export default HistoryFilterModal;
