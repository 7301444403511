import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";

import NotificationHelper from "../../helpers/NotificationHelper";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EditMembershipApi from "../../../api/memberships/EditMembershipApi";

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function EditMembershipModal(props) {
  const [submit, setSubmit] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [listings, setListings] = useState(0);
  const [boosts, setBoosts] = useState(0);
  const [subscription, setSubscription] = useState(0);
  const [effectiveDate, setEffectiveDate] = useState(0);
  const [listingError, setListingError] = useState("");
  const [boostError, setBoostError] = useState("");
  const [effectiveDateError, setEffectiveDateError] = useState("");

  useEffect(() => {
    setListings(props.selectedMembership?.numberOfListing);
    setBoosts(props.selectedMembership?.noOfBoost);
    setSubscription(
      props.selectedMembership?.price ? props.selectedMembership?.price : 0
    );
  }, [props.selectedMembership]);

  function handleCancel() {
    setListings(0);
    setBoosts(0);
    setSubscription(0);
    setListingError("");
    setBoostError("");
    setEffectiveDateError("");
    setSubmit(false);
    props.onClose();
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setSubmit(true);
    if (!listings) {
      setListingError("Listings cannot be empty!");
    }
    if (!boosts) {
      setBoostError("Boosts cannot be empty!");
    }

    if (!effectiveDate) {
      setEffectiveDateError("Effective date cannot be empty!");
    }
    if (listings && boosts && effectiveDate) {
      const apiResponse = await EditMembershipApi(
        props.selectedMembership?.id,
        boosts,
        subscription,
        listings,
        effectiveDate
      );
      if (apiResponse.statusCode) {
        setNotification({
          open: true,
          type: "error",
          message: apiResponse.message,
        });
      } else if (apiResponse.success) {
        props.handleOk();
        setNotification({
          open: true,
          type: "success",
          message: apiResponse.message,
        });
      }

      handleCancel();
    }
    setSubmit(false);
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => handleCancel()}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Edit membership
            </Typography>
            <CloseIcon
              className="cursor-pointer"
              onClick={() => handleCancel()}
            />
          </Stack>

          <Stack sx={{ mt: 2 }} direction="row" spacing={2} alignItems="center">
            <TextField
              type="number"
              fullWidth
              name="listings"
              size="small"
              label="Listings"
              variant="outlined"
              value={listings}
              onChange={(e) => {
                setListingError("");
                setListings(e.target.value);
              }}
              error={listingError ? true : false}
              helperText={listingError ? listingError : " "}
            />
            <TextField
              type="number"
              fullWidth
              name="boosts"
              size="small"
              label="Boosts"
              variant="outlined"
              value={boosts}
              onChange={(e) => {
                setBoostError("");
                setBoosts(e.target.value);
              }}
              error={boostError ? true : false}
              helperText={boostError ? boostError : " "}
            />
          </Stack>
          <Stack sx={{ mt: 1 }} direction="row" spacing={2} alignItems="center">
            <TextField
              type="number"
              fullWidth
              name="subscription"
              size="small"
              label="Subscription"
              variant="outlined"
              value={subscription}
              onChange={(e) => {
                setSubscription(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">C$</InputAdornment>
                ),
              }}
              helperText={" "}
            />
            <FormControl className="datePicker" fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="datepicker"
                  label="Effective date"
                  minDate={dayjs().add(1, "day")}
                  name="effectiveDate"
                  onChange={(newValue) => {
                    setEffectiveDateError("");
                    setEffectiveDate(newValue);
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
              <FormHelperText error={!!effectiveDateError} id="effectiveDate">
                {effectiveDateError ? effectiveDateError : " "}
              </FormHelperText>
            </FormControl>
          </Stack>

          <Stack
            sx={{ mt: 2 }}
            justifyContent="end"
            direction="row"
            spacing={1}
          >
            <Button
              sx={{ textTransform: "none" }}
              variant="outlined"
              size="small"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <LoadingButton
              size="large"
              loading={submit}
              loadingPosition="start"
              variant="contained"
              type="submit"
              fullWidth
              sx={{
                mt: 2,
                textTransform: "none",
                width: 80,
              }}
            >
              <Typography variant="body1">Edit</Typography>
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
      <NotificationHelper notification={notification} />
    </>
  );
}

export default EditMembershipModal;
