import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CreateFeatureApi from "../../../api/feature/CreateFeatureApi";
import { LoadingButton } from "@mui/lab";
import NotificationHelper from "../../helpers/NotificationHelper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};

function AddFeaturedCategoryOrProductModal(props) {
  const [submit, setSubmit] = useState();
  const [link, setLink] = useState("");
  const [linkError, setLinkError] = useState("");

  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });

  function onCancel() {
    setLink("");
    props.onClose();
  }

  async function handleSubmit(event) {
    setSubmit(true);

    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!data.get("link")) {
      setLinkError("Link cannot be empty!");
      setSubmit(false);
    }
    if (data.get("link")) {
      const apiResponse = await CreateFeatureApi(
        props.featureId,
        data.get("link")
      );
      if (apiResponse.statusCode) {
        setNotification({
          open: true,
          type: "error",
          message: apiResponse.message,
        });
      } else if (apiResponse.success) {
        setNotification({
          open: true,
          type: "success",
          message: apiResponse.message,
        });
      }
      props.handleOk();
      onCancel();
    }
    setSubmit(false);
  }

  return (
    <>
      <Modal
        open={props.openCategory}
        onClose={() => onCancel()}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Add Category/Product
            </Typography>
            <CloseIcon onClick={() => onCancel} />
          </Stack>

          <TextField
            sx={{ mt: 2 }}
            size="small"
            name="link"
            value={link}
            onChange={(event) => {
              setLink(event.target.value);
              setLinkError("");
            }}
            error={linkError ? true : false}
            helperText={linkError ? linkError : " "}
            variant="outlined"
            label="Link"
            fullWidth
          />

          <Stack justifyContent="end" direction="row" spacing={1}>
            <Button
              sx={{ textTransform: "none" }}
              variant="outlined"
              size="small"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
            <LoadingButton
              size="large"
              loading={submit}
              loadingPosition="start"
              variant="contained"
              type="submit"
              fullWidth
              sx={{
                mt: 2,
                textTransform: "none",
                width: 80,
              }}
            >
              <Typography variant="body1">Add</Typography>
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
      <NotificationHelper notification={notification} />
    </>
  );
}

export default AddFeaturedCategoryOrProductModal;
