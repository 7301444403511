import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useLocation } from "react-router-dom";

function ContestsDetails() {
  const curLocation = useLocation();
  const path = curLocation.pathname;
  const name = path.split("/");

  const left = <div>Contests / {decodeURIComponent(name[2])}</div>;
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        flexGrow: 1,
        height: "100vh",
        mt: 10,
        ml: 2,
        mr: 2,
        mb: 8,
      }}
    >
      <DashboardBreadcrumb left={left} />
      <Typography component="h2" variant="h6">
        Contests Details
      </Typography>

      <Typography sx={{ mt: 2, color: "grey.500" }}>Contest name</Typography>
      <Typography>{decodeURIComponent(name[2])}</Typography>
      <Typography sx={{ mt: 2 }}>Add Contest Banner</Typography>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        fullWidth
        sx={{
          mt: 2,
          mb: 2,
          height: "150px",
          backgroundColor: "#eeeeee",
          border: "white",
        }}
      >
        <Button component="label">
          IMAGE
          <input hidden accept="image/*" multiple type="file" />
        </Button>
      </Stack>
      <Stack spacing={1}>
        <Typography sx={{ color: "grey.500" }}>About the contest</Typography>
        <Typography>
          Unique and eye-catching designs that capture the holiday season
        </Typography>
      </Stack>
      <Typography sx={{ mt: 2, mb: 2 }}>Rewards</Typography>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={4}>
          <Stack spacing={1}>
            <Typography sx={{ color: "grey.500" }}>1st place</Typography>
            <Typography>$ 100 gift card</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={1}>
            <Typography sx={{ color: "grey.500" }}>2nd place</Typography>
            <Typography>$ 50 gift card</Typography>
            <Typography></Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={1}>
            <Typography sx={{ color: "grey.500" }}>3rd place</Typography>
            <Typography>$ 10 gift card</Typography>
          </Stack>
        </Grid>
      </Grid>

      <Typography sx={{ mt: 2, mb: 2 }}>Timeline</Typography>
      <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={4}>
          <Stack spacing={1}>
            <Typography sx={{ color: "grey.500" }}>
              Submissions open date
            </Typography>
            <Typography>10.01.2023</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={1}>
            <Typography sx={{ color: "grey.500" }}>
              Submissions close date
            </Typography>
            <Typography>10.02.2023</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={1}>
            <Typography sx={{ color: "grey.500" }}>
              Public voting open date
            </Typography>
            <Typography>10.03.2023</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={1}>
            <Typography sx={{ color: "grey.500" }}>
              Public voting close date
            </Typography>
            <Typography>10.04.2023</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={1}>
            <Typography sx={{ color: "grey.500" }}>
              Winner announcement date
            </Typography>
            <Typography>10.05.2023</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ContestsDetails;
