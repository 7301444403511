import React, { useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Paper from "@mui/material/Paper";
import { STATUS } from "../helpers/Constant";
import { CustomNoRowsOverlay } from "../helpers/NoData";
import NotificationHelper from "../helpers/NotificationHelper";
import EditBoostPlanModal from "../modals/edit/EditBoostPlanModal";
import DisableEnableBoostPlanApi from "../../api/boost-plans/DisableEnableBoostPlanApi";
import ConfirmationModal from "../modals/ConfirmationModal";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import DeleteBoostPlanApi from "../../api/boost-plans/DeleteBoostPlanApi";
import HardDeleteModal from "../modals/HardDeleteModal";

function BoostPlansTable(props) {
  const [page, setPage] = useState(1);
  const [editBoostPlanModal, setEditBoostPlanModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [enableDisableBoostModal, setEnableDisableBoostModal] = useState(false);
  const [enableDisableLoading, setEnableDisableLoading] = useState(false);
  const [selectBoostStatus, setSelectBoostStatus] = useState();
  const [boostId, setBoostId] = useState("");
  const [deleteBoostModal, setDeleteBoostModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    setPage(props.page);
  }, [props.page]);

  async function disableEnableBoost() {
    setEnableDisableLoading(true);
    const apiResponse = await DisableEnableBoostPlanApi(
      boostId,
      selectBoostStatus
    );

    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse.success) {
      setNotification({
        open: true,
        type: "success",
        message: apiResponse.message,
      });
      props.changeTablePage();
    }
    setEnableDisableLoading(false);
    setBoostId("");
    setEnableDisableBoostModal(false);
  }
  async function deleteBoost() {
    setDeleteLoading(true);
    const apiResponse = await DeleteBoostPlanApi(boostId);

    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse.success) {
      setNotification({
        open: true,
        type: "success",
        message: apiResponse.message,
      });
      if (
        props.boosts?.length === 1
      ) {
        props.changeTablePage(page - 1);
      } else {
        props.changeTablePage(page);
      }
    }
    setPage(page);
    setDeleteLoading(false);
    setBoostId("");
    setDeleteBoostModal(false);
  }

  return (
    <>
      <Typography component="h2" variant="h6" gutterBottom>
        Boost Plans
      </Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Boost plan name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Price</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>No of Boost</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>status</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          {props.boosts?.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  <CustomNoRowsOverlay message={"No Gift Voucher"} />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.boosts?.map((boost) => (
                <TableRow key={boost.id}>
                  <TableCell>{boost.name}</TableCell>
                  <TableCell>{boost.price}</TableCell>
                  <TableCell>{boost.noOfBoost}</TableCell>
                  <TableCell>
                    {boost.status === STATUS.Active ? "Active" : "Disabled"}
                  </TableCell>
                  <TableCell>
                    <div className="flex justify-items-center gap-1">
                      <div
                        onClick={() => {
                          setSelectedRow(boost);
                          setEditBoostPlanModal(true);
                        }}
                      >
                        <EditOutlinedIcon
                          style={{ fontSize: "20px" }}
                          className="text-blue-500"
                        />
                      </div>
                      {boost.status === STATUS.Disabled ? (
                        <div
                          onClick={() => {
                            setBoostId(boost.id);
                            setSelectBoostStatus(STATUS.Active);
                            setEnableDisableBoostModal(true);
                          }}
                        >
                          <CheckCircleOutlinedIcon
                            style={{ fontSize: "20px" }}
                            className="text-green-500"
                          />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setBoostId(boost.id);
                            setSelectBoostStatus(STATUS.Disabled);
                            setEnableDisableBoostModal(true);
                          }}
                        >
                          <BlockOutlinedIcon
                            style={{ fontSize: "20px" }}
                            className="text-orange-500"
                          />
                        </div>
                      )}
                      <div
                        onClick={() => {
                          setBoostId(boost.id);
                          setDeleteBoostModal(true);
                        }}
                      >
                        <DeleteOutlinedIcon
                          style={{ fontSize: "20px" }}
                          className="text-red-500"
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <NotificationHelper notification={notification} />
      <EditBoostPlanModal
        keepMounted
        open={editBoostPlanModal}
        handleOk={(num) => {
          props.changeTablePage(num);
        }}
        onClose={() => setEditBoostPlanModal(false)}
        selectedRow={selectedRow}
      />
      <ConfirmationModal
        keepMounted
        title={
          selectBoostStatus === STATUS.Disabled
            ? "Disable boost plan"
            : "Enable boost plan"
        }
        description={
          selectBoostStatus === STATUS.Disabled
            ? "Are you sure want to disable this boost plan?"
            : "Are you sure want to enable this boost plan?"
        }
        loading={enableDisableLoading}
        open={enableDisableBoostModal}
        handleOk={() => disableEnableBoost()}
        onClose={() => {
          setEnableDisableBoostModal(false);
          setBoostId("");
        }}
      />
      <HardDeleteModal
        keepMounted
        title="Delete boost plan"
        description="Are you sure want to delete this boost plan?"
        open={deleteBoostModal}
        loading={deleteLoading}
        handleOk={() => deleteBoost()}
        onClose={() => {
          setDeleteBoostModal(false);
          setBoostId("");
        }}
      />
    </>
  );
}
export default BoostPlansTable;
