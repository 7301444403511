import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function HardDeleteModal(props) {
  const [form, setForm] = useState({
    delete: "",
    error: "",
  });

  function onCancel() {
    setForm((prevForm) => ({
      ...prevForm,
      delete: {
        value: "",
        error: "",
      },
    }));
    props.onClose();
  }

  function onFinish(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    
    if (data.get("delete") === "delete") {
      props.handleOk();
      setForm((prevForm) => ({
        ...prevForm,
        delete: {
          value: "",
          error: "",
        },
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        delete: {
          name: "delete",
          error: "If you want to delete, type 'delete'.",
        },
      }));
    }
  }

  return (
    <Modal
      keepMounted
      open={props.open}
      onClose={() => onCancel()}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style} component="form" onSubmit={onFinish}>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            {props.title}
          </Typography>
          <CloseIcon onClick={() => onCancel()} />
        </Stack>
        <Typography>{props.description}</Typography>

        <TextField
          margin="dense"
          fullWidth
          id="delete"
          placeholder="Type 'delete'"
          name="delete"
          value={form.delete.value}
          onChange={(event) =>
            setForm((prevForm) => ({
              ...prevForm,
              delete: {
                value: event.target.value,
                error: "",
              },
            }))
          }
          error={Boolean(form.delete.error)}
          helperText={form.delete.error ? form.delete.error : " "}
          variant="outlined"
          size="small"
        />

        <Stack  justifyContent="end" direction="row" spacing={1}>
          <Button
            variant="outlined"
            size="small"
            sx={{ textTransform: "none", width: 100 }}
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
          <LoadingButton
            size="large"
            loading={props.loading}
            loadingPosition="start"
            variant="contained"
            type="submit"
            fullWidth
            sx={{
              mt: 2,
              textTransform: "none",
              width: 100,
            }}
          >
            <Typography variant="body1">Confirm</Typography>
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
}

export default HardDeleteModal;
