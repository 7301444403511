import React, { useEffect, useState } from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Typography from "@mui/material/Typography";
import AddCategoryModal from "../../components/modals/add/AddCategoryModal";
import AddSubCategoryModal from "../../components/modals/add/AddSubCategoryModal";
import Button from "@mui/material/Button";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useNavigate } from "react-router-dom";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import Grid from "@mui/material/Unstable_Grid2";
import GetCategoryTableApi from "../../api/category/GetCategoryTableApi";
import NotificationHelper from "../../components/helpers/NotificationHelper";
import DeleteCategoryApi from "../../api/category/DeleteCategoryApi";
import EditCategoryModal from "../../components/modals/edit/EditCategoryModal";
import SvgIcon from "@mui/material/SvgIcon";
import { treeItemClasses } from "@mui/lab/TreeItem";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import DisableEnableCategoryApi from "../../api/category/DisableEnableCategoryApi";
import { STATUS } from "../../components/helpers/Constant";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Badge, Pagination, Stack } from "@mui/material";
import CategoryFilterModal from "../../components/modals/filters/CategoryFilterModal";
import RefreshIcon from "@mui/icons-material/Refresh";
import GetFilteredCategoryTableApi from "../../api/category/GetFilteredCategoryTableApi";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import HardDeleteModal from "../../components/modals/HardDeleteModal";
import { CustomNoRowsOverlay } from "../../components/helpers/NoData";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function Categories() {
  const [open, setOpen] = useState(false);
  const [openSubCategory, setOpenSubCategory] = useState(false);
  const [categories, setCategories] = useState();
  const [categoryId, setCategoryId] = useState();
  const [openDisableCategory, setOpenDisableCategory] = useState(false);
  const [openDeleteCategory, setOpenDeleteCategory] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [selectedRow, setSelectedRow] = useState();
  const [openEditCategory, setOpenEditCategory] = useState(false);
  const [selectCategoryStatus, setSelectCategoryStatus] = useState();
  const [page, setPage] = useState(1);
  const [data, setData] = useState();
  const [filterModal, setFilterModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filterBy, setFilterBy] = useState();
  const [loading, setLoading] = useState(false);
  const [disableLoading, setDisableLoading] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    {
      filterStatus ? getFilteredCategory(page) : getCategories(page);
    }
  }, [page]);

  const handleChange = (event, num) => {
    changeTablePage(num);
  };

  async function getCategories(num) {
    const apiResponse = await GetCategoryTableApi(num);
    if (apiResponse.success) {
      setCategories(apiResponse.data?.categories);
      setData(apiResponse.data);
      setPage(num);
    }
  }

  async function getFilteredCategory(event, num) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const apiResponse = await GetFilteredCategoryTableApi(
      data.get("name") || "",
      data.get("status") || "",
      num
    );
    if (apiResponse.success) {
      setCategories(apiResponse.data?.categories);
      setFilterStatus(true);
      setFilterBy({
        location: data.get("name"),
        status: data.get("status"),
      });
      setData(apiResponse.data);
      setPage(num);
    }
  }

  function changeTablePage(num) {
    if (filterStatus) {
      getFilteredCategory(filterBy, num);
    } else {
      getCategories(num);
    }
  }

  function resetFilter() {
    getCategories(1);
    setFilterStatus(false);
    setFilterBy();
  }

  async function disableCategory() {
    setDisableLoading(true);
    const apiResponse = await DisableEnableCategoryApi(
      categoryId,
      selectCategoryStatus
    );

    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse.success) {
      setNotification({
        open: true,
        type: "success",
        message: apiResponse.message,
      });
      getCategories();
    }
    setDisableLoading(false);
    setCategoryId("");
    setOpenDisableCategory(false);
  }

  async function deleteCategory() {
    setLoading(true);
    const apiResponse = await DeleteCategoryApi(categoryId);

    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse.success) {
      setNotification({
        open: true,
        type: "success",
        message: apiResponse.message,
      });

      if (data.totalCount > 1 && categories.length.toString().endsWith("1")) {
        getCategories(page - 1);
        setPage(page - 1);
      } else {
        getCategories(page);
      }
    }

    setLoading(false);
    setCategoryId("");
    setOpenDeleteCategory(false);
  }

  const right = (
    <div className="flex items-center gap-2">
      <Button
        onClick={() => navigate(`/categories/requests`)}
        variant="contained"
        size="small"
        sx={{ height: "36px", textTransform: "none" }}
      >
        Requests
      </Button>
      <div onClick={() => resetFilter()}>
        <BootstrapTooltip title="Refresh" placement="top">
          <Button
            sx={{
              width: "50px",
              minWidth: "unset",
            }}
            variant="contained"
            disabled={!filterStatus}
          >
            <RefreshIcon
              sx={{
                fontSize: "25px",
                color: "primary",
              }}
            />
          </Button>
        </BootstrapTooltip>
      </div>
      <div onClick={() => setFilterModal(true)}>
        <BootstrapTooltip title="Filter" placement="top">
          <Button
            sx={{
              width: "50px",
              minWidth: "unset",
            }}
            variant="contained"
          >
            {filterStatus ? (
              <Badge
                color="secondary"
                overlap="circular"
                badgeContent=" "
                variant="dot"
              >
                <FilterAltOutlinedIcon sx={{ fontSize: "25px" }} />
              </Badge>
            ) : (
              <FilterAltOutlinedIcon sx={{ fontSize: "25px" }} />
            )}
          </Button>
        </BootstrapTooltip>
      </div>
      <div onClick={() => setOpen(true)}>
        <BootstrapTooltip title="Add" placement="top">
          <Button
            sx={{
              width: "50px",
              minWidth: "unset",
            }}
            variant="contained"
          >
            <AddOutlinedIcon style={{ fontSize: "25px" }} />
          </Button>
        </BootstrapTooltip>
      </div>
    </div>
  );

  function hasNestedSubcategories(subcategory) {
    let hasNested = false;

    if (subcategory?.childCategories?.length > 0) {
      hasNested = true;
    }
    return hasNested;
  }

  function renderNestedSubcategories(category, startIndex = 0) {
    if (category?.childCategories?.length === 0) {
      return null;
    }

    const subcategoryClassName = `subcategory-${startIndex + 1}`;

    let output = [];
    category?.childCategories?.forEach((subcategory) => {
      output.push(
        <StyledTreeItem
          className="tree--height"
          expandIcon={
            hasNestedSubcategories(subcategory) ? (
              <PlusSquare />
            ) : (
              <CloseSquare />
            )
          }
          collapseIcon={
            hasNestedSubcategories(subcategory) ? (
              <MinusSquare />
            ) : (
              <CloseSquare />
            )
          }
          nodeId={subcategory._id}
          label={
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
                sx={{
                  "--Grid-borderWidth": "1px",

                  borderColor: "divider",
                  "& > div": {
                    borderColor: "divider",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                }}
              >
                <Grid container item xs={4} alignItems="flex-end">
                  <Grid
                    item
                    xs={
                      subcategoryClassName === "subcategory-1"
                        ? 10
                        : subcategoryClassName === "subcategory-2"
                        ? 9
                        : subcategoryClassName === "subcategory-3"
                        ? 8
                        : subcategoryClassName === "subcategory-4"
                        ? 7
                        : subcategoryClassName === "subcategory-5"
                        ? 6
                        : subcategoryClassName === "subcategory-6"
                        ? 5
                        : subcategoryClassName === "subcategory-7"
                        ? 4
                        : 3
                    }
                  >
                    {subcategory.name}
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <img className=" w-12 " src={subcategory.imgUrl} />
                </Grid>

                <Grid item xs={2}>
                  {subcategory.status === 1 ? "Active" : "Disable"}
                </Grid>
                <Grid item xs={3}>
                  <div className="flex justify-items-center gap-1">
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        setSelectedRow(subcategory);
                        setOpenSubCategory(true);
                      }}
                    >
                      <AddCircleOutlineOutlinedIcon
                        style={{ fontSize: "20px" }}
                        className="text-blue-500"
                      />
                    </div>
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        setSelectedRow(subcategory);
                        setOpenEditCategory(true);
                      }}
                    >
                      <EditOutlinedIcon
                        style={{ fontSize: "20px" }}
                        className="text-blue-500"
                      />
                    </div>
                    {subcategory.status === STATUS.Disabled ? (
                      <div
                        onClick={(event) => {
                          event.stopPropagation();
                          setOpenDisableCategory(true);
                          setCategoryId(subcategory._id);
                          setSelectCategoryStatus(STATUS.Active);
                        }}
                      >
                        <CheckCircleOutlinedIcon
                          style={{ fontSize: "20px" }}
                          className="text-green-500"
                        />
                      </div>
                    ) : (
                      <div
                        onClick={(event) => {
                          event.stopPropagation();
                          setCategoryId(subcategory._id);
                          setSelectCategoryStatus(STATUS.Disabled);
                          setOpenDisableCategory(true);
                        }}
                      >
                        <BlockOutlinedIcon
                          style={{ fontSize: "20px" }}
                          className="text-orange-500"
                        />
                      </div>
                    )}
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        setOpenDeleteCategory(true);
                        setCategoryId(subcategory._id);
                      }}
                    >
                      <DeleteOutlinedIcon
                        style={{ fontSize: "20px" }}
                        className="text-red-500"
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          }
        >
          {renderNestedSubcategories(subcategory, startIndex + 1)}{" "}
        </StyledTreeItem>
      );
    });

    return output;
  }

  function rendercategory() {
    let output = [];
    categories?.forEach((category) => {
      output.push(
        <StyledTreeItem
          className="tree--height"
          nodeId={category._id}
          label={
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
                sx={{
                  "--Grid-borderWidth": "1px",
                  borderColor: "divider",
                  "& > div": {
                    borderColor: "divider",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                }}
              >
                <Grid container item xs={4} alignItems="flex-end">
                  <Grid item xs={11}>
                    {category.name}
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <img className=" w-12" src={category.imgUrl} />
                </Grid>

                <Grid item xs={2}>
                  {category.status === 1 ? "Active" : "Disable"}
                </Grid>
                <Grid item xs={3}>
                  <div className="flex justify-items-center gap-1">
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        setSelectedRow(category);
                        setOpenSubCategory(true);
                      }}
                    >
                      <AddCircleOutlineOutlinedIcon
                        style={{ fontSize: "20px" }}
                        className="text-blue-500"
                      />
                    </div>
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        setSelectedRow(category);
                        setOpenEditCategory(true);
                      }}
                    >
                      <EditOutlinedIcon
                        style={{ fontSize: "20px" }}
                        className="text-blue-500"
                      />
                    </div>
                    {category.status === STATUS.Disabled ? (
                      <div
                        onClick={(event) => {
                          event.stopPropagation();
                          setOpenDisableCategory(true);
                          setCategoryId(category._id);
                          setSelectCategoryStatus(STATUS.Active);
                        }}
                      >
                        <CheckCircleOutlinedIcon
                          style={{ fontSize: "20px" }}
                          className="text-green-500"
                        />
                      </div>
                    ) : (
                      <div
                        onClick={(event) => {
                          event.stopPropagation();
                          setCategoryId(category._id);
                          setSelectCategoryStatus(STATUS.Disabled);
                          setOpenDisableCategory(true);
                        }}
                      >
                        <BlockOutlinedIcon
                          style={{ fontSize: "20px" }}
                          className="text-orange-500"
                        />
                      </div>
                    )}
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        setOpenDeleteCategory(true);
                        setCategoryId(category._id);
                      }}
                    >
                      <DeleteOutlinedIcon
                        style={{ fontSize: "20px" }}
                        className="text-red-500"
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          }
        >
          {renderNestedSubcategories(category)}
        </StyledTreeItem>
      );
    });
    return output;
  }

  function MinusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
      </SvgIcon>
    );
  }

  function PlusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
      </SvgIcon>
    );
  }

  function CloseSquare(props) {
    return (
      <SvgIcon
        className="close"
        fontSize="inherit"
        style={{ width: 14, height: 14 }}
        {...props}
      >
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
      </SvgIcon>
    );
  }

  const StyledTreeItem = styled((props) => <TreeItem {...props} />)(
    ({ theme, value }) => ({
      [`& .${treeItemClasses.iconContainer}`]: {
        "& .close": {
          opacity: 0,
        },
      },
      [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        paddingLeft: 0,
      },
    })
  );

  const left = <div>Categories</div>;
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",

          mt: 10,
          ml: 2,
          mr: 2,
          mb: 4,
        }}
      >
        <DashboardBreadcrumb left={left} right={right} />

        <Typography component="h2" variant="h6" gutterBottom>
          Categories
        </Typography>

        <Box
          sx={{
            mt: 3,
            border: "1px solid #ccc",
            pt: 3,
            borderRadius: "6px",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
              sx={{
                "--Grid-borderWidth": "1px",
                borderColor: "divider",
                "& > div": {
                  borderColor: "divider",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}
            >
              <Grid item xs={4} sx={{ fontWeight: 500 }}>
                Category Name
              </Grid>
              <Grid item xs={3} sx={{ fontWeight: 500 }}>
                Category Image
              </Grid>

              <Grid item xs={2} sx={{ fontWeight: 500 }}>
                Status
              </Grid>
              <Grid item xs={3} sx={{ fontWeight: 500 }}>
                Action
              </Grid>
            </Grid>
          </Box>
          {categories?.length === 0 ? (
            <CustomNoRowsOverlay message={"No Category"} />
          ) : (
            <TreeView
              aria-label="customized"
              defaultCollapseIcon={<MinusSquare />}
              defaultExpandIcon={<PlusSquare />}
              defaultEndIcon={<CloseSquare />}
              sx={{
                mt: 2,
                flexGrow: 1,
              }}
            >
              {rendercategory()}
            </TreeView>
          )}
        </Box>
        {data?.totalcount > 10 && (
          <Stack sx={{ mt: 2 }} alignItems="center">
            <Pagination
              count={Math.ceil(data?.totalCount / 10)}
              siblingCount={0}
              page={page}
              onChange={handleChange}
              variant="outlined"
              color="primary"
              shape="rounded"
            />
          </Stack>
        )}
      </Box>
      <NotificationHelper notification={notification} />

      <CategoryFilterModal
        keepMounted
        open={filterModal}
        filterStatus={filterStatus}
        filterBy={filterBy}
        handleOk={(num) => {
          getFilteredCategory(num);
        }}
        onClose={() => setFilterModal(false)}
      />
      <AddCategoryModal
        keepMounted
        open={open}
        handleOk={() => {
          getCategories();
        }}
        onClose={() => setOpen(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      />
      <AddSubCategoryModal
        keepMounted
        openSubCategory={openSubCategory}
        handleOk={() => {
          getCategories();
        }}
        onClose={() => setOpenSubCategory(false)}
        selectedRow={selectedRow}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      />
      <EditCategoryModal
        keepMounted
        openEditCategory={openEditCategory}
        handleOk={() => {
          getCategories();
        }}
        onClose={() => setOpenEditCategory(false)}
        selectedRow={selectedRow}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      />

      <ConfirmationModal
        keepMounted
        title={
          selectCategoryStatus === STATUS.Disabled
            ? "Disable Category"
            : "Enable Category"
        }
        description={
          selectCategoryStatus === STATUS.Disabled
            ? "Are you sure want to disable this category?"
            : "Are you sure want to Enable this category?"
        }
        loading={disableLoading}
        open={openDisableCategory}
        handleOk={() => disableCategory()}
        onClose={() => {
          setOpenDisableCategory(false);
          setCategoryId("");
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      />

      <HardDeleteModal
        keepMounted
        title="Delete Category"
        description="Are you sure want to delete this category?"
        open={openDeleteCategory}
        loading={loading}
        handleOk={() => deleteCategory()}
        onClose={() => {
          setOpenDeleteCategory(false);
          setCategoryId("");
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      />
    </>
  );
}

export default Categories;
