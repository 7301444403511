import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import EmailIcon from "@mui/icons-material/Email";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoginApi from "../../api/auth/LoginApi";
import { login, logout } from "../../features/AuthSlice";
import { userDetails } from "../../features/UserSlice";
import { firstLogin } from "../../features/FirstLoginSlice";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { GetMeApi } from "../../api/user/GetMeApi";
import { LoadingButton } from "@mui/lab";

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  let navigate = useNavigate();
  const dispatch = useDispatch();

  function isValidEmail(email) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  }

  function showErrorMessage(message) {
    if (message === "Invalid credentials") {
      setPasswordError("Username and password didn't match");
      setSubmit(false);
    } else if (message === "Admin not found") {
      setEmailError("Admin not found");
      setSubmit(false);
    } else {
      setEmailError("");
      setPasswordError("Couldn't login. Try again later.");
      setSubmit(false);
    }
  }

  async function handleSubmit(event) {
    setSubmit(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get("email");
    const password = data.get("password");

    if (!email) {
      setEmailError("Email cannot be empty!");
      setSubmit(false);
    } else if (!isValidEmail(email)) {
      setSubmit(false);
      setEmailError("Email is not valid!");
    }
    if (!password) {
      setPasswordError("Password cannot be empty!");
      setSubmit(false);
    }
    if (email && password && isValidEmail(email)) {
      const apiResponse = await LoginApi(
        data.get("email"),
        data.get("password")
      );

      if (apiResponse.statusCode) {
        showErrorMessage(apiResponse.message);
      } else if (apiResponse.data?.customerAccessToken) {
        localStorage.setItem(
          "authToken",
          apiResponse.data?.customerAccessToken
        );
        localStorage.setItem("authUser", apiResponse.data?.adminId);
        getUserDetails();
        dispatch(login());
        if (apiResponse.data?.firstLogin) {
          dispatch(firstLogin(true));
          navigate("/create-newpassword");
        } else {
          navigate(`/website-layout`);
        }
      } else {
        showErrorMessage("Something is wrong");
      }
    }
    setSubmit(false);
  }

  async function getUserDetails() {
    const apiResponse = await GetMeApi();
    if (apiResponse.statusCode) {
      localStorage.setItem("authToken", "");
      localStorage.setItem("authUser", "");
      dispatch(logout());
    } else if (apiResponse.fullName) {
      dispatch(userDetails(apiResponse));
    } else {
      localStorage.setItem("authToken", "");
      localStorage.setItem("authUser", "");
      dispatch(logout());
    }
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="h-screen flex w-full  justify-center ">
        <Box
          sx={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Welcome
          </Typography>
          <Typography textAlign="center">
            Admin! Enter your credentials to access your account
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 3, width: "400px" }}
          >
            <TextField
              margin="dense"
              fullWidth
              id="email"
              label="Email"
              name="email"
              error={emailError ? true : false}
              helperText={emailError ? emailError : " "}
              onChange={() => setEmailError("")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <EmailIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
            />

            <FormControl variant="outlined" fullWidth sx={{ mb: 2, mt: 1 }}>
              <InputLabel
                error={passwordError ? true : false}
                htmlFor="outlined-adornment-password"
                size="small"
              >
                Password
              </InputLabel>
              <OutlinedInput
                error={passwordError ? true : false}
                id="outlined-adornment-password"
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                size="small"
                helperText={passwordError ? passwordError : " "}
                onChange={() => setPasswordError("")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                sx={{ mb: -2 }}
                error={passwordError ? true : false}
                id="outlined-weight-helper-text"
              >
                {passwordError ? passwordError : " "}
              </FormHelperText>
            </FormControl>

            <LoadingButton
              loading={submit}
              loadingPosition="start"
              variant="contained"
              type="submit"
              fullWidth
              sx={{ mt: 1, mb: 2, textTransform: "initial" }}
            >
              Login
            </LoadingButton>
          </Box>

          <Typography sx={{ mt: 1 }} textAlign="center">
            Forgot your password?{" "}
            <Link
              href="/forgot-password"
              style={{ color: "blue", textDecoration: "none" }}
            >
              Forgot password
            </Link>
          </Typography>
        </Box>
      </div>
    </>
  );
}
export default Login;
