import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ConfirmationModal from "../modals/ConfirmationModal";
import NotificationHelper from "../helpers/NotificationHelper";
import DeleteUserApi from "../../api/users/DeleteUserApi";
import { STATUS } from "../helpers/Constant";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import HardDeleteModal from "../modals/HardDeleteModal";
import EnableDisableUserApi from "../../api/users/EnableDisableUsersApi";
import { CustomNoRowsOverlay } from "../helpers/NoData";

function UsersTable(props) {
  const [page, setPage] = useState(1);
  const [openDisableUser, setOpenDisableUser] = useState(false);
  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const [userId, setUserId] = useState();
  const [selectUserStatus, setSelectUserStatus] = useState();
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [disableLoading, setDisableLoading] = useState(false);

  useEffect(() => {
    setPage(props.page);
  }, [props.page]);

  async function disableUsers() {
    setDisableLoading(true);
    const apiResponse = await EnableDisableUserApi(userId, selectUserStatus);

    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse.success) {
      setNotification({
        open: true,
        type: "success",
        message: apiResponse.message,
      });
      props.getUsers();
    }
    setDisableLoading(false);
    setUserId("");
    setOpenDisableUser(false);
  }

  async function deleteUser() {
    setLoading(true);
    const apiResponse = await DeleteUserApi(userId);

    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse.success) {
      setNotification({
        open: true,
        type: "success",
        message: apiResponse.message,
      });
      if (
        props.users?.length === 1
      ) {
        props.getUsers(page - 1);
      } else {
        props.getUsers(page);
      }
    }
    setLoading(false);
    setUserId("");
    setOpenDeleteUser(false);
  }

  return (
    <>
      <Typography component="h2" variant="h6" gutterBottom>
        Users
      </Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Seller</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Location</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Phone</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          {props.users?.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ justifyItems: "center" }}
                  colSpan={6}
                >
                  <CustomNoRowsOverlay message={"No User"} />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.users?.map((users) => (
                <TableRow key={users._id}>
                  <TableCell>{users.name || "-"}</TableCell>
                  <TableCell>{users.isSeller ? "Yes" : "No"}</TableCell>
                  <TableCell>{users.location?.postalCode || "-"}</TableCell>
                  <TableCell>{users.email}</TableCell>
                  <TableCell>{users.phoneNumber || "-"}</TableCell>
                  <TableCell>
                    <div className="flex justify-items-center gap-1">
                      {users.status === STATUS.Disabled ? (
                        <div
                          onClick={() => {
                            setUserId(users._id);
                            setSelectUserStatus(STATUS.Active);
                            setOpenDisableUser(true);
                          }}
                        >
                          <CheckCircleOutlinedIcon
                            style={{ fontSize: "20px" }}
                            className="text-green-500"
                          />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setUserId(users._id);
                            setSelectUserStatus(STATUS.Disabled);
                            setOpenDisableUser(true);
                          }}
                        >
                          <BlockOutlinedIcon
                            sx={{ fontSize: "20px", color: "#ff9800" }}
                          />
                        </div>
                      )}
                      <div
                        onClick={() => {
                          setUserId(users._id);
                          setOpenDeleteUser(true);
                        }}
                      >
                        <DeleteOutlinedIcon
                          sx={{ fontSize: "20px", color: "#f44336" }}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <NotificationHelper notification={notification} />
      <ConfirmationModal
        keepMounted
        title={
          selectUserStatus === STATUS.Disabled ? "Disable user" : "Enable user"
        }
        description={
          selectUserStatus === STATUS.Disabled
            ? "Are you sure want to disable this user?"
            : "Are you sure want to Enable this user?"
        }
        loading={disableLoading}
        open={openDisableUser}
        handleOk={() => disableUsers()}
        onClose={() => {
          setOpenDisableUser(false);
          setUserId("");
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      />
      <HardDeleteModal
        keepMounted
        title="Delete User"
        description="Are you sure want to delete this user?"
        open={openDeleteUser}
        loading={loading}
        handleOk={() => deleteUser()}
        onClose={() => {
          setOpenDeleteUser(false);
          setUserId("");
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      />
    </>
  );
}
export default UsersTable;
