import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import MainLoader from "../components/sub/MainLoader";

function OpenRoute() {
  const authState = useSelector((state) => state.authState.value);
  if (authState === "login") {
    return <Navigate to={"/website-layout"} replace />;
  } else if (authState === "logout") {
    return <Outlet />;
  }
  return <MainLoader />;
}

export default OpenRoute;
