import React, { useEffect, useState } from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ShopTable from "../../components/tables/ShopTable";
import GetShopTableApi from "../../api/shops/GetShopTableApi";
import { Badge, Button, Pagination, Stack } from "@mui/material";
import ShopFilterModal from "../../components/modals/filters/ShopFilterModal";
import GetFilteredShopTableApi from "../../api/shops/GetFilteredShopTableApi";
import RefreshIcon from "@mui/icons-material/Refresh";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function Shops() {
  const [shops, setShops] = useState([]);
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [filterModal, setFilterModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filterBy, setFilterBy] = useState();

  useEffect(() => {
    {
      filterStatus ? getFilteredShops(page) : getShops(page);
    }
  }, [page]);

  function handleChange(event, num) {
    changeTablePage(num);
  }

  async function getShops(num) {
    const apiResponse = await GetShopTableApi(num);

    if (apiResponse.success) {
      setShops(apiResponse.data?.shops);
      setData(apiResponse.data);
      setPage(num);
    }
  }

  async function getFilteredShops(event, num) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const apiResponse = await GetFilteredShopTableApi(
      data.get("location") || "",
      data.get("status") || "",
      num
    );
    if (apiResponse.success) {
      setShops(apiResponse.data?.shops);
      setFilterStatus(true);
      setFilterBy({
        location: data.get("location"),
        status: data.get("status"),
      });
      setData(apiResponse.data);
      setPage(num);
    }
  }

  function changeTablePage(num) {
    if (filterStatus) {
      getFilteredShops(filterBy, num);
    } else {
      getShops(num);
    }
  }

  function resetFilter() {
    getShops(1);
    setFilterStatus(false);
    setFilterBy("");
  }

  const right = (
    <>
      <div className="flex items-center gap-2">
        <div onClick={() => resetFilter()}>
          <BootstrapTooltip title="Refresh" placement="top">
            <Button
              sx={{
                width: "50px",
                minWidth: "unset",
              }}
              variant="contained"
              disabled={!filterStatus}
            >
              <RefreshIcon
                sx={{
                  fontSize: "25px",
                  color: "primary",
                }}
              />
            </Button>
          </BootstrapTooltip>
        </div>
        <div onClick={() => setFilterModal(true)}>
          <BootstrapTooltip title="Filter" placement="top">
            <Button
              sx={{
                width: "50px",
                minWidth: "unset",
              }}
              variant="contained"
            >
              {filterStatus ? (
                <Badge
                  color="secondary"
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                >
                  <FilterAltOutlinedIcon sx={{ fontSize: "25px" }} />
                </Badge>
              ) : (
                <FilterAltOutlinedIcon sx={{ fontSize: "25px" }} />
              )}
            </Button>
          </BootstrapTooltip>
        </div>
      </div>
    </>
  );
  const left = <div>Sellers / Shops</div>;

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          mt: 10,
          ml: 2,
          mr: 2,
          mb: 4,
        }}
      >
        <DashboardBreadcrumb left={left} right={right} />
        <ShopTable
          shops={shops}
          data={data}
          page={page}
          getShops={(num) => changeTablePage(num)}
        />
        {data?.totalCount > 10 && (
          <Stack alignItems="center" mt={2}>
            <Pagination
              count={Math.ceil(data?.totalCount / 10)}
              siblingCount={0}
              page={page}
              onChange={(event, num) => handleChange(event, num)}
              variant="outlined"
              color="primary"
              shape="rounded"
            />
          </Stack>
        )}
      </Box>
      <ShopFilterModal
        keepMounted
        open={filterModal}
        filterBy={filterBy}
        filterStatus={filterStatus}
        handleOk={(num) => {
          getFilteredShops(num);
        }}
        onClose={() => setFilterModal(false)}
      />
    </>
  );
}

export default Shops;
