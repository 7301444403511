import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { UploadFileApi } from "../../api/UploadFileApi";
import { userDetails } from "../../features/UserSlice";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
} from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import UpdateAdminProfileApi from "../../api/admin/UpdateAdminProfileApi";
import NotificationHelper from "../../components/helpers/NotificationHelper";
import ImageCropper from "../../components/modals/ImageCropper";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";

function AdminProfile() {
  const [submit, setSubmit] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });
  const userState = useSelector((state) => state.userState.value);
  const dispatch = useDispatch();
  const [imageCropper, setImageCropper] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [croppedImageFile, setCroppedImageFile] = useState("");
  const [imageError, setImageError] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [fullName, setFullName] = useState(userState.fullName);
  const [email, setEmail] = useState(userState.email);
  const [phoneNumber, setPhoneNumber] = useState(userState.phoneNumber);
  const [initialImageUrl, setInitialImageUrl] = useState(userState.imageUrl);
  const [countryCode, setCountryCode] = useState(
    userState.phoneNumber?.toString().startsWith("+1") ? "+1" : "+94"
  );

  useEffect(() => {
    if (croppedImageFile) {
      async function blobToFile(blobUrl, fileName) {
        const response = await fetch(blobUrl);
        const blob = await response.blob();
        return new File([blob], fileName, { type: blob.type });
      }

      blobToFile(croppedImageFile, "filename.jpg").then((fileImage) => {
        upload(fileImage, userState._id);
      });
    }
  }, [croppedImageFile]);

  function handleCancel() {
    setFullName(userState.fullName);
    setEmail(userState.email);
    {
      userState.phoneNumber
        ? setPhoneNumber(userState.phoneNumber)
        : setPhoneNumber("");
    }
    {
      userState.phoneNumber
        ? setCountryCode(
            userState.phoneNumber?.toString().startsWith("+1") ? "+1" : "+94"
          )
        : setCountryCode("+94");
    }
    setCroppedImageFile("");
    setImageUrl("");
    setInitialImageUrl(userState.imageUrl);
    setNameError("");
    setImageError(false);
    setEmailError("");
    setPhoneNumberError("");
  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    event.target.value = null;
    setImageFile(selectedFile);
  };

  async function upload(file, name) {
    setSubmit(true);

    const apiResponse = await UploadFileApi(file, name);

    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse) {
      setImageUrl(apiResponse.Location);
      setImageError(false);
    }
    setSubmit(false);
  }

  async function handleSubmit(event) {
    setSubmit(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!data.get("fullName")) {
      setNameError("Fullname can't be empty!");
      setSubmit(false);
    }

    if (!(imageUrl || initialImageUrl)) {
      setImageError(true);
      setSubmit(false);
    }
    if (!data.get("email")) {
      setEmailError("Email can't be empty!");
      setSubmit(false);
    }
    if (!data.get("phoneNumber")) {
      setPhoneNumberError("Phone Number can't be empty!");
      setSubmit(false);
    }
    if (
      data.get("countryCode") === "+1" &&
      data.get("phoneNumber").length !== 9
    ) {
      setPhoneNumberError(
        "This number is invalid for this country as it should have 9 digits."
      );
      setSubmit(false);
    }
    if (
      data.get("countryCode") === "+94" &&
      !(
        data.get("phoneNumber").toString().startsWith("0") &&
        data.get("phoneNumber").length === 10
      ) &&
      data.get("phoneNumber").length !== 9
    ) {
      setPhoneNumberError(
        "This number is invalid for this country as it should have 9 digits."
      );
      setSubmit(false);
    }
    if (
      (imageUrl || initialImageUrl) &&
      data.get("fullName") &&
      data.get("email") &&
      data.get("phoneNumber") &&
      ((data.get("countryCode") === "+1" &&
        data.get("phoneNumber").length === 9) ||
        (data.get("countryCode") === "+94" &&
          ((data.get("phoneNumber").toString().startsWith("0") &&
            data.get("phoneNumber").length === 10) ||
            data.get("phoneNumber").length === 9)))
    ) {
      setSubmit(true);

      let phone = data.get("phoneNumber");
      if (phone.charAt(0) === "0") {
        phone = phone.slice(1);
      }
      const apiResponse = await UpdateAdminProfileApi(
        data.get("fullName"),
        data.get("email"),
        imageUrl ? imageUrl : initialImageUrl,
        data.get("countryCode") + phone
      );

      if (apiResponse.statusCode) {
        setNotification({
          open: true,
          type: "error",
          message: apiResponse.message,
        });
      } else if (apiResponse.success) {
        setNotification({
          open: true,
          type: "success",
          message: apiResponse.message,
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));

        dispatch(userDetails(apiResponse));
      }
    }
    setSubmit(false);
  }

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          mt: 10,
          ml: 2,
          mr: 2,
          mb: 4,
        }}
      >
        <Typography component="h2" variant="h6">
          Your Profile
        </Typography>
        <Box sx={{ mt: 2 }} component="form" onSubmit={handleSubmit}>
          <div
            className={
              "h-36 w-36 flex justify-center items-center mb-2 relative " +
              (!croppedImageFile && "bg-gray-200")
            }
          >
            {(initialImageUrl || imageUrl || croppedImageFile) && (
              <img
                value={initialImageUrl}
                onChange={(event) => {
                  setInitialImageUrl(event.target.value);
                  setImageError("");
                }}
                src={
                  croppedImageFile
                    ? croppedImageFile
                    : imageUrl
                    ? imageUrl
                    : initialImageUrl
                }
                height={144}
                width={144}
                alt="updateProfile"
                className="absolute top-0 left-0 w-full h-full"
              />
            )}
            <Stack direction="row" spacing={1}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={() => setImageCropper(true)}
              >
                <input
                  accept="image/*"
                  hidden
                  type="file"
                  onChange={handleFileChange}
                />
                {croppedImageFile || imageUrl || initialImageUrl ? (
                  <SwapHorizIcon />
                ) : (
                  <AddIcon />
                )}
              </IconButton>
            </Stack>
          </div>
          {imageError ? (
            <FormHelperText sx={{ ml: 2, color: "#d32f2f" }}>
              Admin profile image can't be empty!
            </FormHelperText>
          ) : (
            <FormHelperText sx={{ ml: 1, color: "#d32f2f" }}> </FormHelperText>
          )}

          <div>
            <TextField
              sx={{ mt: 1, width: "40ch" }}
              size="small"
              name="fullName"
              value={fullName}
              label="Full name"
              variant="outlined"
              onChange={(event) => {
                setFullName(event.target.value);
                setNameError("");
              }}
              error={nameError ? true : false}
              helperText={nameError ? nameError : " "}
            />
          </div>

          <div>
            <TextField
              sx={{ mt: 1, width: "40ch" }}
              size="small"
              name="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
                setEmailError("");
              }}
              error={emailError ? true : false}
              helperText={emailError ? emailError : " "}
              label="Email"
              variant="outlined"
            />
          </div>

          <TextField
            id="outlined-basic"
            sx={{ mt: 1, width: "40ch" }}
            size="small"
            label="Phone No"
            name="phoneNumber"
            type="tel"
            inputProps={{ inputMode: "numeric" }}
            onKeyDown={(event) => {
              const allowedKeys = ["Backspace", "Delete", "Enter"];
              if (
                !/^[0-9]+$/.test(event.key) &&
                !/^Arrow(Left|Right|Up|Down)$/.test(event.key) &&
                !allowedKeys.includes(event.key)
              ) {
                event.preventDefault();
              }
            }}
            value={
              phoneNumber?.toString().startsWith("+94")
                ? phoneNumber?.toString().slice(3)
                : phoneNumber?.toString().startsWith("+1")
                ? phoneNumber?.toString().slice(2)
                : phoneNumber?.toString()
            }
            onChange={(event) => {
              setPhoneNumber(event.target.value);
              setPhoneNumberError("");
            }}
            error={phoneNumberError ? true : false}
            helperText={phoneNumberError ? phoneNumberError : " "}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FormControl>
                    <Select
                      id="outlined-basic"
                      size="small"
                      label="Phone No"
                      sx={{
                        marginLeft: -1.8,
                        width: "90px",
                      }}
                      name="countryCode"
                      value={countryCode}
                      onChange={(event) => {
                        setCountryCode(event.target.value);
                        setPhoneNumberError("");
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="+94">+94</MenuItem>
                      <MenuItem value="+1">+1</MenuItem>
                    </Select>
                  </FormControl>
                </InputAdornment>
              ),
            }}
          />

          <Stack direction="row" sx={{ mt: 1 }} spacing={2}>
            <LoadingButton
              loading={submit}
              loadingPosition="start"
              variant="contained"
              type="submit"
              size="small"
              fullWidth
              sx={{ width: "160px", textTransform: "none" }}
            >
              Save
            </LoadingButton>
            <Button
              sx={{ width: "160px", textTransform: "none" }}
              variant="outlined"
              size="small"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
      <NotificationHelper notification={notification} />
      <ImageCropper
        visible={imageCropper}
        handleCancel={() => setImageCropper(false)}
        imageFile={imageFile}
        handleClearImage={() => setImageFile(null)}
        setCroppedImageFile={(file) => setCroppedImageFile(file)}
      />
    </>
  );
}

export default AdminProfile;
