import { createSlice } from "@reduxjs/toolkit";

const firstLoginSlice = createSlice({
  name: "login",
  initialState: { value: false },
  reducers: {
    firstLogin: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { firstLogin } = firstLoginSlice.actions;
export default firstLoginSlice.reducer;
