import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Avatar from "@mui/material/Avatar";
import ResetPasswordApi from "../../api/auth/ResetPasswordApi";
import { FormHelperText } from "@mui/material";
import NotificationHelper from "../../components/helpers/NotificationHelper";
import { LoadingButton } from "@mui/lab";

function ResetPassword() {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [form, setForm] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const location = useLocation();
  let navigate = useNavigate();

  function strongPassword(password) {
    let strength = false;
    const passwordArray = password.split("");
    let lowercase = false;
    let uppercase = false;
    let number = false;
    let length = false;
    // Validate lowercase letters
    const lowerCaseLetters = /[a-z]/g;
    passwordArray.forEach((pass) => {
      if (pass.match(lowerCaseLetters)) {
        lowercase = true;
      }
    });
    // Validate capital letters
    const upperCaseLetters = /[A-Z]/g;
    passwordArray.forEach((pass) => {
      if (pass.match(upperCaseLetters)) {
        uppercase = true;
      }
    });
    // Validate numbers
    const numbers = /[0-9]/g;
    passwordArray.forEach((pass) => {
      if (pass.match(numbers)) {
        number = true;
      }
    });
    // Validate length
    if (passwordArray.length >= 8) {
      length = true;
    }
    if (lowercase && uppercase && number && length) {
      strength = true;
    }

    return strength;
  }

  async function handleSubmit(event) {
    setSubmit(true);

    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const newPassword = data.get("newPassword");
    const confirmPassword = data.get("confirmPassword");

    if (!newPassword) {
      setForm((prevForm) => ({
        ...prevForm,
        newPassword: {
          value: prevForm.newPassword.value,
          error: "New password cannot be empty!",
        },
      }));
    } else if (!strongPassword(data.get("newPassword"))) {
      setForm((prevForm) => ({
        ...prevForm,
        newPassword: {
          value: prevForm.newPassword.value,
          error: "Enter a strong password!",
        },
      }));
    }
    if (!confirmPassword) {
      setForm((prevForm) => ({
        ...prevForm,
        confirmPassword: {
          value: prevForm.confirmPassword.value,
          error: "Confirm password cannot be empty!",
        },
      }));
    }

    if (data.get("newPassword") !== data.get("confirmPassword")) {
      setForm((prevForm) => ({
        ...prevForm,
        confirmPassword: {
          value: prevForm.confirmPassword.value,
          error: "Password doesn't match!",
        },
      }));
    }
    if (
      newPassword &&
      confirmPassword &&
      strongPassword(newPassword) &&
      strongPassword(confirmPassword) &&
      newPassword === confirmPassword
    ) {
      const apiResponse = await ResetPasswordApi(
        data.get("newPassword"),
        data.get("confirmPassword"),
        location.state
      );
      if (apiResponse.statusCode) {
        setNotification({
          open: true,
          type: "error",
          message: apiResponse.message,
        });
      } else if (apiResponse.success) {
        setNotification({
          open: true,
          type: "success",
          message: apiResponse.message,
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate(`/login`, {
          state: apiResponse.data?.customerAccessToken,
        });
      }
    }
    setSubmit(false);
  }

  const handleClickShowNewPassword = () => {
    setShowNewPassword((show) => !show);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <div className="h-screen flex w-full  justify-center ">
        <Box
          sx={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "400px",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOpenIcon />
          </Avatar>
          <Box component="form" flexDirection="column" onSubmit={handleSubmit}>
            <Typography textAlign="center" variant="h6">
              {" "}
              Reset Password
            </Typography>

            <FormControl variant="outlined" fullWidth sx={{ mb: 2, mt: 1 }}>
              <InputLabel
                error={form.newPassword.error ? true : false}
                htmlFor="outlined-adornment-password"
                size="small"
              >
                New Password
              </InputLabel>
              <OutlinedInput
                error={form.newPassword.error ? true : false}
                id="outlined-adornment-password"
                fullWidth
                name="newPassword"
                label="New Password"
                type={showNewPassword ? "text" : "password"}
                size="small"
                onChange={(e) =>
                  setForm((prevForm) => ({
                    ...prevForm,
                    newPassword: {
                      value: e.target.value,
                      error: "",
                    },
                  }))
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                sx={{ mb: -2 }}
                error={form.newPassword.error ? true : false}
                id="outlined-weight-helper-text"
              >
                {form.newPassword.error ? form.newPassword.error : " "}
              </FormHelperText>
            </FormControl>

            <FormControl variant="outlined" fullWidth sx={{ mb: 2, mt: 1 }}>
              <InputLabel
                error={form.confirmPassword.error ? true : false}
                htmlFor="outlined-adornment-password"
                size="small"
              >
                Confirm Password
              </InputLabel>
              <OutlinedInput
                error={form.confirmPassword.error ? true : false}
                id="outlined-adornment-password"
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                size="small"
                onChange={(e) =>
                  setForm((prevForm) => ({
                    ...prevForm,
                    confirmPassword: {
                      value: e.target.value,
                      error: "",
                    },
                  }))
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                sx={{ mb: -2 }}
                error={form.confirmPassword.error ? true : false}
                id="outlined-weight-helper-text"
              >
                {form.confirmPassword.error ? form.confirmPassword.error : " "}
              </FormHelperText>
            </FormControl>

            <div className="flex  justify-center">
              <LoadingButton
                loading={submit}
                loadingPosition="start"
                variant="contained"
                type="submit"
                fullWidth
                sx={{ mt: 1, mb: 2, textTransform: "initial" }}
              >
                Update Password
              </LoadingButton>
            </div>
          </Box>
        </Box>
      </div>
      <NotificationHelper notification={notification} />
    </>
  );
}

export default ResetPassword;
