import {
  Badge,
  Box,
  Button,
  Pagination,
  Stack,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import styled from "@emotion/styled";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AccountsTable from "../../components/tables/AccountsTable";
import RefreshIcon from "@mui/icons-material/Refresh";
import AccountsFilterModal from "../../components/modals/filters/AccountsFilterModal";
import { GetPayablesApi } from "../../api/accounts/GetPayablesApi";
import FilterPayablesApi from "../../api/accounts/FilterPayablesApi";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function Accounts() {
  const [filterModal, setFilterModal] = useState(false);
  const [payables, setPayables] = useState();
  const [filterStatus, setFilterStatus] = useState(false);
  const [filterBy, setFilterBy] = useState();

  useEffect(() => {
    getPayables(1);
  }, []);

  async function getPayables(page) {
    const apiResponse = await GetPayablesApi(page, new Date());
    if (apiResponse.success) {
      setPayables(apiResponse.data);
    }
  }

  async function getFilteredPayables(values, num) {
    const apiResponse = await FilterPayablesApi(values, num);
    if (apiResponse.success) {
      setPayables(apiResponse.data);
      setFilterStatus(true);
      setFilterBy(values);
    }
  }

  function handlePageChange(event, num) {
    if (filterStatus) {
      getFilteredPayables(filterBy, num);
    } else {
      getPayables(num);
    }
  }

  function resetFilter() {
    getPayables(1);
    setFilterStatus(false);
    setFilterBy();
  }

  const right = (
    <>
      <div className="flex items-center gap-2">
        <div>
          <BootstrapTooltip title="Refresh" placement="top">
            <Button
              onClick={() => resetFilter()}
              sx={{
                width: "50px",
                minWidth: "unset",
              }}
              variant="contained"
            >
              <RefreshIcon
                sx={{
                  fontSize: "25px",
                  color: "primary",
                }}
              />
            </Button>
          </BootstrapTooltip>
        </div>
        <div>
          <BootstrapTooltip title="Filter" placement="top">
            <Button
              onClick={() => setFilterModal(true)}
              sx={{
                width: "50px",
                minWidth: "unset",
              }}
              variant="contained"
            >
              {filterStatus ? (
                <Badge
                  color="secondary"
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                >
                  <FilterAltOutlinedIcon sx={{ fontSize: "25px" }} />
                </Badge>
              ) : (
                <FilterAltOutlinedIcon sx={{ fontSize: "25px" }} />
              )}
            </Button>
          </BootstrapTooltip>
        </div>
      </div>
    </>
  );
  const left = <div>Accounts</div>;
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        mt: 10,
        ml: 2,
        mr: 2,
        mb: 4,
      }}
    >
      <DashboardBreadcrumb left={left} right={right} />
      <AccountsTable payables={payables?.accounts} />
      {payables?.totalCount > 10 && (
        <Stack alignItems="center" mt={2}>
          <Pagination
            count={Math.ceil(payables?.totalCount / 10)}
            siblingCount={0}
            onChange={handlePageChange}
            variant="outlined"
            color="primary"
            shape="rounded"
          />
        </Stack>
      )}
      <AccountsFilterModal
        open={filterModal}
        filterBy={filterBy}
        handleOk={(values) => {
          getFilteredPayables(values, 1);
        }}
        onClose={() => setFilterModal(false)}
      />
    </Box>
  );
}

export default Accounts;
