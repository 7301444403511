import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { UploadFileApi } from "../../../api/UploadFileApi";
import AddSubCategoryApi from "../../../api/category/AddSubCategoryApi";
import { LoadingButton } from "@mui/lab";
import NotificationHelper from "../../helpers/NotificationHelper";
import { FormHelperText, IconButton } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import AddIcon from "@mui/icons-material/Add";
import ImageCropper from "../ImageCropper";
import { randomId } from "../../helpers/RandomId";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function AddSubCategoryModal(props) {
  const [imageUrl, setImageUrl] = useState("");
  const [submit, setSubmit] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [imageCropper, setImageCropper] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [croppedImageFile, setCroppedImageFile] = useState("");
  const [name, setName] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [imageError, setImageError] = useState("");

  useEffect(() => {
    if (croppedImageFile) {
      async function blobToFile(blobUrl, fileName) {
        const response = await fetch(blobUrl);
        const blob = await response.blob();
        return new File([blob], fileName, { type: blob.type });
      }

      blobToFile(croppedImageFile, "filename.jpg").then((fileImage) => {
        upload(fileImage, randomId);
      });
    }
  }, [croppedImageFile]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    event.target.value = null;
    setImageFile(selectedFile);
    setImageError("");
  };

  async function upload(file, name) {
    setSubmit(true);
    const apiResponse = await UploadFileApi(file, name);

    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse) {
      setImageUrl(apiResponse.Location);
    }
    setSubmit(false);
  }

  function onCancel() {
    setName("");
    setImageUrl("");
    setCroppedImageFile("");
    setCategoryError("");
    setImageError("");
    props.onClose();
  }

  async function handleSubmit(event) {
    setSubmit(true);

    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!data.get("name")) {
      setCategoryError("Category cannot be empty!");
      setSubmit(false);
    }
    if (!imageUrl) {
      setImageError(true);
      setSubmit(false);
    }
    if (data.get("name") && imageUrl) {
      const apiResponse = await AddSubCategoryApi(
        data.get("name"),
        imageUrl,
        props.selectedRow._id
      );
      if (apiResponse.statusCode) {
        setNotification({
          open: true,
          type: "error",
          message: apiResponse.message,
        });
      } else if (apiResponse.success) {
        setNotification({
          open: true,
          type: "success",
          message: apiResponse.message,
        });
      }
      props.handleOk();
      onCancel();
    }
    setSubmit(false);
  }

  return (
    <>
      <Modal
        keepMounted
        open={props.openSubCategory}
        onClose={() => onCancel()}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Add Category
            </Typography>
            <CloseIcon onClick={() => onCancel()} />
          </Stack>
          <Typography sx={{ mt: 2 }}>
            Subcategory of : {props.selectedRow?.name}
          </Typography>
          <TextField
            sx={{ mt: 2 }}
            name="name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setCategoryError("");
            }}
            error={categoryError ? true : false}
            helperText={categoryError ? categoryError : " "}
            fullWidth
            size="small"
            label="Category Name"
            variant="outlined"
          />
          <Typography>Category Image:</Typography>

          <div
            className={
              "mt-2 h-36 w-36 flex justify-center items-center mb-8 relative " +
              (!croppedImageFile && "bg-gray-200")
            }
          >
            {(imageUrl || croppedImageFile) && (
              <img
                src={croppedImageFile ? croppedImageFile : imageUrl}
                alt="addSubCategory"
                className="absolute top-0 left-0 w-full h-full"
              />
            )}
            <Stack direction="row" spacing={1}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={() => {
                  setImageCropper(true);
                }}
              >
                <input
                  accept="image/*"
                  hidden
                  type="file"
                  onChange={handleFileChange}
                />
                {croppedImageFile || imageUrl ? <SwapHorizIcon /> : <AddIcon />}
              </IconButton>
            </Stack>
          </div>
          {imageError ? (
            <FormHelperText sx={{ mt: -3, ml: 1, color: "#d32f2f" }}>
              Category image can't be empty!
            </FormHelperText>
          ) : (
            <FormHelperText sx={{ mt: -3, ml: 1, color: "#d32f2f" }}>
              {" "}
            </FormHelperText>
          )}

          <Stack
            sx={{ mt: 2 }}
            justifyContent="end"
            direction="row"
            spacing={1}
          >
            <Button
              sx={{ textTransform: "none" }}
              variant="outlined"
              size="small"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
            <LoadingButton
              size="large"
              loading={submit}
              loadingPosition="start"
              variant="contained"
              type="submit"
              fullWidth
              sx={{
                mt: 2,
                textTransform: "none",
                width: 80,
              }}
            >
              <Typography variant="body1">Add</Typography>
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
      <NotificationHelper notification={notification} />
      <ImageCropper
        visible={imageCropper}
        handleCancel={() => setImageCropper(false)}
        imageFile={imageFile}
        handleClearImage={() => setImageFile(null)}
        setCroppedImageFile={(file) => setCroppedImageFile(file)}
      />
    </>
  );
}

export default AddSubCategoryModal;
