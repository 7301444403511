import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  FormControl,
  InputLabel,
  Slider,
  TextField,
} from "@mui/material";
import { STATUS } from "../../helpers/Constant";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function BoostPlanFilterModal(props) {
  const [status, setStatus] = useState("");
  const [submit, setSubmit] = useState(false);
  const [name, setName] = useState("");
  const [boostNo, setBoostNo] = useState("");
  const [priceValues, setPriceValues] = useState([100, 300]);

  useEffect(() => {
    setName(props.filterBy?.name || "");
    setPriceValues([
      props.filterBy?.startPrice || 100,
      props.filterBy?.endPrice || 300,
    ]);
    setBoostNo(props.filterBy?.noOfBoost || "");
    setStatus(props.filterBy?.status || "");
  }, [
    props.filterBy?.name,
    props.filterBy?.startPrice,
    props.filterBy?.endPrice,
    props.filterBy?.noOfBoost,
    props.filterBy?.status,
  ]);

  function onCancel() {
    setSubmit(false);
    if (props.filterStatus) {
      setName(name);
      setPriceValues(priceValues);
      setBoostNo(boostNo);
      setStatus(status);
    } else {
      setName("");
      setPriceValues([100, 300]);
      setBoostNo("");
      setStatus("");
    }
    props.onClose();
  }

  const handleSubmit = (num) => {
    setSubmit(true);
    props.handleOk(num);
    onCancel();
  };

  const handleChange = (event, newValue) => {
    props.priceRange(newValue);
    setPriceValues(newValue);
  };

  const marks = [
    {
      value: 0,
      label: "$0",
    },
    {
      value: 100,
      label: "$100",
    },
    {
      value: 300,
      label: "$300",
    },
    {
      value: 500,
      label: "$500",
    },
  ];

  return (
    <Modal
      keepMounted
      open={props.open}
      onClose={() => onCancel()}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style} component="form" onSubmit={handleSubmit}>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">

            Boost Filter

          </Typography>
          <CloseIcon onClick={() => onCancel()} />
        </Stack>

        <Stack sx={{ mt: 2 }} direction="raw" columnGap={2}>
          <TextField
            fullWidth
            name="name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            size="small"
            label="Boost plan name"
            variant="outlined"
          />

          <FormControl size="small" fullWidth>
            <InputLabel id="demo-simple-select-label">No of boost</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="noOfBoost"
              value={boostNo}
              label="No of boost"
              onChange={(event) => setBoostNo(event.target.value)}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        <FormControl sx={{ mt: 2 }} size="small" fullWidth>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="status"
            value={status}
            label="Status"
            onChange={(event) => setStatus(event.target.value)}
          >
            <MenuItem value={STATUS.Active}>Active</MenuItem>
            <MenuItem value={STATUS.Disabled}>Disabled</MenuItem>
          </Select>
        </FormControl>

        <Typography sx={{ mt: 2 }}>Price Range</Typography>
        <Stack sx={{ mt: 2, ml: 2, mr: 2 }}>
          <Slider
            aria-label="Range Slider"
            name="priceRange"
            value={priceValues}
            onChange={handleChange}
            valueLabelDisplay="auto"
            min={10}
            max={500}
            marks={marks}
          />
        </Stack>

        <Stack sx={{ mt: 2 }} alignItems="flex-start" direction="row" gap={2}>
          <Typography variant="body2">Selected Price Range:</Typography>
          <Typography variant="body2">
            {`$ ${priceValues[0]} - $ ${priceValues[1]}`}
          </Typography>
        </Stack>

        <Stack sx={{ mt: 2 }} justifyContent="end" direction="row" spacing={1}>
          <Button
            variant="outlined"
            sx={{ width: 100, textTransform: "none" }}
            size="small"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
          <LoadingButton
            size="large"
            loadingPosition="start"
            loading={submit}
            variant="contained"
            type="submit"
            fullWidth
            sx={{
              mt: 2,
              textTransform: "none",
              width: 100,
            }}
          >
            <Typography variant="body1">Filter</Typography>
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
}

export default BoostPlanFilterModal;
