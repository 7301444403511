import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Avatar from "@mui/material/Avatar";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { userDetails } from "../../features/UserSlice";
import { useDispatch } from "react-redux";
import NotificationHelper from "../../components/helpers/NotificationHelper";
import { LoadingButton } from "@mui/lab";
import CreateNewPasswordApi from "../../api/auth/CreateNewPasswordApi";
import { logout } from "../../features/AuthSlice";
import Logout from "@mui/icons-material/Logout";

function CreateNewPassword() {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  let navigate = useNavigate();
  let dispatch = useDispatch();

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword((show) => !show);
  };
  const handleClickShowNewPassword = () => {
    setShowNewPassword((show) => !show);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function isStrongPassword(password) {
    let strength = false;
    const passwordArray = password.split("");
    let lowercase = false;
    let uppercase = false;
    let number = false;
    let length = false;
    // Validate lowercase letters
    const lowerCaseLetters = /[a-z]/g;
    passwordArray.forEach((pass) => {
      if (pass.match(lowerCaseLetters)) {
        lowercase = true;
      }
    });
    // Validate capital letters
    const upperCaseLetters = /[A-Z]/g;
    passwordArray.forEach((pass) => {
      if (pass.match(upperCaseLetters)) {
        uppercase = true;
      }
    });
    // Validate numbers
    const numbers = /[0-9]/g;
    passwordArray.forEach((pass) => {
      if (pass.match(numbers)) {
        number = true;
      }
    });
    // Validate length
    if (passwordArray.length >= 8) {
      length = true;
    }
    if (lowercase && uppercase && number && length) {
      strength = true;
    }

    return strength;
  }

  async function handleSubmit(event) {
    setSubmit(true);

    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const currentPassword = data.get("currentPassword");
    const newPassword = data.get("newPassword");
    const confirmPassword = data.get("confirmPassword");

    if (newPassword && !isStrongPassword(newPassword)) {
      setNewPasswordError("Enter a strong password!");
      setNotification({
        open: true,
        type: "error",
        message:
          "Your password must be at least 8 characters long, contain at least one number, one uppercase letter and one lowercase letter.",
      });
      setSubmit(false);
    } else if (!currentPassword || !newPassword || !confirmPassword) {
      if (!currentPassword) {
        setCurrentPasswordError("Current password cannot be empty!");
      }
      if (!newPassword) {
        setNewPasswordError("New password cannot be empty!");
      }
      if (!confirmPassword) {
        setConfirmPasswordError("Confirm password cannot be empty!");
      }
      setSubmit(false);
    }

    if (
      isStrongPassword(newPassword) &&
      confirmPassword &&
      newPassword !== confirmPassword
    ) {
      setConfirmPasswordError("Password did not match the new password!");
      setSubmit(false);
    }
    if (
      currentPassword &&
      newPassword &&
      confirmPassword &&
      isStrongPassword(newPassword) &&
      isStrongPassword(confirmPassword) &&
      newPassword === confirmPassword
    ) {
      const apiResponse = await CreateNewPasswordApi(
        currentPassword,
        newPassword,
        confirmPassword
      );
      if (apiResponse.message === "Current password is incorrect") {
        setCurrentPasswordError("Current password is incorrect");
      } else if (apiResponse.statusCode) {
        setNotification({
          open: true,
          type: "error",
          message: apiResponse.message,
        });
      } else if (apiResponse.success) {
        setNotification({
          open: true,
          type: "success",
          message: apiResponse.message,
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));

        dispatch(userDetails(apiResponse));
        if (apiResponse.firstLogin) {
          navigate("/create-newpassword");
        } else {
          navigate("/personal-details");
        }
      }
    }
    setSubmit(false);
  }

  function logoutNow() {
    localStorage.setItem("authToken", "");
    localStorage.setItem("authUser", "");
    dispatch(logout());
    dispatch(userDetails({}));
    navigate("/login");
  }

  return (
    <>
      <div style={{ position: "absolute", top: 10, right: 10 }}>
        <Button onClick={() => logoutNow()}>
          <Logout fontSize="small" />
          <Stack sx={{ ml: 1, textTransform: "none" }}>Logout</Stack>
        </Button>
      </div>
      <div className="h-screen flex w-full  justify-center ">
        <Box
          sx={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "400px",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOpenIcon />
          </Avatar>
          <Typography textAlign="center" variant="h6">
            Create New Password
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate>
            <FormControl variant="outlined" fullWidth sx={{ mb: 2, mt: 1 }}>
              <InputLabel
                error={currentPasswordError ? true : false}
                htmlFor="outlined-adornment-password"
                size="small"
              >
                Current Password
              </InputLabel>
              <OutlinedInput
                error={currentPasswordError ? true : false}
                id="outlined-adornment-password"
                fullWidth
                name="currentPassword"
                label="Current Password"
                type={showCurrentPassword ? "text" : "password"}
                size="small"
                onChange={() => {
                  setCurrentPasswordError("");
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCurrentPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                sx={{ mb: -2 }}
                error={currentPasswordError ? true : false}
                id="outlined-weight-helper-text"
              >
                {currentPasswordError ? currentPasswordError : " "}
              </FormHelperText>
            </FormControl>

            <FormControl variant="outlined" fullWidth sx={{ mb: 2, mt: 1 }}>
              <InputLabel
                error={newPasswordError ? true : false}
                htmlFor="outlined-adornment-password"
                size="small"
              >
                New Password
              </InputLabel>
              <OutlinedInput
                error={newPasswordError ? true : false}
                id="outlined-adornment-password"
                fullWidth
                name="newPassword"
                label="New Password"
                type={showNewPassword ? "text" : "password"}
                size="small"
                onChange={() => {
                  setNewPasswordError("");
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                sx={{ mb: -2 }}
                error={newPasswordError ? true : false}
                id="outlined-weight-helper-text"
              >
                {newPasswordError ? newPasswordError : " "}
              </FormHelperText>
            </FormControl>

            <FormControl variant="outlined" fullWidth sx={{ mb: 2, mt: 1 }}>
              <InputLabel
                error={confirmPasswordError ? true : false}
                htmlFor="outlined-adornment-password"
                size="small"
              >
                Confirm Password
              </InputLabel>
              <OutlinedInput
                error={confirmPasswordError ? true : false}
                id="outlined-adornment-password"
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                size="small"
                onChange={() => {
                  setConfirmPasswordError("");
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                sx={{ mb: -2 }}
                error={confirmPasswordError ? true : false}
                id="outlined-weight-helper-text"
              >
                {confirmPasswordError ? confirmPasswordError : " "}
              </FormHelperText>
            </FormControl>

            <LoadingButton
              loading={submit}
              loadingPosition="start"
              variant="contained"
              type="submit"
              fullWidth
              sx={{ mb: 2, textTransform: "initial" }}
            >
              Update Password
            </LoadingButton>
          </Box>
        </Box>
      </div>
      <NotificationHelper notification={notification} />
    </>
  );
}

export default CreateNewPassword;
