import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ConfirmationModal from "../modals/ConfirmationModal";
import { STATUS } from "../helpers/Constant";
import DisableEnableAdminApi from "../../api/admin/DisableEnableAdminApi";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import NotificationHelper from "../helpers/NotificationHelper";
import DeleteAdminApi from "../../api/admin/DeleteAdminApi";
import HardDeleteModal from "../modals/HardDeleteModal";
import { CustomNoRowsOverlay } from "../helpers/NoData";

function AdminTable(props) {
  const [page, setPage] = useState(1);
  const [openDisableAdmin, setOpenDisableAdmin] = useState(false);
  const [adminId, setAdminId] = useState();
  const [selectAdminStatus, setSelectAdminStatus] = useState();
  const [openDeleteAdmin, setOpenDeleteAdmin] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [disableLoading, setDisableLoading] = useState(false);

  useEffect(() => {
    setPage(props.page);
  }, [props.page]);

  async function disableAdmin() {
    setDisableLoading(true);
    const apiResponse = await DisableEnableAdminApi(adminId, selectAdminStatus);

    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse.success) {
      setNotification({
        open: true,
        type: "success",
        message: apiResponse.message,
      });
      props.getAdmin();
    }
    setDisableLoading(false);
    setAdminId("");
    setOpenDisableAdmin(false);
  }

  async function deleteAdmin() {
    setLoading(true);
    const apiResponse = await DeleteAdminApi(adminId);

    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse.success) {
      setNotification({
        open: true,
        type: "success",
        message: apiResponse.message,
      });
      if (
        props.admins?.length === 1
      ) {
        props.getAdmin(page - 1);
      } else {
        props.getAdmin(page);
      }
    }
    setPage(page);
    setLoading(false);
    setAdminId("");
    setOpenDeleteAdmin(false);
  }

  return (
    <>
      <Typography component="h2" variant="h6" gutterBottom>
        Admin
      </Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Phone No</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>status</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          {props.admins?.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  <CustomNoRowsOverlay message={"No Admin"} />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.admins?.map((admin) => (
                <TableRow key={admin._id}>
                  <TableCell>{admin.name || "-"}</TableCell>
                  <TableCell>{admin.email || "-"}</TableCell>
                  <TableCell>{admin.phoneNumber || "-"}</TableCell>
                  <TableCell>
                    {admin.status === STATUS.Active ? "Active" : "Disabled"}
                  </TableCell>
                  <TableCell>
                    <div className="flex justify-items-center gap-1">
                      {admin.status === STATUS.Disabled ? (
                        <div
                          onClick={() => {
                            setAdminId(admin._id);
                            setSelectAdminStatus(STATUS.Active);
                            setOpenDisableAdmin(true);
                          }}
                        >
                          <CheckCircleOutlinedIcon
                            style={{ fontSize: "20px" }}
                            className="text-green-500"
                          />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setAdminId(admin._id);
                            setSelectAdminStatus(STATUS.Disabled);
                            setOpenDisableAdmin(true);
                          }}
                        >
                          <BlockOutlinedIcon
                            style={{ fontSize: "20px" }}
                            className="text-orange-500"
                          />
                        </div>
                      )}
                      <div
                        onClick={() => {
                          setAdminId(admin._id);
                          setOpenDeleteAdmin(true);
                        }}
                      >
                        <DeleteOutlinedIcon
                          style={{ fontSize: "20px" }}
                          className="text-red-500"
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <NotificationHelper notification={notification} />
      <ConfirmationModal
        keepMounted
        title={
          selectAdminStatus === STATUS.Disabled
            ? "Disable Admin"
            : "Enable Admin"
        }
        description={
          selectAdminStatus === STATUS.Disabled
            ? "Are you sure want to disable this admin?"
            : "Are you sure want to Enable this admin?"
        }
        loading={disableLoading}
        open={openDisableAdmin}
        handleOk={() => disableAdmin()}
        onClose={() => {
          setOpenDisableAdmin(false);
          setAdminId("");
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      />
      <HardDeleteModal
        keepMounted
        title="Delete Admin"
        description="Are you sure want to delete this admin?"
        open={openDeleteAdmin}
        loading={loading}
        handleOk={() => deleteAdmin()}
        onClose={() => {
          setOpenDeleteAdmin(false);
          setAdminId("");
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      />
    </>
  );
}
export default AdminTable;
