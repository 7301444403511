import React, { useState } from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import ProductCard from "../../components/cards/ProductCard";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";

function SubscriptionPlansDetails() {
  const [value, setValue] = useState(0);
  const curLocation = useLocation();
  const path = curLocation.pathname;
  const subName = path.split("/");

  function createData(id, name, subDate, expDate, status) {
    return { id, name, subDate, expDate, status };
  }

  const rows = [
    createData(0, "John", "01.01.2023", "01.01.2024", "Active"),
    createData(1, "Jane", "06.03.2023", "06.03.2024", "Inactive"),
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  const left = (
    <div>Subscriptions-plans / {decodeURIComponent(subName[2])}</div>
  );
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        mt: 10,
        ml: 2,
        mr: 2,
        mb: 4,
      }}
    >
      <DashboardBreadcrumb left={left} />
      <Typography component="h2" variant="h6">
        {decodeURIComponent(subName[2])}
      </Typography>
      <AppBar sx={{ mt: 2 }} position="static" color="transparent">
        <Tabs value={value} onChange={handleChange}>
          <Tab
            sx={{
              textTransform: "none",
              "&:hover": {
                color: "#3f51b5",
                opacity: 1,
              },
            }}
            label="Subscription plan details"
          />
          <Tab
            sx={{
              textTransform: "none",
              "&:hover": {
                color: "#3f51b5",
                opacity: 1,
              },
            }}
            label="Products"
          />
          <Tab
            sx={{
              textTransform: "none",
              "&:hover": {
                color: "#3f51b5",
                opacity: 1,
              },
            }}
            label="Subscribed Users"
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Subscription Plan Details</Typography>
          <BorderColorOutlinedIcon sx={{ color: "blue" }} />
        </Stack>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ mt: 1 }}
        >
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>Subscription Name</Typography>
            <Typography>$100 pack monthly</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>Subscription Price</Typography>
            <Typography>100 USD</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>
              Subscription Frequency
            </Typography>
            <Typography>Monthly</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>Available Period</Typography>
            <Typography>January 1, 2023 - December 31, 2023</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: "gray" }}>Next Delivery</Typography>
            <Typography>10.10.2023</Typography>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Products</Typography>
          <Button
            sx={{ width: "140px", textTransform: "none" }}
            variant="outlined"
          >
            Add product
          </Button>
        </Stack>
        <Grid
          container
          rowSpacing={1}
          sx={{ mt: 1 }}
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {Array.from(Array(6)).map((_, index) => (
            <Grid item xs={2} sm={4} md={3} key={index}>
              <ProductCard />
            </Grid>
          ))}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Typography variant="h6">Subscribed Users</Typography>
        <TableContainer component={Paper} variant="outlined">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>UserName</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Subscription Date
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Expairation Date
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.subDate}</TableCell>
                  <TableCell>{row.expDate}</TableCell>

                  <TableCell>{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </Box>
  );
}

export default SubscriptionPlansDetails;
