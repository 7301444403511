import React, { useEffect, useState } from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import AdminTable from "../../components/tables/AdminTable";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import InviteAdminModal from "../../components/modals/InviteAdminModal";
import GetAdminTableApi from "../../api/admin/GetAdminTableApi";
import { Badge, Button, Pagination, Stack } from "@mui/material";
import AdminFilterModal from "../../components/modals/filters/AdminFilterModal";
import GetFilteredAdminTableApi from "../../api/admin/GetFilteredAdminTableApi";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function Admin() {
  const [openAddAdmin, setOpenAddAdmin] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [page, setPage] = useState(1);
  const [filterModal, setFilterModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filterBy, setFilterBy] = useState();

  useEffect(() => {
    {
      filterStatus ? getFilteredAdmin(page) : getAdmin(page);
    }
  }, [page]);

  const handleChange = (event, num) => {
    changeTablePage(num);
  };

  async function getAdmin(num) {
    const apiResponse = await GetAdminTableApi(num);

    if (apiResponse.success) {
      setAdmins(apiResponse.data);
      setPage(num);
    }
  }

  async function getFilteredAdmin(event, num) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const apiResponse = await GetFilteredAdminTableApi(
      data.get("status") || "",
      num
    );
    if (apiResponse.success) {
      setAdmins(apiResponse.data);
      setFilterStatus(true);
      setFilterBy({
        status: data.get("status"),
      });
      setPage(num);
    }
  }

  function changeTablePage(num) {
    if (filterStatus) {
      getFilteredAdmin(filterBy, num);
    } else {
      getAdmin(num);
    }
  }

  function resetFilter() {
    getAdmin(1);
    setFilterStatus(false);
    setFilterBy();
  }

  const right = (
    <div className="flex items-center gap-2">
      <div onClick={() => resetFilter()}>
        <BootstrapTooltip title="Refresh" placement="top">
          <Button
            sx={{
              width: "50px",
              minWidth: "unset",
            }}
            variant="contained"
            disabled={!filterStatus}
          >
            <RefreshIcon
              sx={{
                fontSize: "25px",
                color: "primary",
              }}
            />
          </Button>
        </BootstrapTooltip>
      </div>
      <div onClick={() => setFilterModal(true)}>
        <BootstrapTooltip title="Filter" placement="top">
          <Button
            sx={{
              width: "50px",
              minWidth: "unset",
            }}
            variant="contained"
          >
            {filterStatus ? (
              <Badge
                color="secondary"
                overlap="circular"
                badgeContent=" "
                variant="dot"
              >
                <FilterAltOutlinedIcon
                  sx={{ fontSize: "25px" }}
                />
              </Badge>
            ) : (
              <FilterAltOutlinedIcon
                sx={{ fontSize: "25px" }}
              />
            )}
          </Button>
        </BootstrapTooltip>
      </div>
      <div onClick={() => setOpenAddAdmin(true)}>
        <BootstrapTooltip title="Add" placement="top">
          <Button
            sx={{
              width: "50px",
              minWidth: "unset",
            }}
            variant="contained"
          >
            <AddOutlinedIcon style={{ fontSize: "25px" }} />
          </Button>
        </BootstrapTooltip>
      </div>
    </div>
  );

  const left = <div>Admin</div>;
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        mt: 10,
        ml: 2,
        mr: 2,
        mb: 4,
      }}
    >
      <DashboardBreadcrumb left={left} right={right} />
      <AdminTable
        admins={admins?.admins}
        page={page}
        getAdmin={(num) => changeTablePage(num)}
      />
      {admins.totalCount > 10 && (
        <Stack alignItems="center" mt={2}>
          <Pagination
            count={Math.ceil(admins?.totalCount / 10)}
            siblingCount={0}
            page={page}
            onChange={handleChange}
            variant="outlined"
            color="primary"
            shape="rounded"
          />
        </Stack>
      )}
      <InviteAdminModal
        keepMounted
        open={openAddAdmin}
        handleOk={(num) => {
          getAdmin(num);
          setOpenAddAdmin(false);
        }}
        onClose={() => setOpenAddAdmin(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      />
      <AdminFilterModal
        keepMounted
        open={filterModal}
        filterStatus={filterStatus}
        filterBy={filterBy}
        handleOk={(num) => {
          getFilteredAdmin(num);
        }}
        onClose={() => setFilterModal(false)}
      />
    </Box>
  );
}

export default Admin;
