import * as React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";

const action = (
  <div className="flex justify-items-center gap-1">
    <EditOutlinedIcon style={{ fontSize: "20px" }} className="text-blue-500" />
    <BlockOutlinedIcon
      style={{ fontSize: "20px" }}
      className="text-orange-500"
    />
    <DeleteOutlinedIcon style={{ fontSize: "20px" }} className="text-red-500" />
  </div>
);

// Generate boost Data
function createData(id, name, sub, startDate, endDate, status, action) {
  return { id, name, sub, startDate, endDate, status, action };
}

const rows = [
  createData(
    0,
    "Christmas ornaments & decorations",
    "40",
    "10.12.2023",
    "30.12.2023",
    "Completed",
    action
  ),
  createData(
    1,
    "Spooky Halloween Products",
    "25",
    "10.10.2023",
    "20.10.2023",
    "Ongoing",
    action
  ),
  createData(
    2,
    "St.Patricks day Accessories",
    "-",
    "10.11.2023",
    "10.12.2023",
    "Upcoming",
    action
  ),
];

function ContestsTable() {
  let navigate = useNavigate();
  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" gutterBottom>
        Contests
      </Typography>
      <TableContainer
      component={Paper} variant="outlined"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Contests Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                No of Submissions
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Start Date</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>End Date</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow onClick={() => {
                navigate(`/contests/${row?.name}`);
              }} key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.sub}</TableCell>
                <TableCell>{row.startDate}</TableCell>
                <TableCell>{row.endDate}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.action}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
export default ContestsTable;
