import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function CreateContests() {
  const left = <div>Contests / Create Contests</div>;
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        flexGrow: 1,
        height: "100vh",
        mt: 10,
        ml: 2,
        mr: 2,
        mb: 8,
      }}
    >
      <DashboardBreadcrumb left={left} />
      <Typography component="h2" variant="h6">
        Create Contests
      </Typography>

      <TextField
        sx={{ mt: 1 }}
        size="small"
        label="Contest name"
        variant="outlined"
        required
      />
      <Typography sx={{ mt: 2 }}>Add Contest Banner</Typography>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        fullWidth
        sx={{
          mt: 2,
          mb: 2,
          height: "150px",
          backgroundColor: "#eeeeee",
          border: "white",
        }}
      >
        <Button component="label">
          + Add
          <input hidden accept="image/*" multiple type="file" />
        </Button>
      </Stack>
      <Stack sx={{ width: "318px" }}>
        <TextField
          id="outlined-multiline-static"
          label="About the Contest"
          multiline
          rows={3}
        />
      </Stack>
      <Typography sx={{ mt: 2 }}>Rewards</Typography>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={4}>
          <TextField
            sx={{ mt: 1 }}
            size="small"
            label="1st Place"
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            sx={{ mt: 1 }}
            size="small"
            label="2nd Place"
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            sx={{ mt: 1 }}
            size="small"
            label="3rd Place"
            variant="outlined"
            required
          />
        </Grid>
      </Grid>

      <Typography sx={{ mt: 2, mb: 2 }}>Timeline</Typography>
      <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={4}>
          <Stack sx={{ width: "318px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem components={["DatePicker"]}>
                <DatePicker
                  slotProps={{ textField: { size: "small" } }}
                  label="Submissions open date"
                />
              </DemoItem>
            </LocalizationProvider>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack sx={{ width: "318px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem components={["DatePicker"]}>
                <DatePicker
                  slotProps={{ textField: { size: "small" } }}
                  label="Submissions close date"
                />
              </DemoItem>
            </LocalizationProvider>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack sx={{ width: "318px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem components={["DatePicker"]}>
                <DatePicker
                  slotProps={{ textField: { size: "small" } }}
                  label="Public voting open date"
                />
              </DemoItem>
            </LocalizationProvider>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack sx={{ width: "318px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem components={["DatePicker"]}>
                <DatePicker
                  slotProps={{ textField: { size: "small" } }}
                  label="Public voting close date"
                />
              </DemoItem>
            </LocalizationProvider>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack sx={{ width: "318px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem components={["DatePicker"]}>
                <DatePicker
                  slotProps={{ textField: { size: "small" } }}
                  label="Winner announcement date"
                />
              </DemoItem>
            </LocalizationProvider>
          </Stack>
        </Grid>
      </Grid>

      <Stack
        sx={{ mt: 2 }}
        justifyContent="start"
        alignItems="center"
        direction="row"
        spacing={2}
      >
        <Button variant="contained" sx={{ width: "120px" }} size="small">
          Create
        </Button>
        <Button variant="outlined" sx={{ width: "120px" }} size="small">
          Cancel
        </Button>
      </Stack>
    </Box>
  );
}

export default CreateContests;
