import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

function AppRoute() {
  
  const firstLoginState = useSelector((state) => state.firstLoginState.value);
  
  if (firstLoginState) {
    return <Navigate to={"/create-newpassword"} replace />;
  } else {
    return <Outlet />;
  }
}

export default AppRoute;
