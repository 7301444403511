import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/UserSlice";
import authReducer from "../features/AuthSlice";
import firstLoginReducer from "../features/FirstLoginSlice";

export const Store = configureStore({
  reducer: {
    userState: userReducer,
    authState: authReducer,
    firstLoginState: firstLoginReducer,
  },
});
