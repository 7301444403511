import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { STATUS } from "../helpers/Constant";
import ConfirmationModal from "../modals/ConfirmationModal";
import DeleteShopApi from "../../api/shops/DeleteShopApi";
import NotificationHelper from "../helpers/NotificationHelper";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import HardDeleteModal from "../modals/HardDeleteModal";
import EnableDisableShopApi from "../../api/shops/EnableDisableShopApi";
import { CustomNoRowsOverlay } from "../helpers/NoData";

function ShopTable(props) {
  const [page, setPage] = useState(1);
  const [openDisableShop, setOpenDisableShop] = useState(false);
  const [openDeleteShop, setOpenDeleteShop] = useState(false);
  const [shopId, setShopId] = useState();
  const [selectShopStatus, setSelectShopStatus] = useState();
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [disableLoading, setDisableLoading] = useState(false);

  useEffect(() => {
    setPage(props.page);
  }, [props.page]);

  async function disableShop() {
    setDisableLoading(true);
    const apiResponse = await EnableDisableShopApi(shopId, selectShopStatus);

    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse.success) {
      setNotification({
        open: true,
        type: "success",
        message: apiResponse.message,
      });
      props.getShops();
    }
    setDisableLoading(false);
    setShopId("");
    setOpenDisableShop(false);
  }

  async function deleteShop() {
    setLoading(true);
    const apiResponse = await DeleteShopApi(shopId);

    if (apiResponse.error) {
      setNotification({
        open: true,
        type: "error",
        message: apiResponse.message,
      });
    } else if (apiResponse.success) {
      setNotification({
        open: true,
        type: "success",
        message: apiResponse.message,
      });
      if (
        props.data?.totalCount > 1 &&
        props.shops?.length.toString().endsWith("1")
      ) {
        props.getShops(page - 1);
      } else {
        props.getShops(page);
      }
    }
    setLoading(false);
    setShopId("");
    setOpenDeleteShop(false);
  }

  return (
    <>
      <React.Fragment>
        <Typography component="h2" variant="h6" gutterBottom>
          Shops
        </Typography>

        <TableContainer component={Paper} variant="outlined">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Shop Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Seller Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Location</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            {props.shops?.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <CustomNoRowsOverlay message={"No Shop"} />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {props.shops?.map((shop) => (
                  <TableRow key={shop._id}>
                    <TableCell>{shop.name}</TableCell>
                    <TableCell>{shop.user}</TableCell>
                    <TableCell>{shop.location?.city || "-"}</TableCell>
                    <TableCell>
                      {shop.status === STATUS.Accepted
                        ? "Accepted"
                        : "Disabled"}
                    </TableCell>
                    <TableCell>
                      <div className="flex justify-items-center gap-1">
                        {/* <EditOutlinedIcon
                        style={{ fontSize: "20px" }}
                        className="text-blue-500"
                      /> */}
                        {shop.status === STATUS.Disabled ? (
                          <div
                            onClick={() => {
                              setShopId(shop._id);
                              setSelectShopStatus(STATUS.Accepted);
                              setOpenDisableShop(true);
                            }}
                          >
                            <CheckCircleOutlinedIcon
                              style={{ fontSize: "20px" }}
                              className="text-green-500"
                            />
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              setShopId(shop._id);
                              setSelectShopStatus(STATUS.Disabled);
                              setOpenDisableShop(true);
                            }}
                          >
                            <BlockOutlinedIcon
                              style={{ fontSize: "20px" }}
                              className="text-orange-500"
                            />
                          </div>
                        )}
                        <div
                          onClick={() => {
                            setShopId(shop._id);
                            setOpenDeleteShop(true);
                          }}
                        >
                          <DeleteOutlinedIcon
                            style={{ fontSize: "20px" }}
                            className="text-red-500"
                          />
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </React.Fragment>
      <NotificationHelper notification={notification} />
      <ConfirmationModal
        keepMounted
        title={
          selectShopStatus === STATUS.Disabled ? "Disable Shop" : "Enable Shop"
        }
        description={
          selectShopStatus === STATUS.Disabled
            ? "Are you sure want to disable this shop?"
            : "Are you sure want to Enable this shop?"
        }
        loading={disableLoading}
        open={openDisableShop}
        handleOk={() => disableShop()}
        onClose={() => {
          setOpenDisableShop(false);
          setShopId("");
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      />
      <HardDeleteModal
        keepMounted
        title="Delete Shop"
        description="Are you sure want to delete this shop?"
        loading={loading}
        open={openDeleteShop}
        handleOk={() => deleteShop()}
        onClose={() => {
          setOpenDeleteShop(false);
          setShopId("");
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      />
    </>
  );
}

export default ShopTable;
